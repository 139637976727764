import React from 'react';
import classnames from 'classnames';
import './MenuSelector.scss';
import { MENUS } from '../constants';

function MenuSelector({ onSelectMenu, menu, disableMenus = [] }) {
  const onClick = value => {
    onSelectMenu(menu === value ? null : value);
  };

  return (
    <div className="MenuSelector drop-shadow">
      {MENUS.map((m, i) => (
        <div
          key={i}
          title={m.label}
          onClick={() => onClick(m.value)}
          className={classnames('MenuSelector-button', {
            active: m.value && menu === m.value,
            disabled: disableMenus.includes(m.value),
          })}
        >
          <div className="MenuSelector-icon-container">
            {React.createElement(m.icon, {})}
          </div>
        </div>
      ))}
    </div>
  );
}

export default MenuSelector;
