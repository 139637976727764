import React from 'react';
import classnames from 'classnames';
import './Toggle.scss';

function Toggle({
  options,
  onToggle,
  selected,
  disabled = false,
  type = 'primary',
}) {
  return (
    <div
      className={classnames('Toggle', {
        primary: type === 'primary',
        secondary: type === 'secondary',
        disabled,
      })}
    >
      {options.map((option, i) => (
        <div
          key={i}
          className={classnames('Toggle-option', {
            primary: type === 'primary',
            secondary: type === 'secondary',
            selected: selected.includes(option.value),
            unselected: !selected.includes(option.value),
            disabled,
          })}
          onClick={() => onToggle(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
}

export default Toggle;
