import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import * as d3 from 'd3';
import html2canvas from 'html2canvas';
import hat from 'hat';
import classnames from 'classnames';
import './DownloadMenu.scss';
import Dropdown from '../Dropdown';
import RadioButtonSet from '../RadioButtonSet';
import ButtonSet from '../ButtonSet';
import {
  FILTERS_LABELS,
  CHOROPLETH_BASIS_OPTIONS,
  getCurrentDate,
  formatDateWithTime,
  ZONE_SYSTEM_TYPES,
  updateSpatialKeys,
  updateFilterValues,
  CUSTOM_DATA_DOWNLOAD_OPTIONS,
} from '../../constants';
import {
  getCrossTabulation,
  loadBlockgroupShapesFromApi,
} from '../../connectors';
import { saveAs } from 'file-saver';
import { useLoadingStore } from '../../store/loaders';
import Loader from '../Loader';
import * as XLSX from 'xlsx';
import shpwrite from '@mapbox/shp-write';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import ChartsDownloadMenu from './ChartsDownloadMenu';
import { apiSummaryDataToAppSummaryData } from '../../constants';
import { GroupButtonSet } from '../../components/GroupButtonSet';
import { PDFReport } from './PDFReport';
import { useReportStore } from '../../store/reportStore';
import {
  loadCensustractShapesFromApi,
  loadCountyShapesFromApi,
} from '../../connectors/blockgroups';
import SpatialDataDownloadOptions from './SpatialDataDownloadOptions';
import ODFlowsDownloadMenu from './ODFlowsDownloadMenu';
import { useMapStore as originMapStore } from '../../store/origin-map';
import { useMapStore as destinationMapStore } from '../../store/destination-map';

function DownloadMenu({
  dimensions,
  activeDashboardInstance,
  originData,
  destinationData,
  filterValues,
  summaryData,
  highlightedSummaryData,
  dataSegmentation,
  clientLogo,
  dashboardType,
  dataFilters,
  zoneType,
  direction,
  highlightedGeoidsOrigin,
  highlightedGeoidsDestination,
}) {
  const { t } = useTranslation();
  const enablePdfReport = false;
  const loader = useRef();
  // Cross tabulation
  const [crossTabulationDimensions, setCrossTabulationDimensions] = useState(
    []
  );
  const [crossTabulationMetric, setCrossTabulationMetric] = useState(null);
  const [crossTabulationDownloadFormat, setCrossTabulationDownloadFormat] =
    useState(null);

  const crossTabulationDownloadFormatOptions = [
    { label: t('general.csv'), value: 'csv' },
    { label: t('general.excel'), value: 'excel' },
  ];

  const [selectedMapDownload, setSelectedMapDownload] = useState(null);
  const originSelectedGeoIds = originMapStore(state => state.selectedGeoIds);
  const destinationSelectionGeoIds = destinationMapStore(
    state => state.selectedGeoIds
  );

  const mapDownloadOptions = [
    { label: t('general.origin'), value: 'origin' },
    { label: t('general.destination'), value: 'destination' },
  ];

  const mapSource = useReportStore(state => state.mapSource);
  const pdfReport = useReportStore(state => state.pdfReport);
  const setPdfReport = useReportStore(state => state.setPdfReport);
  const pdfDetails = useReportStore(state => state.pdfDetails);

  // const [spatialDownloadOptions, setSpatialDownloadOptions] = useState({
  //   originDestination: null,
  //   geography: null,
  // });

  const isLoading = useLoadingStore(
    state => state.isLoading.downloadMenu.length
  );
  const setLoadingState = useLoadingStore(state => state.setLoadingState);

  const [selectedTab, setSelectedTab] = useState('Custom Charts');

  const getFilterLabel = property => {
    let label = FILTERS_LABELS.find(v => property === v.value)?.label;
    if (!label) {
      label = property.replaceAll('_', ' ');
      label = label.charAt(0).toUpperCase() + label.slice(1);
    }
    return label;
  };

  const crossTabulationOptions = useMemo(() => {
    let options = [];
    if (!dimensions) return options;
    const updatedDimensions = dimensions.filter(item => item !== 'zoneSystem');
    return options.concat(
      updatedDimensions.map(d => ({
        label: getFilterLabel(d),
        value: d,
      }))
    );
  }, [dimensions]);

  const downloadExcel = useCallback(
    async (originData, name, columns) => {
      const workbook = XLSX.utils.book_new();

      const worksheet = XLSX.utils.json_to_sheet(originData, {
        header: columns,
      });
      XLSX.utils.book_append_sheet(workbook, worksheet, `${name}`);

      XLSX.writeFile(workbook, `${name}.xlsx`, { compression: true });
      setLoadingState('downloadMenu', loader.current);
    },
    [setLoadingState, loader.current]
  );

  const downloadCsv = useCallback(
    async (originData, name, columns) => {
      const csv = await d3.csvFormat(originData, columns);
      const blob = new Blob([csv], { type: 'text/csv' });
      saveAs(blob, `${name}.csv`);
      setLoadingState('downloadMenu', loader.current);
    },
    [setLoadingState, loader.current]
  );

  const downloadCrossTabulation = useCallback(async () => {
    setLoadingState('downloadMenu', loader.current);
    let nextFilters = updateFilterValues(dataFilters, filterValues);
    const clonedFilters = JSON.parse(JSON.stringify(nextFilters));
    delete clonedFilters.zoneSystem;
    clonedFilters.zone_system = [zoneType];
    if (highlightedGeoidsDestination.length) {
      clonedFilters['destination_geo'] = highlightedGeoidsDestination;
    } else if (destinationSelectionGeoIds?.[zoneType]?.length) {
      clonedFilters['destination_geo'] = destinationSelectionGeoIds?.[zoneType];
    }

    if (highlightedGeoidsOrigin.length) {
      clonedFilters['origin_geo'] = highlightedGeoidsOrigin;
    } else if (originSelectedGeoIds?.[zoneType]?.length) {
      clonedFilters['origin_geo'] = originSelectedGeoIds?.[zoneType];
    }

    let results = await getCrossTabulation(
      crossTabulationMetric === 'daily_trips' ? 'daily_trips' : 'daily_pmt',
      activeDashboardInstance,
      [],
      crossTabulationDimensions,
      clonedFilters,
      'downloadMenu'
    );

    results.map(item => {
      if (item?.daily_trips) {
        item.daily_trips = parseFloat(item.daily_trips.toFixed(2));
      } else if (item?.daily_pmt) {
        item.daily_pmt = parseFloat(item.daily_pmt.toFixed(2));
      }
    });

    const filterOptions = activeDashboardInstance?.filterOptions;
    results = results.map(r => {
      let next = { ...r };
      next = Object.entries(next).map(([k, v]) => {
        const convenienceObj = apiSummaryDataToAppSummaryData(
          { [k]: [{ value: v }] },
          null,
          filterOptions
        );
        const [nextK, nextV] = Object.entries(convenienceObj)[0];
        return [nextK, Array.isArray(nextV) ? nextV?.[0]?.value : nextV];
      }, {});

      return {
        ...Object.fromEntries(next),
        [crossTabulationMetric]: r[crossTabulationMetric],
      };
    });

    const keys = Object.keys(results[0])
      .sort()
      .filter(k => k !== crossTabulationMetric);

    const getSortByFn = key => {
      const sequence = filterValues[key];
      const sortByFn = item => {
        const index = sequence?.findIndex(v => v === item[key]);
        return index;
      };
      return sortByFn;
    };
    const formatted = _.sortBy(
      results,
      keys.map(k => getSortByFn(k))
    );

    const viewName = activeDashboardInstance?.viewName
      .toLowerCase()
      .replaceAll(' ', '_');
    const name = `${viewName}-${getCurrentDate()}-${crossTabulationDimensions.join(
      '-'
    )}`;

    switch (crossTabulationDownloadFormat) {
      case 'excel':
        downloadExcel(formatted, name.slice(0, 31));
        break;
      case 'csv':
      default:
        downloadCsv(formatted, name);
        break;
    }
  }, [
    activeDashboardInstance,
    crossTabulationMetric,
    crossTabulationDimensions,
    crossTabulationDownloadFormat,
    downloadCsv,
    downloadExcel,
    filterValues,
    dataFilters,
    destinationSelectionGeoIds,
    originSelectedGeoIds,
  ]);

  const downloadSpatialFiles = useCallback(
    async (mapDirection, spatialDataDownloadFormat) => {
      loader.current = hat();
      setLoadingState('downloadMenu', loader.current);
      const data =
        mapDirection === 'destination' ? destinationData : originData;
      if (!data) return;
      const viewName = activeDashboardInstance?.viewName
        .toLowerCase()
        .replaceAll(' ', '_');
      const name = `${viewName}-${zoneType}-${getCurrentDate()}`;
      let results = [];
      const geoids = Object.keys(data?.[zoneType]);
      if (zoneType === ZONE_SYSTEM_TYPES.censusTract) {
        results = await loadCensustractShapesFromApi(geoids);
      } else if (zoneType === ZONE_SYSTEM_TYPES.blockGroup) {
        results = await loadBlockgroupShapesFromApi(geoids);
      } else if (zoneType === ZONE_SYSTEM_TYPES.county) {
        results = await loadCountyShapesFromApi(geoids);
      } else {
        const zoneSystem = activeDashboardInstance?.zoneSystems?.find(
          item => item.name === zoneType
        );
        results = zoneSystem?.zones
          .filter(item => geoids.includes(item.label.toString()))
          .map(item => {
            return {
              ...item,
              geoId: JSON.stringify(item.label),
            };
          });
      }

      const updatedKeysData = updateSpatialKeys(data?.[zoneType]);

      const shapes = results.map(r => {
        return {
          type: 'Feature',
          properties: {
            ...(spatialDataDownloadFormat === 'shpfile'
              ? updatedKeysData?.[r.geoId]
              : data?.[zoneType]?.[r.geoId] ?? {}),
            geoId: r.geoId,
          },
          geometry: r.shape,
        };
      });

      const featureCollection = {
        type: 'FeatureCollection',
        features: shapes,
      };

      switch (spatialDataDownloadFormat) {
        case 'shpfile': {
          const shpFileOptions = {
            folder: name,
            filename: name,
            outputType: 'blob',
            compression: 'DEFLATE',
          };

          // TODO there is a bug with downloading polygons
          // Can we get this from server side? Otherwise revisit
          shpwrite
            .zip(JSON.parse(JSON.stringify(featureCollection)), shpFileOptions)
            .then(function (blob) {
              saveAs(blob, name + '.zip');
              setLoadingState('downloadMenu', loader.current);
            });

          break;
        }
        case 'geojson': {
          const blob = new Blob([JSON.stringify(featureCollection, null, 2)], {
            type: 'text/plain;charset=utf-8',
          });
          saveAs(blob, `${name}.geojson`);
          setLoadingState('downloadMenu', loader.current);
          break;
        }
        default:
      }
    },
    [originData, activeDashboardInstance]
  );

  const downloadPng = async () => {
    setLoadingState('downloadMenu', loader.current);
    const canvas = mapSource?.getCanvas();
    const el = document.getElementById(`legend-${selectedMapDownload}-graph`);
    canvas &&
      canvas.toBlob(blob => {
        const csvURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute(
          'download',
          `${pdfDetails.viewName?.replaceAll(
            ' ',
            '_'
          )}_${selectedMapDownload}_map_${formatDateWithTime(new Date())}.png`
        );
        tempLink.click();
        setSelectedMapDownload(null);
      });
    if (el) {
      const canvas = await html2canvas(el);
      canvas &&
        canvas.toBlob(blob => {
          const csvURL = window.URL.createObjectURL(blob);
          const tempLink = document.createElement('a');
          tempLink.href = csvURL;
          tempLink.setAttribute(
            'download',
            `${pdfDetails.viewName?.replaceAll(
              ' ',
              '_'
            )}_${selectedMapDownload}_legend_${formatDateWithTime(
              new Date()
            )}.png`
          );
          tempLink.click();
        });
    }
    setLoadingState('downloadMenu', loader.current);
  };

  const getDestinationMap = () => {
    if (pdfReport.pdfDestination === selectedMapDownload) {
      downloadPng();
    } else {
      setPdfReport({
        ...pdfReport,
        pdfDestination: selectedMapDownload,
      });
    }
  };

  useEffect(() => {
    mapSource &&
      pdfReport.mapDestination === selectedMapDownload &&
      downloadPng();
  }, [pdfReport, mapSource]);

  return (
    <div className="DownloadMenu">
      <div className="menu-primary-label">
        {t('downloadMenu.downloadCharts')}
      </div>
      {enablePdfReport && (
        <GroupButtonSet
          options={[t('downloadMenu.customCharts'), t('downloadMenu.reports')]}
          selected={selectedTab}
          onChange={setSelectedTab}
        />
      )}
      {selectedTab === 'Report' ? (
        <PDFReport clientLogo={clientLogo} />
      ) : (
        <>
          <ChartsDownloadMenu
            filterValues={filterValues}
            dataSegmentation={dataSegmentation}
            data={summaryData}
            highlightedData={highlightedSummaryData}
            viewName={activeDashboardInstance?.viewName}
          />
          <div className="menu-primary-label">
            {t('downloadMenu.mapDownload')}
          </div>
          <div className="DownloadMenu-section">
            <div className="DownloadMenu-row-container">
              <RadioButtonSet
                selected={selectedMapDownload ? [selectedMapDownload] : null}
                options={mapDownloadOptions}
                onToggle={setSelectedMapDownload}
              />
              <div
                className={classnames('DownloadMenu-button', {
                  disabled: !selectedMapDownload,
                })}
                onClick={getDestinationMap}
              >
                {t('general.download')}
              </div>
            </div>
          </div>
          <div className="menu-primary-label">
            {t('downloadMenu.customDownload')}
          </div>
          <div className="DownloadMenu-section">
            <div className="DownloadMenu-row-container">
              <div className="DownloadMenu-buttonset-container">
                <div className="menu-secondary-label">
                  {t('downloadMenu.selectUpTo3')}
                </div>
                <ButtonSet
                  dark={true}
                  selected={crossTabulationDimensions}
                  options={crossTabulationOptions}
                  onToggle={v => {
                    const nextDimensions = crossTabulationDimensions.includes(v)
                      ? crossTabulationDimensions.filter(val => val !== v)
                      : crossTabulationDimensions.concat([v]);
                    if (nextDimensions.length > 3) return;
                    setCrossTabulationDimensions(nextDimensions);
                  }}
                />
                <div className="DownloadMenu-dropdown-container">
                  <div className="menu-secondary-label">
                    {t('downloadMenu.metric')}
                  </div>
                  <Dropdown
                    dark={true}
                    selectedValue={crossTabulationMetric}
                    options={CUSTOM_DATA_DOWNLOAD_OPTIONS.filter(
                      item => item?.dashboardType === dashboardType
                    )}
                    onChange={setCrossTabulationMetric}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="DownloadMenu-section">
            <div className="DownloadMenu-row-container">
              <RadioButtonSet
                selected={
                  crossTabulationDownloadFormat
                    ? [crossTabulationDownloadFormat]
                    : null
                }
                options={crossTabulationDownloadFormatOptions}
                onToggle={setCrossTabulationDownloadFormat}
              />
              <div
                className={classnames('DownloadMenu-button', {
                  disabled:
                    !crossTabulationDimensions.length ||
                    !crossTabulationMetric ||
                    !crossTabulationDownloadFormat,
                })}
                onClick={downloadCrossTabulation}
              >
                {t('downloadMenu.download')}
              </div>
            </div>
          </div>

          <div className="menu-primary-label">
            {t('downloadMenu.spatialData')}
          </div>

          {/* TODO bring this back later */}
          {/* <div className="DownloadMenu-section">
            <div className="DownloadMenu-row-container">
              <div className="DownloadMenu-dropdown-container">
                <div className="menu-secondary-label">Origin/destination</div>
                <Dropdown
                  dark={true}
                  selectedValue={spatialDownloadOptions.originDestination}
                  options={[
                    { label: 'ORIGIN', value: 'origin_geo' },
                    { label: 'DESTINATION', value: 'destination_geo' },
                  ]}
                  onChange={v =>
                    setSpatialDownloadOptions(s => ({ ...s, originDestination: v }))
                  }
                />
              </div>
              <div className="DownloadMenu-dropdown-container">
                <div className="menu-secondary-label">Geography</div>
                <Dropdown
                  dark={true}
                  selectedValue={spatialDownloadOptions.geography}
                  options={[]}
                  onChange={v =>
                    setSpatialDownloadOptions(s => ({ ...s, geography: v }))
                  }
                />
              </div>
            </div>
          </div> */}

          <div className="DownloadMenu-section">
            {direction.includes('origin') && (
              <SpatialDataDownloadOptions
                mapDirection={'origin'}
                downloadSpatialFiles={downloadSpatialFiles}
              />
            )}
            {direction.includes('destination') && (
              <SpatialDataDownloadOptions
                mapDirection={'destination'}
                downloadSpatialFiles={downloadSpatialFiles}
              />
            )}
          </div>
          <ODFlowsDownloadMenu
            data={dataFilters}
            highlightedGeoidsOrigin={highlightedGeoidsOrigin}
            highlightedGeoidsDestination={highlightedGeoidsDestination}
            activeDashboardInstance={activeDashboardInstance}
            zoneType={zoneType}
            filterValues={filterValues}
          />
        </>
      )}

      {/*  */}
      {isLoading ? (
        <div className="DownloadMenu-loader-container">
          <Loader />
        </div>
      ) : null}
    </div>
  );
}

export default DownloadMenu;
