import bbox from '@turf/bbox';

const tempTransitDashboardTransform = (
  dashboardId,
  userId,
  clientId,
  apiDashboard
) => {
  const { dashboard } = apiDashboard;
  const nextDashboard = {
    dashboardId,
    userId,
    clientId,
    datasetId: dashboard?.dataset?.id,
    type: dashboard?.product?.name,
    studyPeriods: dashboard?.studyPeriods,
    backend: 'database',
    filterOptions: dashboard?.filterOptions ?? {},
    mapBounds: [
      -77.63799550921401, 38.37657723366854, -76.42813189388741,
      39.343160786224075,
    ],
    mapCenter: [-77.03208007259511, 38.908217016389045],
    studyareaBlockgroups: [
      'AC: Ballston - Courthouse - Rosslyn',
      'AC: Bluemont - Barcroft - Douglas Park',
      'AC: Crystal/Pentagon Cities - Shirlington',
      'AC: East Falls Church - Garden City',
      'AL: Brookville - Landmark/Van Dorn',
      'AL: Old Town - Rosemont',
      'DC: Anacostia - Good Hope',
      'DC: Benning Heights - Deanwood',
      'DC: Brookland - Fort Totten',
      'DC: Chinatown - Dupont Circle',
      'DC: Cleveland Park - Woodley Park',
      'DC: Columbia Heights - Mt. Pleasant',
      'DC: Congress Heights - Anacostia Naval Station',
      'DC: Foggy Bottom - GWU',
      'DC: Friendship Heights - Palisades',
      'DC: Howard University - Cardozo/Shaw',
      'DC: Ivy City - Fort Lincoln',
      "DC: L'Enfant Plaza - Waterfront",
      'DC: National Mall',
      'DC: Navy Yard - Potomac Avenue',
      'DC: Petworth - Walter Reed',
      'DC: Shaw/Howard University - Mt Vernon Sq.',
      'DC: Union Station - NOMA',
      'DC: Van Ness - Barnaby Wood',
      'FFX: Annandale - Falls Church',
      'FFX: Baileys Crossroads - Lincolnia',
      'FFX: Fairfax City - Vienna',
      'FFX: Lorton - Fort Belvoir',
      'FFX: Mclean - Tysons Corner',
      'FFX: Mount Vernon - Springfield/Franconia',
      'FFX: Reston - Herndon',
      'LC: Dulles International Airport',
      'MC: Aspen Hill - Olney',
      'MC: Bethesda - Potomac - White Flint',
      'MC: Clarksburg - Gaithersburg',
      'MC: Glenmont -Wheaton - White Oak',
      'MC: Rockville - Twinbrook',
      'MC: Shady Grove',
      'PG: Beltsville - Laurel',
      'PG: Bladensburg - New Carrollton',
      'PG: Bowie - Mitchellville',
      'PG: Branch Avenue - Fort Washington',
      'PG: Brandywine - Clinton',
      'PG: College Park - Hyattsville',
      'PG: Greenbelt',
      'PG: Joint Base Andrews - Upper Marlboro',
      'PG: Largo - Glenarden',
      'PG: Suitland - Capitol Heights',
    ],
  };

  return nextDashboard;
};

export { tempTransitDashboardTransform };
