import React, { useCallback } from 'react';
import { ReactComponent as AscendingActive } from '../images/order_ascending_active.svg';
import { ReactComponent as DescendingActive } from '../images/order_descending_active.svg';
import { ReactComponent as AscendingInactive } from '../images/order_ascending_inactive.svg';
import { ReactComponent as DescendingInactive } from '../images/order_descending_inactive.svg';

import './AscendingDescendingIcon.scss';

function AscendingDescendingIcon({ active, setActive }) {
  const onClickAscending = useCallback(() => {
    const next = active === 'ascending' ? null : 'ascending';
    setActive(next);
  }, [active, setActive]);
  const onClickDescending = useCallback(() => {
    const next = active === 'descending' ? null : 'descending';
    setActive(next);
  }, [active, setActive]);

  return (
    <div className="AscendingDescendingIcon">
      {active === 'ascending' ? (
        <AscendingActive onClick={onClickAscending} />
      ) : (
        <AscendingInactive onClick={onClickAscending} />
      )}
      {active === 'descending' ? (
        <DescendingActive onClick={onClickDescending} />
      ) : (
        <DescendingInactive onClick={onClickDescending} />
      )}
    </div>
  );
}

export default AscendingDescendingIcon;
