import {
  FILTER_PROPERTY_ORDER,
  TRANSIT_FILTER_PROPERTY_ORDER,
  parseFilterValues,
} from '../constants';

const getFileKey = (filters, isTransit) => {
  return (
    (isTransit ? TRANSIT_FILTER_PROPERTY_ORDER : FILTER_PROPERTY_ORDER)
      .filter(k => !!filters?.[k])
      .map(k => parseFilterValues(filters[k]))
      .filter(v => v !== 'all')
      .join('-') || 'all'
  );
};

const getFullPath = filename => {
  const basePath = [window.location.origin, window.location.pathname].join('/');

  return [basePath, filename].join('/');
};

export const loadJsonForMapLayers = async s3Key => {
  const response = await fetch(
    `${process.env.REACT_APP_S3_SHAPEFILES_URL}/${s3Key}`
  );
  return await response.json();
};

export const loadJson = async filename => {
  const path = getFullPath(filename);
  const response = await fetch(path);
  return await response.json();
};

export const loadData = async (path, filters) => {
  let nextFilters = { ...filters };
  // TODO this isn't ideal
  if (nextFilters?.itinerary_type === 'No Viable Itinerary') {
    delete nextFilters.travel_time_ratio_bin;
  }
  // TODO we have limited filters
  return await loadJson(
    `data/${path}/${getFileKey(nextFilters, path.includes('transit'))}.json`
  );
};
