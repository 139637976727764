import { create } from 'zustand';

export const useTransitItineraryStore = create(set => ({
  hovered: {
    origin_geomarket: null,
    destination_geomarket: null,
  },
  selectedODPair: '',
  itineraryData: null,
  targetCompetitiveness: 0,
  ivttPercentage: 50,
  walkTimePercentage: 50,
  odPairsData: null,
  targetTransitShare: 0,
  itineraryTableFilters: {
    weakness: 'All',
    minimunNoTotalTrips: 0,
    minimunNoTransitTrips: 0,
  },
  odPairTableFilters: {
    competitiveness: 'All',
    minimunNoTotalTrips: 0,
    minimunNoTransitTrips: 0,
  },
  setODPairsData: odPairsData => set({ odPairsData }),
  setTargetCompetitiveness: targetCompetitiveness =>
    set({ targetCompetitiveness }),
  setIvttPercentage: ivttPercentage => set({ ivttPercentage }),
  setItineraryTableFilters: itineraryTableFilters =>
    set({ itineraryTableFilters }),
  setODPairTableFilters: odPairTableFilters => set({ odPairTableFilters }),
  setWalkTimePercentage: walkTimePercentage => set({ walkTimePercentage }),
  setTargetTransitShare: targetTransitShare => set({ targetTransitShare }),
  setItineraryData: itineraryData => set({ itineraryData }),
  setSelectedODPair: selectedODPair => set({ selectedODPair }),
  setHovered: value =>
    set(state => {
      return { hovered: value };
    }),
}));
