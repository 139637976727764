import React from 'react';
import { useTranslation } from 'react-i18next';
import './SelectionsMenu.scss';
import MapDrawModeInput from '../MapDrawModeInput';
import {
  ZONE_SYSTEM_TEXT,
  ZONE_SYSTEM_TYPES,
  capitalizeWord,
  pluralize,
} from '../../constants';

function SelectionsMenu({
  direction,
  highlightedGeoids,
  mapDrawStore,
  mapStore,
  zoneType,
}) {
  const { t } = useTranslation();
  const selectedGeoIds = mapStore(state => state.selectedGeoIds);

  const renderNumberOfGeos = () => {
    const zoneTypeText = Object.values(ZONE_SYSTEM_TYPES).includes(zoneType)
      ? pluralize(
          selectedGeoIds?.[zoneType]?.length || 0,
          ZONE_SYSTEM_TEXT[zoneType]
        )
      : pluralize(selectedGeoIds?.[zoneType]?.length || 0, 'zone');

    return `${selectedGeoIds?.[zoneType]?.length || 0} ${zoneTypeText}`;
  };

  return (
    <div className="SelectionsMenu">
      <div className="menu-header-label">{t('mapMenu.zoneSelections')}</div>
      <div className="menu-primary-label">{t('mapMenu.currentSelections')}</div>
      <div className="SelectionsMenu-section">
        <div className="SelectionsMenu-selected-label">
          {capitalizeWord(direction)}
          <span className="SelectionsMenu-highlighted-number">
            {renderNumberOfGeos()}
          </span>
        </div>
      </div>

      <div className="menu-primary-label">{t('mapMenu.addSelections')}</div>
      <div className="menu-secondary-label">{t('mapMenu.drawOnMap')}</div>
      <div className="SelectionsMenu-section">
        <MapDrawModeInput
          mapDrawStore={mapDrawStore}
          mapStore={mapStore}
          zoneType={zoneType}
        />
      </div>
    </div>
  );
}

export default SelectionsMenu;
