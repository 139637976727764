import React from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import './StateCountyTree.scss';

export const StateCountyTree = React.memo(
  ({ options, type, setSelectedStateCounty, value }) => {
    const getTreeDropdownOptions = arr => {
      return arr?.length > 0
        ? arr?.map((item, ind) => {
            const state = {
              label: item?.name,
              value: item?.geoId,
              key: `${item?.name}_${ind}`,
              checked: value?.[`${type}_states`]?.includes(item?.geoId),
            };
            const countyOptions = item?.counties?.map((obj, res) => ({
              label: obj?.name,
              value: obj?.geoId,
              key: `${item?.geoId}_${res}`,
              checked: value?.[`${type}_counties`]?.includes(obj?.geoId),
            }));
            return {
              ...state,
              children: countyOptions,
            };
          })
        : [];
    };

    return (
      <DropdownTreeSelect
        multiSelect
        data={getTreeDropdownOptions(options)}
        keepOpenOnSelect={true}
        onChange={(ev, node) =>
          setSelectedStateCounty && setSelectedStateCounty(node, type)
        }
        texts={{
          placeholder: 'Select...',
        }}
      />
    );
  }
);
