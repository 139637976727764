import React, { useState } from 'react';
import classnames from 'classnames';
import './DownloadMenu.scss';
import RadioButtonSet from '../RadioButtonSet';
import { capitalizeWord } from '../../constants';

const SpatialDataDownloadOptions = ({ mapDirection, downloadSpatialFiles }) => {
  const [spatialDataDownloadFormat, setSpatialDataDownloadFormat] =
    useState(null);

  const spatialDataDownloadFormatOptions = [
    { label: 'Shapefile', value: 'shpfile' },
    { label: 'GeoJSON', value: 'geojson' },
  ];

  return (
    <div className="DownloadMenu-spatial-file-set">
      <div className="menu-primary-label">{capitalizeWord(mapDirection)}</div>
      <div className="DownloadMenu-row-container">
        <RadioButtonSet
          selected={
            spatialDataDownloadFormat ? [spatialDataDownloadFormat] : null
          }
          options={spatialDataDownloadFormatOptions}
          onToggle={setSpatialDataDownloadFormat}
        />
        <div
          className={classnames('DownloadMenu-button', {
            disabled: !spatialDataDownloadFormat,
          })}
          onClick={() =>
            downloadSpatialFiles(mapDirection, spatialDataDownloadFormat)
          }
        >
          Download
        </div>
      </div>
    </div>
  );
};

export default SpatialDataDownloadOptions;
