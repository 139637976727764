import React, { useMemo, useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import i18next from 'i18next';
import classnames from 'classnames';
import { useLoadingStore } from '../store/loaders';
import Loader from './Loader';
import * as d3 from 'd3';
import useResizeObserver from '@react-hook/resize-observer';
import { useTransitItineraryStore } from '../store/transit-itinerary';
import './Scatterplot.scss';

// This is the combined  gheight of non-chart elements from CSS
const INPUTS_HEIGHT = 150;
const AXIS_TEXT_OFFSET = 5;
const LEGEND = {
  // Good transit share, good ratio
  gtgr: {
    color: '#50ACDE',
    label: i18next.t('competitiveness.goodMarketShareGoodCompetitiveness'),
  },
  // Good transit share, poor ratio
  gtpr: {
    color: '#9D92E3',
    label: i18next.t('competitiveness.goodMarketSharePoorCompetitiveness'),
  },
  // Poor transit share, good ratio
  ptgr: {
    color: '#ECB98A',
    label: i18next.t('competitiveness.poorMarketShareGoodCompetitiveness'),
  },
  // Poor transit share, poor ratio
  ptpr: {
    color: '#E9D676',
    label: i18next.t('competitiveness.poorMarketSharePoorCompetitiveness'),
  },
};
const GET_COLOR = (d, targetTransitShare, targetCompetitiveness) => {
  const { ratio, transitShare } = d;
  let key = '';
  if (transitShare > targetTransitShare) {
    key = key + 'gt';
  } else {
    key = key + 'pt';
  }
  if (ratio > targetCompetitiveness) {
    key = key + 'pr';
  } else {
    key = key + 'gr';
  }
  return LEGEND[key]['color'];
};

const ScatterplotInput = ({
  label,
  isPercent,
  step,
  min,
  max,
  value,
  onChange,
}) => {
  const decimalPlaces = useMemo(() => {
    const decimal =
      `${step}`.split('.').length > 1 ? `${step}`.split('.')[1] : null;
    if (decimal) return `${decimal}`.length;
    return 0;
  }, [step]);

  const setValue = useCallback(
    e => {
      let nextValue = Number(e.target.value);

      if (isPercent) {
        nextValue = nextValue / 100;
      }

      if (!nextValue || isNaN(nextValue)) return;

      onChange(Number(nextValue.toFixed(decimalPlaces)));
    },
    [decimalPlaces, onChange, isPercent]
  );

  const minimum = useMemo(() => {
    return Number(Number(min).toFixed(decimalPlaces));
  }, [min, decimalPlaces]);

  const maximum = useMemo(() => {
    return Number(Number(max).toFixed(decimalPlaces));
  }, [max, decimalPlaces]);

  return (
    <div className="ScatterplotInput">
      <div className="ScatterplotInput-label-container">
        <div className="ScatterplotInput-label">{label}</div>
      </div>
      <span className="ScatterplotInput-input">
        <input
          type="number"
          min={isPercent ? minimum * 100 : minimum}
          max={isPercent ? maximum * 100 : maximum}
          value={isPercent ? value * 100 : value}
          step={isPercent ? step * 100 : step}
          onChange={setValue}
        />
        <span className="ScatterplotInput-input-percent">
          {isPercent ? '%' : null}
        </span>
      </span>
    </div>
  );
};

function Scatterplot({ data }) {
  const setHoveredItinerary = useTransitItineraryStore(
    state => state.setHovered
  );
  const selectedODPair = useTransitItineraryStore(
    state => state.selectedODPair
  );
  const [competitivenessCountData, setCompetitivenessCountData] =
    useState(null);
  const hoveredItinerary = useTransitItineraryStore(state => state.hovered);
  const targetCompetitiveness = useTransitItineraryStore(
    state => state.targetCompetitiveness
  );
  const setTargetCompetitiveness = useTransitItineraryStore(
    state => state.setTargetCompetitiveness
  );

  const isLoading = useLoadingStore(
    state => !!state.isLoading.tripsPanel.length
  );
  const targetTransitShare = useTransitItineraryStore(
    state => state.targetTransitShare
  );
  const setTargetTransitShare = useTransitItineraryStore(
    state => state.setTargetTransitShare
  );
  const odPairTableFilters = useTransitItineraryStore(
    state => state.odPairTableFilters
  );
  const [minimumTripsThreshold, setMinimumTripsThreshold] = useState(0);

  const formattedData = useMemo(() => {
    if (!data) return [];
    return data.map(d => ({
      ratio: Number(d?.avg_travel_time_ratio),
      transitShare: Number(d?.transit_trips) / Number(d?.total_trips),
      totalTrips: Number(d?.total_trips),
      origin_geomarket: d?.origin_geomarket,
      destination_geomarket: d?.destination_geomarket,
      competitiveness: d?.competitiveness,
    }));
  }, [data]);

  useEffect(() => {
    if (!formattedData.length) return;
    const competitivenessData = formattedData.reduce((acc, item) => {
      const competitiveness = item.competitiveness;
      if (acc[competitiveness]) {
        acc[competitiveness].count += 1;
      } else {
        acc[competitiveness] = { count: 1 };
      }
      return acc;
    }, {});

    setCompetitivenessCountData(competitivenessData);
  }, [formattedData]);

  useEffect(() => {
    if (!formattedData.length) return;
    if (odPairTableFilters.competitiveness === 'All') {
      setTargetTransitShare(
        Number(d3.median(formattedData.map(d => d?.transitShare))?.toFixed(2))
      );
      setTargetCompetitiveness(
        Number(d3.median(formattedData.map(d => d.ratio)).toFixed(2))
      );
    }
  }, [formattedData]);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const drawScatterPlot = useCallback(
    (h, w) => {
      if (!formattedData) return;
      const content = document.getElementById('svg-chart');
      if (content || !h || !w) return;

      // We are filtering here and only using to show
      // data, not axes to keep the chart consistent
      const filteredData = formattedData.filter(
        item => item.totalTrips >= minimumTripsThreshold
      );

      // set the dimensions and margins of the graph
      var margin = { top: 10, right: 30, bottom: 50, left: 50 };
      const width = w - margin.left - margin.right;
      const height = h - margin.top - margin.bottom;

      // append the svg object to the body of the page
      var svg = d3
        .select('#scatterplot-chart')
        .append('svg')
        .attr('id', 'svg-chart')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`)
        // Safeguard for same event on dots since they don't always fire it seems
        .on('mouseout', e => {
          if (!selectedODPair) {
            setHoveredItinerary({
              origin_geomarket: null,
              destination_geomarket: null,
            });
          }
        });

      const minCompetitiveness = d3.min(formattedData.map(d => d.ratio));
      const maxCompetitiveness = d3.max(formattedData.map(d => d.ratio));
      const minTransitShare = d3.min(formattedData.map(d => d.transitShare));
      const maxTransitShare = d3.max(formattedData.map(d => d.transitShare));
      const minTotalTrips = d3.min(formattedData.map(d => d.totalTrips));
      const maxTotalTrips = d3.max(formattedData.map(d => d.totalTrips));

      const dotRadiusScale = d3
        .scaleLinear()
        .domain([minTotalTrips, maxTotalTrips])
        .range([3, 10]);

      // Add X axis
      const x = d3
        .scaleLinear()
        .domain([minCompetitiveness, maxCompetitiveness])
        .range([0, width]);
      svg
        .append('g')
        .attr('transform', `translate(0, ${height})`)
        .attr('class', 'axis')
        .call(d3.axisBottom(x).tickSizeInner(-height))
        // Label
        .append('text')
        .attr('class', 'axis-text')
        .attr('fill', 'black')
        .attr(
          'transform',
          `translate(${width / 2}, ${margin.bottom / 2 + AXIS_TEXT_OFFSET})`
        )
        .text('Competitiveness (Transit time ratio)');

      // Add Y axis
      const y = d3
        .scaleLinear()
        .domain([minTransitShare, maxTransitShare])
        .range([height, 0]);

      svg
        .append('g')
        .attr('class', 'axis')
        .call(
          d3
            .axisLeft(y)
            .tickFormat((d, i) => {
              return `${Math.floor(d * 100)}%`;
            })
            .tickSizeInner(-width)
        )
        // Label
        .append('text')
        .attr('class', 'axis-text')
        .attr('fill', 'black')
        .attr(
          'transform',
          `translate(${margin.left / -2 - AXIS_TEXT_OFFSET}, ${
            height / 2
          })rotate(-90)`
        )
        .text('Transit share');

      // Add dots
      svg
        .append('g')
        .selectAll('dot')
        .data(filteredData)
        .join('circle')
        .attr('class', d => {
          let nextClass = 'dot';
          const { origin_geomarket, destination_geomarket } = d;
          if (Object.values(hoveredItinerary).every(v => !v)) return nextClass;

          if (Object.values(hoveredItinerary).every(v => !!v)) {
            if (
              hoveredItinerary.origin_geomarket === origin_geomarket &&
              hoveredItinerary.destination_geomarket === destination_geomarket
            ) {
              nextClass = `${nextClass} hovered`;
            } else {
              nextClass = `${nextClass} unhovered`;
            }
          } else {
            if (
              hoveredItinerary.origin_geomarket === origin_geomarket ||
              hoveredItinerary.destination_geomarket === destination_geomarket
            ) {
              nextClass = `${nextClass} hovered`;
            } else {
              nextClass = `${nextClass} unhovered`;
            }
          }

          return nextClass;
        })
        .attr('r', d => dotRadiusScale(d.totalTrips))
        .attr('cx', function (d) {
          return x(d.ratio);
        })
        .attr('cy', function (d) {
          return y(d.transitShare);
        })
        .style('fill', d => {
          return GET_COLOR(d, targetTransitShare, targetCompetitiveness);
        })
        .on('mouseover', e => {
          const { origin_geomarket, destination_geomarket } = e.target.__data__;
          if (!selectedODPair) {
            setHoveredItinerary({ origin_geomarket, destination_geomarket });
          }
        })
        .on('mouseout', e => {
          if (!selectedODPair) {
            setHoveredItinerary({
              origin_geomarket: null,
              destination_geomarket: null,
            });
          }
        });

      const textSize = 10;

      // Transit share line
      svg
        .append('line')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', y(targetTransitShare))
        .attr('y2', y(targetTransitShare))
        .style('stroke', '#899AA3')
        .style('stroke-width', '2')
        .style('stroke-dasharray', '7, 7');

      const transitShareLabelDir =
        (minTransitShare + maxTransitShare) / 2 > targetTransitShare ? -1 : 2;

      svg
        .append('text')
        .attr('class', 'target-text')
        .attr('fill', 'black')
        .attr(
          'transform',
          `translate(${width - textSize * 2}, ${
            y(targetTransitShare) + textSize * transitShareLabelDir
          })`
        )
        .text(`${Math.round(targetTransitShare * 100)}%`);

      // Competitiveness line
      svg
        .append('line')
        .attr('x1', x(targetCompetitiveness))
        .attr('x2', x(targetCompetitiveness))
        .attr('y1', height)
        .attr('y2', 0)
        .style('stroke', '#899AA3')
        .style('stroke-width', '2')
        .style('stroke-dasharray', '7, 7');

      const competitivenessLabelDir =
        (minCompetitiveness + maxCompetitiveness) / 2 > targetCompetitiveness
          ? 1
          : -3;

      svg
        .append('text')
        .attr('class', 'target-text')
        .attr('fill', 'black')
        .attr(
          'transform',
          `translate(${
            x(targetCompetitiveness) + textSize * competitivenessLabelDir
          }, ${textSize})`
        )
        .text(`${targetCompetitiveness}`);
    },
    [
      formattedData,
      targetCompetitiveness,
      targetTransitShare,
      minimumTripsThreshold,
      hoveredItinerary,
      setHoveredItinerary,
    ]
  );

  const redraw = useCallback(
    resizeArgs => {
      if (!resizeArgs) return;
      const { contentRect } = resizeArgs;
      let { width: nextWidth, height: nextHeight } = contentRect;
      nextHeight = nextHeight - INPUTS_HEIGHT;
      const { width, height } = dimensions;

      const reloadContent = (h, w) => {
        const content = document.getElementById('svg-chart');
        if (!content) return;
        content.remove();
        drawScatterPlot(h, w);
      };

      if (nextHeight !== height || nextWidth !== width) {
        reloadContent(nextHeight, nextWidth);
        setDimensions({ width: nextWidth, height: nextHeight });
      }
    },
    [dimensions, drawScatterPlot]
  );

  useResizeObserver(
    document?.getElementsByClassName('Scatterplot')?.[0],
    _.throttle(redraw, 1500, { trailing: false })
  );

  // On mount
  useEffect(() => {
    const chart = document.getElementById('scatterplot-chart');
    if (!chart) return;
    // Return early if chart exists for other lifecycle methods to pick up
    const content = document.getElementById('svg-chart');
    if (!content) {
      const container = document?.getElementsByClassName('Scatterplot')?.[0];
      drawScatterPlot(container.offsetHeight, container.offsetWidth);
      setDimensions({
        height: container.offsetHeight - INPUTS_HEIGHT,
        width: container.offsetWidth,
      });
    } else {
      content.remove();
      drawScatterPlot(dimensions.height, dimensions.width);
    }
  }, [drawScatterPlot, data, dimensions]);

  return (
    <div className="Scatterplot">
      {isLoading || !data ? (
        <Loader />
      ) : (
        <>
          <div
            className="Scatterplot-header menu-primary-label"
            title="TRANSIT SHARES VS AVG TRAVEL TIME RATIO BY OD PAIRS"
          >
            TRANSIT SHARES VS AVG TRAVEL TIME RATIO BY OD PAIRS
          </div>
          <div className="Scatterplot-inputs-container">
            <ScatterplotInput
              label={'Target Transit Share'}
              min={d3.min(formattedData.map(d => d.transitShare))}
              max={d3.max(formattedData.map(d => d.transitShare))}
              value={targetTransitShare}
              onChange={setTargetTransitShare}
              step={0.01}
              isPercent={true}
            />
            <ScatterplotInput
              label={'Target Competitiveness'}
              min={d3.min(formattedData.map(d => d.ratio))}
              max={d3.max(formattedData.map(d => d.ratio))}
              value={targetCompetitiveness}
              onChange={setTargetCompetitiveness}
              step={0.01}
            />
            <ScatterplotInput
              label={'Minimum Trips Threshold'}
              min={0}
              max={d3.max(formattedData.map(d => d.totalTrips))}
              value={minimumTripsThreshold}
              onChange={setMinimumTripsThreshold}
              step={1}
            />
          </div>
          <div className="Scatterplot-chart-container">
            <div id="scatterplot-chart" />
          </div>
          <div className="Scatterplot-legend">
            {Object.values(LEGEND).map((item, i) => (
              <div key={i} className="Scatterplot-legend-item">
                <div
                  className="Scatterplot-legend-swatch"
                  style={{ backgroundColor: item.color }}
                />
                {`${item.label} (${
                  competitivenessCountData?.[item?.label]?.count || 0
                })`}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Scatterplot;
