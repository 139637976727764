import React, { useCallback, useMemo } from 'react';
import Dropdown from '../Dropdown';
import CustomLayerEntry from '../custom-layers/CustomLayerEntry';
import { useCustomLayersStore } from '../../store/customLayers';
import './CustomLayersInput.scss';

function CustomLayersInput({ mapLayers }) {
  const activeCustomLayers = useCustomLayersStore(state => state.active);

  const addCustomLayer = useCustomLayersStore(state => state.add);

  const dashboardLayers = useMemo(() => {
    return mapLayers ?? [];
  }, [mapLayers]);

  const add = useCallback(
    layerId => {
      const layer = dashboardLayers.find(({ id }) => id === layerId);
      addCustomLayer({ ...layer, visibility: 'visible' });
    },
    [addCustomLayer, dashboardLayers]
  );

  const layerOptions = useMemo(() => {
    return dashboardLayers.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [dashboardLayers]);

  return (
    <div className="CustomLayersInput">
      <Dropdown
        selectedValue={null}
        options={layerOptions}
        onChange={add}
        placeholder="Select Additional Layers"
        disabled={!dashboardLayers.length}
      />
      {activeCustomLayers.length ? (
        <div className="active-layers">
          {activeCustomLayers.map(layer => (
            <CustomLayerEntry key={layer.id} id={layer.id} />
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default CustomLayersInput;
