import React, { useMemo, useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { useLoadingStore } from '../../store/loaders';
import Loader from '../../components/Loader';
import * as d3 from 'd3';
import useResizeObserver from '@react-hook/resize-observer';
import './TravelTimeCompetitiveness.scss';
import {
  calculateBinIntervals,
  categorizeData,
  getCategory,
} from '../../constants';

const INPUTS_HEIGHT = 30;
const BIN_WIDTH = 0.5;
function Traveltimechart({ data }) {
  const isLoading = useLoadingStore(
    state => !!state.isLoading.tripsPanel.length
  );

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const processData = inputData => {
    const processedData = inputData.reduce((acc, item) => {
      const category = item.category;
      const totalTrips = parseFloat(item.total_trips);
      const totalTransitTrips = parseFloat(item.transit_trips);

      // Check if the category already exists in the accumulator
      if (acc.hasOwnProperty(category)) {
        // If it exists, add the total trips and total transit trips to the existing values
        acc[category].total_trips += totalTrips;
        acc[category].transit_trips += totalTransitTrips;
      } else {
        // If it doesn't exist, create a new entry for the category
        acc[category] = {
          category: category,
          total_trips: totalTrips,
          transit_trips: totalTransitTrips,
        };
      }
      return acc;
    }, {});

    return processedData;
  };

  const calculateTransitShare = data =>
    Math.floor((data?.transit_trips / data?.total_trips) * 100);

  const drawScatterPlot = useCallback(
    (h, w) => {
      const content = document.getElementById('svg-traveltime-chart');
      if (content || !h || !w || !data) return;
      // Get the highest trips value in the data for x-axis range
      let maxDailyTrip = 0;
      const binIntervals = calculateBinIntervals(data, BIN_WIDTH);
      const chartData = categorizeData(data, binIntervals);
      chartData.map(item => {
        item.non_transit_trips = item?.total_trips - item?.transit_trips;
        if (maxDailyTrip < item?.total_trips) {
          maxDailyTrip = item?.total_trips;
        }
      });

      // set the dimensions and margins of the graph
      var margin = { top: 10, right: 20, bottom: 40, left: 50 };
      const width = w - margin.left - margin.right;
      const height = h - margin.top - margin.bottom;

      // append the svg object to the body of the page
      var svg = d3
        .select('#traveltime-chart')
        .append('svg')
        .attr('id', 'svg-traveltime-chart')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      let tooltip = d3
        .select('#traveltime-chart')
        .append('div')
        .attr('class', 'chartTooltip')
        .style('position', 'absolute')
        .style('color', 'white')
        .style('border-radius', '5px')
        .style('padding', '5px')
        .style('opacity', '0')
        .style('z-index', '2');

      // Define scales
      const xScale = d3
        .scaleLinear()
        .domain([0, maxDailyTrip])
        .range([0, width]);

      const yScale = d3
        .scaleBand()
        .domain(chartData.map(d => d.category))
        .range([0, height])
        .padding(0.4);

      // Define colors
      const colorScale = d3
        .scaleOrdinal()
        .domain(['transit_trips', 'non_transit_trips'])
        .range(['#148BCC', '#DBDFE1']);

      // Stack data
      const stack = d3
        .stack()
        .keys(['transit_trips', 'non_transit_trips'])
        .order(d3.stackOrderNone)
        .offset(d3.stackOffsetNone);

      const stackedData = stack(chartData);

      const tooltipText = (event, d) => {
        tooltip
          .html(
            `
            <div>
              <div><strong>${Math.floor(
                d?.data?.transit_trips
              )} transit trips</strong></div>
              <div>${Math.floor(d?.data?.total_trips)} daily trips</div>
              <div class="share">${calculateTransitShare(
                d?.data
              )}% transit share</div>
            </div>
          `
          )
          .style('top', event.pageY + 'px')
          .style('left', event.pageX + 'px')
          .style('opacity', '0.9')
          .style('z-index', 2);
      };

      // Draw stacked bars
      svg
        .selectAll('.bar')
        .data(stackedData)
        .enter()
        .append('g')
        .attr('fill', d => colorScale(d.key))
        .selectAll('rect')
        .data(d => d)
        .enter()
        .append('rect')
        .attr('x', d => xScale(d[0]))
        .attr('y', (d, i) => yScale(chartData[i].category))
        .attr('height', yScale.bandwidth())
        .attr('width', d => xScale(d[1]) - xScale(d[0]))
        .attr('class', (d, i) => 'group' + i + ' bar')
        .on('mouseover', (event, d) => {
          tooltipText(event, d);
          d3.selectAll('.bar').transition().style('opacity', 0.5);
          const index = parseInt(
            event?.target?.classList?.[0]?.replace(/[^\d.]/g, ''),
            10
          );
          d3.selectAll(`.${event?.target?.classList?.[0]}`)
            .transition()
            .style('opacity', 1);
          d3.selectAll(`.yAxis${index}`).transition().style('opacity', 1);
        })
        .on('mousemove', (event, d) => {
          tooltip
            .style('top', event.layerY + 'px')
            .style('left', event.layerX + 'px');
        })
        .on('mouseleave', d => {
          tooltip.style('opacity', '0').style('z-index', '-1');
          d3.selectAll('.bar').transition().style('opacity', 1);
        });

      // Add X-axis
      const xAxis = svg
        .append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(xScale).ticks(4).tickFormat(d3.format('.0s')));

      //Remove x-axis line
      xAxis.selectAll('path,line').style('opacity', 0).remove();

      // Add Y-axis
      const yAxis = svg
        .append('g')
        .call(d3.axisLeft(yScale))
        .attr('class', 'y-axis')
        .style('font-weight', '500');

      d3.selectAll('.y-axis .tick').attr(
        'class',
        (d, i) => 'yAxis' + i + ' bar'
      );

      // Remove Y-axis Line
      yAxis.selectAll('path,line').style('opacity', 0).remove();

      // Add X-axis label
      svg
        .append('text')
        .attr('y', height + 30)
        .attr('x', 0)
        .attr('class', 'axis-text')
        .attr('fill', 'black')
        .text('Number of trips');
    },
    [data]
  );

  const redraw = useCallback(
    resizeArgs => {
      if (!resizeArgs) return;
      const { contentRect } = resizeArgs;
      let { width: nextWidth, height: nextHeight } = contentRect;
      nextHeight = nextHeight - INPUTS_HEIGHT;
      const { width, height } = dimensions;

      const reloadContent = (h, w) => {
        const content = document.getElementById('svg-traveltime-chart');
        if (!content) return;
        content.remove();
        drawScatterPlot(h, w);
      };

      if (nextHeight !== height || nextWidth !== width) {
        reloadContent(nextHeight, nextWidth);
        setDimensions({ width: nextWidth, height: nextHeight });
      }
    },
    [dimensions, drawScatterPlot]
  );

  useResizeObserver(
    document?.getElementsByClassName('Traveltimechart')?.[0],
    _.throttle(redraw, 1500, { trailing: false })
  );

  // On mount
  useEffect(() => {
    const chart = document.getElementById('traveltime-chart');
    if (!chart) return;
    // Return early if chart exists for other lifecycle methods to pick up
    const content = document.getElementById('svg-traveltime-chart');
    if (!content) {
      const container =
        document?.getElementsByClassName('Traveltimechart')?.[0];
      drawScatterPlot(container.offsetHeight, container.offsetWidth);
      setDimensions({
        height: container.offsetHeight - INPUTS_HEIGHT,
        width: container.offsetWidth,
      });
    } else {
      content.remove();
      drawScatterPlot(dimensions.height, dimensions.width);
    }
  }, [drawScatterPlot, data, dimensions]);

  return (
    <div className="Traveltimechart">
      {isLoading || !data ? (
        <Loader />
      ) : (
        <>
          <div
            className="Traveltimechart-header menu-primary-label"
            title="TRANSIT SHARES VS AVG TRAVEL TIME RATIO BY OD PAIRS"
          >
            TRAVEL TIME COMPETITIVENESS
          </div>
          <div className="traveltime-chart-container">
            <div id="traveltime-chart" />
          </div>
        </>
      )}
    </div>
  );
}

export default Traveltimechart;
