import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './MapDrawModeInput.scss';
import ButtonSet from './ButtonSet';
import { ZONE_SYSTEM_TYPES } from '../constants';

function MapDrawModeInput({ mapDrawStore, mapStore, zoneType }) {
  const { t } = useTranslation();
  const mapDraw = mapDrawStore(state => state.mapDraw);
  const currentMode = mapDrawStore(state => state.activeMode);
  const setCurrentMode = mapDrawStore(state => state.setActiveMode);
  const setLayerShape = mapStore(state => state.setLayerShape);
  const layerShape = mapStore(state => state.layerShape);
  const setSelectedGeoIds = mapStore(state => state.setSelectedGeoIds);
  const selectedGeoIds = mapStore(state => state.selectedGeoIds);

  const modeOptions = [
    { label: t('mapMode.polygon'), value: 'polygon' },
    { label: t('mapMode.freehand'), value: 'freehand' },
    { label: t('mapMode.rectangle'), value: 'rectangle' },
    { label: t('mapMode.point'), value: 'point' },
    { label: t('mapMode.clear'), value: 'clear' },
  ];

  const onChange = useCallback(
    newMode => {
      let modeToSet = newMode === '' ? 'select' : newMode;
      if (modeToSet === currentMode) modeToSet = 'select';

      if (modeToSet === 'clear') {
        const ids = mapDraw.getSnapshot().map(({ id }) => id);
        mapDraw.removeFeatures(ids);
        modeToSet = 'select';
        setSelectedGeoIds(null);
        setLayerShape(null);
      }

      setCurrentMode(modeToSet);
    },
    [currentMode, mapDraw, setCurrentMode]
  );

  // Set map draw mode state in useEffect hook so we always start on 'select'
  useEffect(() => {
    if (!mapDraw) return;
    mapDraw.setMode(currentMode);
  }, [mapDraw, currentMode]);

  return (
    <div className="MapDrawModeInput">
      <ButtonSet
        options={modeOptions}
        onToggle={onChange}
        selected={[currentMode]}
      />
    </div>
  );
}

export default MapDrawModeInput;
