import React, { useState, useEffect, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import './FiltersMenu.scss';
import FiltersContainer from '../FiltersContainer';

function FiltersMenu({
  onChangeProperties,
  filterValues,
  filters,
  initialFilters,
}) {
  const { t } = useTranslation();
  const updatedFilterValues = useMemo(() => {
    const nextFilterValues = { ...filterValues };
    delete nextFilterValues['zoneSystem'];
    return nextFilterValues;
  }, [filterValues]);

  const [unconfirmedFilterState, setUnconfirmedFilterState] = useState(
    filters ?? {}
  );

  const [selectedStateCounty, setSelectedStateCounty] = useState({});

  const updateUnconfirmedState = useCallback(
    value => {
      setUnconfirmedFilterState({
        ...unconfirmedFilterState,
        ...value,
        ...selectedStateCounty,
      });
    },
    [unconfirmedFilterState, selectedStateCounty]
  );

  const onConfirm = useCallback(
    (unconfirmedData, stateCountyData) => {
      let nextFilterState = stateCountyData
        ? { ...unconfirmedData, ...stateCountyData }
        : { ...unconfirmedFilterState, ...selectedStateCounty };
      nextFilterState = Object.entries(nextFilterState).reduce(
        (acc, [k, v]) => {
          if (!v.includes('all')) {
            acc[k] = v;
          }
          return acc;
        },
        {}
      );
      const isSame = Object.keys({ ...filters, ...nextFilterState }).every(
        k => {
          return (
            JSON.stringify(filters[k]?.sort()) ===
            JSON.stringify(nextFilterState[k]?.sort())
          );
        }
      );

      if (!isSame) {
        onChangeProperties(nextFilterState);
      }
    },
    [unconfirmedFilterState, onChangeProperties, filters, selectedStateCounty]
  );

  const resetHandler = () => {
    const data = {
      origin_states: [],
      origin_counties: [],
      destination_states: [],
      destination_counties: [],
    };
    setUnconfirmedFilterState({
      ...updatedFilterValues,
      ...initialFilters,
      ...data,
    });
    setSelectedStateCounty(data);
    onConfirm(
      {
        ...updatedFilterValues,
        ...initialFilters,
        ...data,
      },
      data
    );
  };

  useEffect(() => {
    setUnconfirmedFilterState(filters);
  }, [filters]);

  return (
    <div className="FiltersMenu">
      <div className="menu-header-label FiltersMenu-header-container">
        {t('mapMenu.filters')}
        <div className="FiltersMenu-apply-button-container">
          <div className="FiltersMenu-apply-button" onClick={() => onConfirm()}>
            {t('mapMenu.apply')}
          </div>
          <div
            className={classnames('FiltersMenu-reset-button', {
              disabled: _.isEmpty(initialFilters),
            })}
            onClick={resetHandler}
          >
            {t('mapMenu.reset')}
          </div>
        </div>
      </div>
      <FiltersContainer
        onChangeProperties={updateUnconfirmedState}
        filterValues={updatedFilterValues}
        filters={unconfirmedFilterState}
        onChangeStateCounty={setSelectedStateCounty}
        stateCountyValues={selectedStateCounty}
      />
    </div>
  );
}

export default FiltersMenu;
