import React, { useCallback, useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import './MapZoom.scss';
import { ReactComponent as Plus } from '../images/plus.svg';
import { ReactComponent as Minus } from '../images/minus.svg';
import { ReactComponent as North } from '../images/north.svg';
import { ReactComponent as Location } from '../images/location.svg';
import { ReactComponent as Selections } from '../images/selections.svg';
import { ReactComponent as ODFlow } from '../images/odFlowIcon.svg';
import { ReactComponent as Sync } from '../images/sync.svg';
import * as turf from 'turf';
import MenuContainer from './MenuContainer';
import SelectionsMenu from './menus/SelectionsMenu';
import { useMapStore as useOriginMapStore } from '../store/origin-map';
import { minZoom } from '../constants';

function MapZoom({
  activeDashboardInstance,
  direction,
  highlightedGeoids,
  mapStore,
  mapDrawStore,
  allowMapSync,
  zoneType,
  boundingShape,
  setMenuOpen,
  menuOpen,
  setOdFlowFlag,
  odFlowFlag,
}) {
  const { t } = useTranslation();
  const mapInstance = mapStore(state => state.mapInstance);
  const mapState = mapStore(state => ({
    center: state.center,
    zoom: state.zoom,
    bearing: state.bearing,
    pitch: state.pitch,
    isUpdateByScroll: state.isUpdateByScroll,
    isMapUpdated: state.isMapUpdated,
  }));
  const setMapState = mapStore(state => state.setMapState);
  const mapDrawInstance = mapDrawStore(state => state.mapDraw);
  const selectedGeoid = mapStore(state => state.selectedGeoid);
  const setSelectedGeoid = mapStore(state => state.setSelectedGeoid);
  const selectedGeoIds = mapStore(state => state.selectedGeoIds);
  const mapSelectionFeatures = mapStore(state => state.mapSelectionFeatures);

  // This is only for syncing maps which can only happen on destination
  const originMapState = useOriginMapStore(state => ({
    center: state.center,
    zoom: state.zoom,
    bearing: state.bearing,
    pitch: state.pitch,
    isUpdateByScroll: false,
    isMapUpdated: true,
  }));

  const onZoomIn = useCallback(() => {
    const nextMapState = { ...mapState };
    nextMapState.zoom = nextMapState.zoom + 1;
    nextMapState.isUpdateByScroll = false;
    nextMapState.isMapUpdated = true;
    setMapState(nextMapState);
  }, [mapState, setMapState]);

  const onZoomOut = useCallback(() => {
    const nextMapState = { ...mapState };
    nextMapState.zoom = nextMapState.zoom - 1;
    nextMapState.isUpdateByScroll = false;
    nextMapState.isMapUpdated = true;
    setMapState(nextMapState);
  }, [mapState, setMapState]);

  const onSetNorth = useCallback(() => {
    setMapState({
      ...mapState,
      bearing: 0,
      pitch: 0,
      isUpdateByScroll: false,
      isMapUpdated: true,
    });
  }, [mapState, setMapState]);

  const onSelectZoom = useCallback(
    (v, selectedFeatures) => {
      if (!mapInstance) return;
      switch (v) {
        case 'study_area': {
          mapInstance.fitBounds([
            [boundingShape?.minX, boundingShape?.minY],
            [boundingShape?.maxX, boundingShape?.maxY],
          ]);
          break;
        }
        case 'selections': {
          if (!mapSelectionFeatures?.length) return;
          const selectedGeojson = {
            type: 'FeatureCollection',
            features: mapSelectionFeatures,
          };
          // TODO, we should consider adding a buffer
          const bounds = turf.bbox(selectedGeojson);
          mapInstance.fitBounds(bounds);
          break;
        }
        default:
      }
    },
    [activeDashboardInstance, mapInstance, mapSelectionFeatures]
  );

  const syncMaps = useCallback(() => {
    setMapState(originMapState);
  }, [originMapState, setMapState]);

  const renderMenu = useCallback(() => {
    if (menuOpen === 'zoom') {
      let zoomOptions = [
        { label: t('mapMenu.zoomStudyArea'), value: 'study_area' },
      ];
      if (highlightedGeoids.length || selectedGeoIds?.[zoneType]?.length) {
        zoomOptions = zoomOptions.concat([
          {
            label: t('mapMenu.zoomSelections'),
            value: 'selections',
          },
        ]);
      }
      return (
        <MenuContainer closeMenu={() => setMenuOpen(null)}>
          <div className="MapZoom-zoom-button-container">
            {zoomOptions.map((o, i) => (
              <div
                key={i}
                className="MapZoom-zoom-button"
                onClick={() =>
                  onSelectZoom(
                    o.value,
                    mapDrawInstance ? mapDrawInstance.getSnapshot() : null
                  )
                }
              >
                {o.label}
              </div>
            ))}
          </div>
        </MenuContainer>
      );
    }
    if (menuOpen === 'selections') {
      return (
        <MenuContainer closeMenu={() => setMenuOpen(null)}>
          <SelectionsMenu
            direction={direction.includes('origin') ? 'origin' : 'destination'}
            highlightedGeoids={highlightedGeoids}
            mapDrawStore={mapDrawStore}
            mapStore={mapStore}
            zoneType={zoneType}
          />
        </MenuContainer>
      );
    }
  }, [
    highlightedGeoids,
    mapDrawInstance,
    mapDrawStore,
    direction,
    onSelectZoom,
    menuOpen,
  ]);

  const odFlowHandler = () => {
    setSelectedGeoid(null);
    setOdFlowFlag(prev => !prev);
  };

  const MAP_MENUS = useMemo(() => {
    let menus = [
      {
        id: 'plus',
        icon: Plus,
        onClick: onZoomIn,
        disabled: mapState.zoom >= 20,
        label: t('mapMenu.zoomIn'),
      },
      {
        id: 'minus',
        icon: Minus,
        onClick: onZoomOut,
        disabled: mapState.zoom <= minZoom,
        label: t('mapMenu.zoomOut'),
      },
      {
        id: 'selections',
        icon: Selections,
        onClick: () =>
          setMenuOpen(o => (o !== 'selections' ? 'selections' : null)),
        label: t('mapMenu.selections'),
        disabled: !!selectedGeoid,
      },
      {
        id: 'location',
        icon: Location,
        onClick: () => setMenuOpen(o => (o !== 'zoom' ? 'zoom' : null)),
        label: t('mapMenu.resetZoom'),
      },

      {
        id: 'north',
        icon: North,
        onClick: onSetNorth,
        label: t('mapMenu.resetMap'),
      },
      // To do in future
      // {
      //   id: 'odflows',
      //   icon: ODFlow,
      //   onClick: odFlowHandler,
      //   label: t('mapMenu.odflow'),
      //   selected: odFlowFlag,
      // },
    ];
    if (allowMapSync) {
      menus.push({
        id: 'sync',
        icon: Sync,
        onClick: syncMaps,
        label: t('mapMenu.syncMaps'),
      });
    }
    return menus;
  }, [
    allowMapSync,
    onZoomIn,
    onZoomOut,
    onSetNorth,
    mapState,
    syncMaps,
    selectedGeoid,
  ]);

  useEffect(() => {
    if (!menuOpen) return;
    const currentMenu = MAP_MENUS.find(m => m.id === menuOpen);
    if (currentMenu?.disabled) {
      setMenuOpen(null);
    }
  }, [menuOpen, MAP_MENUS]);

  return (
    <div className="MapZoom drop-shadow">
      {MAP_MENUS.map((menu, i) => (
        <React.Fragment key={i}>
          <div
            onClick={menu?.onClick}
            title={menu.label}
            className={classnames('MapZoom-button', {
              disabled: menu?.disabled ?? false,
              selected: menu?.selected ?? false,
            })}
          >
            <div
              className="MapZoom-icon-container"
              style={{
                transform: `rotate(${
                  menu?.id === 'north' ? -1 * mapState.bearing : 0
                }deg)`,
                transition: 'transform 100ms ease',
              }}
            >
              {React.createElement(menu?.icon, {})}
            </div>
          </div>
          {menu?.id === 'location' && menuOpen === 'zoom' ? (
            <div className="MapZoom-zoom-menu">{renderMenu()}</div>
          ) : null}
          {menu?.id === 'selections' && menuOpen === 'selections' ? (
            <div className="MapZoom-selections-menu">{renderMenu()}</div>
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
}

export default MapZoom;
