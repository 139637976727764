import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Close } from '../../images/close.svg';
import './ODPairsTableFilter.scss';
import { CompetitivenessLabels } from '../../constants';
import Dropdown from '../../components/Dropdown';
import NumberInput from '../../components/NumberInput';
import { useTransitItineraryStore } from '../../store/transit-itinerary';

const ODPairsTableFilter = ({ closeMenu }) => {
  const { t } = useTranslation();
  const initialData = {
    competitiveness: 'All',
    minimunNoTotalTrips: 0,
    minimunNoTransitTrips: 0,
  };
  const odPairTableFilters = useTransitItineraryStore(
    state => state.odPairTableFilters
  );
  const setODPairTableFilters = useTransitItineraryStore(
    state => state.setODPairTableFilters
  );

  const updateValues = (value, key) => {
    const tempData = JSON.parse(JSON.stringify(odPairTableFilters));
    tempData[key] = value;
    setODPairTableFilters(tempData);
  };

  return (
    <div className="TableFilterMenu">
      <div className="TableFilterMenu-header">
        <div className="TableFilterMenu-header-label">
          {t('odpairsFilters.odPairsTableFilters')}
        </div>
        <div
          className="TableFilterMenu-header-reset-label"
          onClick={() => setODPairTableFilters(initialData)}
        >
          {t('odpairsFilters.resetAll')}
        </div>
      </div>
      <div className="TableFilterMenu-section">
        <div className="TableFilterMenu-section-header-label">
          {t('odpairsFilters.competitiveness')}
        </div>
        <Dropdown
          selectedValue={odPairTableFilters.competitiveness}
          options={CompetitivenessLabels}
          onChange={value => updateValues(value, 'competitiveness')}
          optionsPosition="bottom"
        />
      </div>
      <div className="TableFilterMenu-section">
        <div className="TableFilterMenu-section-header-label">
          {t('odpairsFilters.minNumberOfTotalTrips')}
        </div>
        <NumberInput
          value={odPairTableFilters.minimunNoTotalTrips}
          onChange={value => updateValues(value, 'minimunNoTotalTrips')}
        />
      </div>
      <div className="TableFilterMenu-section">
        <div className="TableFilterMenu-section-header-label">
          {t('odpairsFilters.minNumberOfTransitTrips')}
        </div>
        <NumberInput
          value={odPairTableFilters.minimunNoTransitTrips}
          onChange={value => updateValues(value, 'minimunNoTransitTrips')}
        />
      </div>
      <div onClick={closeMenu} className="TableFilterMenu-close-button">
        <Close />
      </div>
    </div>
  );
};

export default ODPairsTableFilter;
