import React from 'react';
import './TransitStats.scss';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader';
import { getHumanReadableNumber } from '../../constants';

const TransitStats = ({ stats }) => {
  const { t } = useTranslation();

  const statDetails = [
    {
      label: t('transitStats.dailyTrips'),
      value: getHumanReadableNumber(stats?.total_trips),
    },
    {
      label: t('transitStats.transitTrips'),
      value: getHumanReadableNumber(stats?.transit_trips),
    },
    {
      label: t('transitStats.avgTravelTimeRatio'),
      value: getHumanReadableNumber(stats?.avg_travel_time_ratio),
    },
    {
      label: t('transitStats.peakPeriodShare'),
      value: `${getHumanReadableNumber(stats?.peak_period_share * 100)}%`,
    },
    {
      label: t('transitStats.commuteShare'),
      value: `${getHumanReadableNumber(stats?.commute_share) * 100}%`,
    },
    {
      label: t('transitStats.avgTransfers'),
      value: getHumanReadableNumber(stats?.avg_transfers),
    },
    {
      label: t('transitStats.avgTripDistance'),
      value: `${getHumanReadableNumber(stats?.avg_trip_distance)} mi`,
    },
    {
      label: t('transitStats.noViableItinerary'),
      value: `${getHumanReadableNumber(stats?.no_viable_itinerary * 100)}%`,
    },
  ];

  return (
    <div className="Stats">
      <>
        {!stats ? (
          <Loader />
        ) : (
          statDetails.map(item => (
            <div>
              <div className="Stats-value">{item.value}</div>
              <div className="Stats-label">{item.label}</div>
            </div>
          ))
        )}
      </>
    </div>
  );
};

export default TransitStats;
