import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as d3 from 'd3';
import { saveAs } from 'file-saver';
import hat from 'hat';
import './DownloadMenu.scss';
import classNames from 'classnames';
import { loadTripEndpoints } from '../../connectors';
import {
  getCurrentDate,
  isNotCustomZone,
  updateFilterValues,
} from '../../constants';
import Tooltip from '../custom-components/Tooltip';
import { useLoadingStore } from '../../store/loaders';
import { useMapStore as originMapStore } from '../../store/origin-map';
import { useMapStore as destinationMapStore } from '../../store/destination-map';

const ODFlowsDownloadMenu = ({
  data,
  activeDashboardInstance,
  highlightedGeoidsOrigin,
  highlightedGeoidsDestination,
  zoneType,
  filterValues,
}) => {
  const [numberOfODs, setNumberOfODs] = useState('');
  const { t } = useTranslation();
  const setLoadingState = useLoadingStore(state => state.setLoadingState);
  const loader = useRef();
  const originSelectedGeoIds = originMapStore(state => state.selectedGeoIds);
  const destinationSelectionGeoIds = destinationMapStore(
    state => state.selectedGeoIds
  );

  const downloadCsv = useCallback(
    async (originData, name) => {
      const csv = await d3.csvFormat(originData);
      const blob = new Blob([csv], { type: 'text/csv' });
      saveAs(blob, `${name}.csv`);
      setLoadingState('downloadMenu', loader.current);
    },
    [loader.current, setLoadingState]
  );

  const downloadODFlows = async () => {
    loader.current = hat();
    setLoadingState('downloadMenu', loader.current);
    let nextFilters = updateFilterValues(data, filterValues);
    const modifiedFilters = JSON.parse(JSON.stringify(nextFilters));
    delete modifiedFilters.zoneSystem;

    modifiedFilters.zone_system = [zoneType];
    if (highlightedGeoidsDestination.length) {
      modifiedFilters['destination_geo'] = highlightedGeoidsDestination;
    } else if (destinationSelectionGeoIds?.[zoneType]?.length) {
      modifiedFilters['destination_geo'] =
        destinationSelectionGeoIds?.[zoneType];
    }

    if (highlightedGeoidsOrigin.length) {
      modifiedFilters['origin_geo'] = highlightedGeoidsOrigin;
    } else if (originSelectedGeoIds?.[zoneType]?.length) {
      modifiedFilters['origin_geo'] = originSelectedGeoIds?.[zoneType];
    }

    let tripEndpointsData = await loadTripEndpoints(
      activeDashboardInstance,
      null,
      null,
      modifiedFilters,
      parseInt(numberOfODs)
    );

    if (!isNotCustomZone(zoneType)) {
      const zones = activeDashboardInstance?.zoneSystems.find(
        item => item.name === zoneType
      ).zones;
      const zoneLabels = {};
      zones.forEach(item => {
        zoneLabels[item.label] = item.name;
      });
      tripEndpointsData.forEach(item => {
        item.origin_name = zoneLabels[item.origin_geo];
        item.destination_name = zoneLabels[item.destination_geo];
      });
    }

    tripEndpointsData.forEach(item => {
      item.daily_trips = parseFloat(item?.trip_result.toFixed(2));
      item.daily_pmt = parseFloat(item?.pmt_result.toFixed(2));
      delete item?.trip_result;
      delete item?.pmt_result;
    });
    const viewName = activeDashboardInstance?.viewName;
    const name = `${viewName}-${getCurrentDate()}-${numberOfODs}-odFlows`;
    downloadCsv(tripEndpointsData, name);
  };

  const handleChange = event => {
    const inputValue = event.target.value;

    if (inputValue === '') {
      setNumberOfODs('');
    }
    if (/^\d*$/.test(inputValue)) {
      const numericValue = parseInt(inputValue);
      if (numericValue <= 5000) {
        setNumberOfODs(inputValue);
      }
    }
  };

  return (
    <div className="DownloadMenu-section DownloadMenu-odFlows-block">
      <div className="DownloadMenu-odMenuHeader">
        <div className="menu-primary-label">
          {t('downloadMenu.downloadOdFlows')}
        </div>
        <Tooltip />
      </div>
      <div className="DownloadMenu-row-container">
        <input
          type="text"
          value={numberOfODs}
          onChange={handleChange}
          placeholder={t('downloadMenu.enterNumberOfODFlows')}
          className="DownloadMenu-input Dropdown-current dark"
        />
        <div
          className={classNames('DownloadMenu-button', {
            disabled: !numberOfODs,
          })}
          onClick={() => downloadODFlows()}
        >
          {t('general.download')}
        </div>
      </div>
    </div>
  );
};

export default ODFlowsDownloadMenu;
