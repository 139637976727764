import React from 'react';
import './NumberInput.scss';

function NumberInput({ onChange, min, max, value, step }) {
  const onUpdate = e => {
    onChange(Number(e.target.value));
  };
  return (
    <div className="NumberInput">
      <input
        type="number"
        min={min}
        max={max}
        step={step ?? 1}
        value={value}
        onChange={onUpdate}
        className="NumberInput-input"
      />
    </div>
  );
}

export default NumberInput;
