import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { ReactComponent as CaretDown } from '../images/caret_down.svg';
import { ReactComponent as CaretUp } from '../images/caret_up.svg';
import './Dropdown.scss';

function Dropdown({
  selectedValue,
  options,
  onChange,
  optionsPosition = 'bottom',
  disabled,
  // Optional prop fn to coordinate between multiple dropdowns
  setOpenState = () => false,
  isOpen = null,
  dark,
  placeholder = null,
}) {
  const [isSelecting, setIsSelecting] = useState(false);

  const setOpen = useCallback(
    bool => {
      setOpenState(bool);
      setIsSelecting(bool);
    },
    [setOpenState, setIsSelecting]
  );

  const setValue = nextValue => {
    onChange(nextValue);
    setOpen(false);
  };

  const getSelectedLabel = useCallback(
    (dropdownOptions, selectedOption) => {
      return (
        dropdownOptions.find(o => {
          const { value } = o;
          if (typeof value === 'object') {
            return JSON.stringify(value) === JSON.stringify(selectedOption);
          } else {
            return value === selectedOption;
          }
        })?.label ?? placeholder
      );
    },
    [placeholder]
  );

  const getCurrentSelected = useCallback(
    (dropdownOptions, selectedOption, isOpen) => {
      const fn = disabled ? () => false : () => setOpen(!isOpen);
      return (
        <div
          className={classnames('Dropdown-current', { dark, disabled })}
          onClick={fn}
        >
          <span className="Dropdown-current-label">
            {getSelectedLabel(dropdownOptions, selectedOption)}
          </span>
          <span className={classnames('Dropdown-caret', { dark })}>
            {(isOpen && optionsPosition === 'bottom') ||
            (!isOpen && optionsPosition === 'top') ? (
              <CaretUp />
            ) : (
              <CaretDown />
            )}
          </span>
        </div>
      );
    },
    [disabled, optionsPosition, setOpen, getSelectedLabel]
  );

  // Close dropdown when others are open and coordinated
  useEffect(() => {
    // null means this property is not being used
    if (isOpen === null) return;
    if (!isOpen) {
      setIsSelecting(false);
    }
  }, [isOpen]);

  return (
    <div
      className={classnames('Dropdown', {
        'position-top': optionsPosition === 'top',
        'position-bottom': optionsPosition === 'bottom',
        dark,
      })}
    >
      {isSelecting ? (
        <div className={classnames('Dropdown-border-helper-left', { dark })} />
      ) : null}
      {isSelecting ? (
        <>
          {optionsPosition === 'bottom'
            ? getCurrentSelected(options, selectedValue, isSelecting, disabled)
            : null}
          <ul className={classnames('Dropdown-list', { dark })}>
            {options.map(({ value, label }, i) => (
              <li
                className={classnames('Dropdown-option', { dark })}
                key={i}
                onClick={e => setValue(value)}
              >
                {label}
              </li>
            ))}
          </ul>
          {optionsPosition === 'top'
            ? getCurrentSelected(options, selectedValue, isSelecting, disabled)
            : null}
        </>
      ) : (
        getCurrentSelected(options, selectedValue, isSelecting)
      )}
      {isSelecting ? (
        <div className={classnames('Dropdown-border-helper-right', { dark })} />
      ) : null}
    </div>
  );
}

export default Dropdown;
