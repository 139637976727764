import React from 'react';
import classnames from 'classnames';
import './MenuContainer.scss';
import { ReactComponent as Close } from '../images/close.svg';

function MenuContainer({ children, closeMenu, dark, hideClose, downloadMenu }) {
  return (
    <div
      className={classnames(
        'MenuContainer drop-shadow',
        { dark },
        { downloadMenu }
      )}
    >
      {!hideClose ? (
        <div onClick={closeMenu} className="MenuContainer-close-button">
          <Close />
        </div>
      ) : null}
      {children}
    </div>
  );
}

export default MenuContainer;
