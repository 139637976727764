import { TRANSIT_URL, API_KEY, DASHBOARD_API_URL } from '../constants';

export const getTransitDashboardDetails = async dashboardId => {
  const url = `${DASHBOARD_API_URL}/dashboarddetails/${dashboardId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
    },
  });

  return (await response.json()).data;
};

export const statsDataQuery = async (
  userId,
  dashboardId,
  clientId,
  datasetId,
  filters,
  accessToken
) => {
  const response = await fetch(`${TRANSIT_URL}/totalstats`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      clientId,
      dashboardId,
      datasetId,
      userId,
      filterValues: {
        purpose: [1, 2],
        day_of_week: [1, 4],
        equity: [1],
      },
    }),
  });

  return (await response.json()).data;
};
const mapDataQuery = async (
  userId,
  dashboardId,
  clientId,
  datasetId,
  filters,
  direction,
  accessToken
) => {
  const directionField =
    direction === 'origin' ? 'origin_geomarket' : 'destination_geomarket';
  const response = await fetch(`${TRANSIT_URL}/mapdata`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      clientId,
      dashboardId,
      direction: directionField,
      datasetId,
      userId,
      filterValues: {
        purpose: [1, 2],
        day_of_week: [1, 4],
        equity: [1],
      },
    }),
  });

  return (await response.json()).data;
};

const odPairsDataQuery = async (
  userId,
  dashboardId,
  clientId,
  datasetId,
  filters,
  accessToken
) => {
  const response = await fetch(`${TRANSIT_URL}/odpairs`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      clientId,
      dashboardId,
      datasetId,
      userId,
      filterValues: {
        purpose: [1, 2],
        day_of_week: [1, 4],
        equity: [1],
      },
    }),
  });

  return (await response.json()).data;
};

const itineraryDataQuery = async (
  userId,
  dashboardId,
  clientId,
  datasetId,
  origin_geomarket,
  destination_geomarket,
  accessToken
) => {
  const response = await fetch(`${TRANSIT_URL}/itinerary`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      clientId,
      dashboardId,
      datasetId,
      origin_geomarket,
      destination_geomarket,
      userId,
    }),
  });

  return (await response.json()).data;
};

export const getTransitMapData = async (
  userId,
  dashboardId,
  clientId,
  datasetId,
  filters,
  direction,
  accessToken
) => {
  const directionField =
    direction === 'origin' ? 'origin_geomarket' : 'destination_geomarket';
  const data = await mapDataQuery(
    userId,
    dashboardId,
    clientId,
    datasetId,
    filters,
    directionField,
    accessToken
  );
  const result = {};

  data.forEach(item => {
    item.zoneName = item?.geomarket;
    const key = item?.geomarket;
    result[key] = item;
  });

  return result;
};

export const getODPairsData = async (
  userId,
  dashboardId,
  clientId,
  datasetId,
  filters,
  accessToken
) => {
  const data = await odPairsDataQuery(
    userId,
    dashboardId,
    clientId,
    datasetId,
    filters,
    accessToken
  );

  data.forEach(item => {
    item.transitShare = (item?.transit_trips / item?.total_trips) * 100;
  });
  return data;
};

export const getItineraryData = async (
  userId,
  dashboardId,
  clientId,
  datasetId,
  origin_geomarket,
  destination_geomarket,
  accessToken
) => {
  const data = await itineraryDataQuery(
    userId,
    dashboardId,
    clientId,
    datasetId,
    origin_geomarket,
    destination_geomarket,
    accessToken
  );

  return data;
};
