import { create } from 'zustand';

export const useApiRequestsStore = create((set, get) => ({
  latestCalls: {},
  setLatestCalls: (fnName, args) =>
    set(state => {
      return {
        ...state,
        latestCalls: { ...state.latestCalls, [fnName]: args },
      };
    }),
  allowRequest: (fnName, ...args) => {
    const nextArgs = JSON.stringify(args);
    const { latestCalls, setLatestCalls } = get();
    let allow = true;
    if (latestCalls?.[fnName]) {
      const prevArgs = latestCalls?.[fnName];
      allow = prevArgs !== nextArgs;
    }
    setLatestCalls(fnName, nextArgs);
    return allow;
  },
}));
