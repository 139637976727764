import React from 'react';
import classnames from 'classnames';
import './ButtonSet.scss';

function ButtonSet({ options, onToggle, selected, dark = false }) {
  return (
    <div className="ButtonSet">
      {options.map((option, i) => (
        <div
          key={i}
          className={classnames('ButtonSet-button', {
            selected: selected && selected.includes(option.value),
            dark,
          })}
          onClick={() => onToggle(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
}

export default ButtonSet;
