import React from 'react';
import './TransitBreakdown.scss';
import { TripBreakdownColorData } from '../../constants';
import classNames from 'classnames';

const TransitBreakDownColumns = [
  {
    label: 'Itinerary name',
    key: 'itinerary',
    active: null,
  },
  {
    label: 'Total time',
    key: 'total_time',
    active: null,
  },
  {
    label: 'modes',
    key: 'modes',
    active: null,
  },
];

const TransitModeData = [
  {
    label: 'Access',
    key: 'access',
    color: TripBreakdownColorData.access,
  },
  {
    label: 'Initial Wait',
    key: 'initial_wait',
    color: TripBreakdownColorData.initialWait,
  },
  {
    label: 'IVTT',
    key: 'ivtt',
    color: TripBreakdownColorData.ivtt,
  },
  {
    label: 'Transfer Walk',
    key: 'transfer_walk',
    color: TripBreakdownColorData.transferWalk,
  },
  {
    label: 'Transfer Wait',
    key: 'transfer_wait',
    color: TripBreakdownColorData.transferWait,
  },
  {
    label: 'Egress',
    key: 'egress',
    color: TripBreakdownColorData.egress,
  },
];

const TranistBreakdown = ({ tranistBreakdownData }) => {
  return (
    <>
      {TransitBreakDownColumns.map((column, i) => (
        <div
          key={i}
          className={classNames('Transitbreakdown-list-column', {
            mode: column.key === 'modes',
          })}
        >
          <div style={{ width: '100%' }}>
            {column.key !== 'modes' ? (
              <div className="Transitbreakdown-list-column-header">
                {column.label}
              </div>
            ) : (
              <div className="Transitbreakdown-list-mode">
                {TransitModeData.map((item, i) => (
                  <div className="Transitbreakdown-list-mode-column">
                    <div
                      className="Transitbreakdown-list-mode-column-indicator"
                      style={{ backgroundColor: item?.color }}
                    ></div>
                    <div className="Transitbreakdown-list-mode-column-label">
                      {item.label}
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="Transitbreakdown-list-container">
              {tranistBreakdownData.map((item, i) => (
                <div>
                  {column.key !== 'modes' ? (
                    <div
                      className="Transitbreakdown-list-container-label"
                      title={item[column.key]}
                    >
                      {item[column.key]}
                    </div>
                  ) : (
                    <div className="Transitbreakdown-list-container-breakdown">
                      {TransitModeData.map((modeData, i) => (
                        <div
                          style={{
                            backgroundColor: modeData?.color,
                            width: `${item[modeData.key]}%`,
                            minWidth: '20px',
                          }}
                        >
                          <div className="Transitbreakdown-list-container-breakdown-label">
                            {item[modeData.key]}%
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default TranistBreakdown;
