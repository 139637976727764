import React, { useCallback, useEffect, useState } from 'react';
import Dropdown from './Dropdown';
import ButtonSet from './ButtonSet';
import './FiltersContainer.scss';
import { useLoadingStore } from '../store/loaders';
import Loader from './Loader';
import { FILTERS_LABELS } from '../constants';
import { StateCountyTree } from './StateCountyTree';
import { useDashboardStore } from '../store/dashboard';

function FiltersContainer({
  onChangeProperties,
  filterValues,
  filters,
  onChangeStateCounty,
  stateCountyValues,
}) {
  const isLoading = useLoadingStore(state => state.isLoading.filters.length);
  const stateCounties = useDashboardStore(state => state.stateCounties);
  const [selectedStateCounty, setSelectedStateCounty] = useState({});

  const onSubmit = useCallback(
    state => {
      onChangeProperties(state);
    },
    [onChangeProperties]
  );

  const onSelectFilter = useCallback(
    (property, value) => {
      let nextFilterState;
      if (property === 'period') {
        if (!filters[property].includes(value)) {
          nextFilterState = { ...filters, [property]: [value] };
        }
      } else {
        if (
          filters[property]?.length === 1 &&
          filters[property].includes(value)
        ) {
          nextFilterState = { ...filters, [property]: [value] };
        } else {
          let nextValue = filters[property].includes(value)
            ? filters[property].filter(v => v !== value)
            : filters[property].concat([value]);
          nextFilterState = { ...filters, [property]: nextValue };
        }
      }
      onSubmit({ ...nextFilterState, ...selectedStateCounty });
    },
    [onSubmit, filters, selectedStateCounty]
  );

  const getFilterLabels = (property, values) => {
    let nextValues = values;
    const additionalValues = FILTERS_LABELS.find(
      v => property === v.value
    )?.add;
    if (additionalValues) {
      nextValues = nextValues.concat(additionalValues);
    }
    return nextValues.map(v => ({ label: v, value: v }));
  };

  const getFilterHeader = property => {
    let header = FILTERS_LABELS.find(v => property === v.value)?.label;
    if (!header) {
      header = property.replaceAll('_', ' ');
      header = header.toUpperCase();
    }
    return header;
  };

  const getControlType = property => {
    const control = FILTERS_LABELS.find(v => property === v.value)?.control;
    return control ?? 'buttons';
  };

  const updateStateCounty = useCallback((arr, type) => {
    if (arr?.length > 0) {
      const stateIds = arr
        ?.filter(item => item?.value?.length <= 2)
        ?.map(item => item?.value);
      const countyIds = arr
        ?.filter(item => item?.value?.length > 2)
        ?.map(item => item?.value);
      const data = {
        [`${type}_states`]: stateIds ?? [],
        [`${type}_counties`]: countyIds ?? [],
      };
      setSelectedStateCounty({
        ...data,
        ...selectedStateCounty,
      });
    } else {
      const data = {
        [`${type}_states`]: [],
        [`${type}_counties`]: [],
      };
      setSelectedStateCounty({
        ...data,
        ...selectedStateCounty,
      });
    }
  }, []);

  useEffect(() => {
    selectedStateCounty &&
      onChangeStateCounty({
        ...stateCountyValues,
        ...selectedStateCounty,
      });
  }, [selectedStateCounty]);

  return (
    <div className="FiltersContainer">
      {!isLoading && filterValues ? (
        <React.Fragment>
          {Object.entries(filterValues).map(([property, values]) => (
            <div key={property} className="FiltersContainer-section">
              <div className="menu-primary-label">
                {getFilterHeader(property)}
              </div>
              {getControlType(property) === 'buttons' ? (
                <ButtonSet
                  selected={filters?.[property] ?? values}
                  options={getFilterLabels(property, values)}
                  onToggle={v => onSelectFilter(property, v)}
                />
              ) : (
                <Dropdown
                  selectedValue={filters?.[property] ?? 'all'}
                  options={getFilterLabels(property, values)}
                  onChange={v => onSelectFilter(property, v)}
                />
              )}
            </div>
          ))}
          {/* To do in future */}
          {/* <div className="menu-primary-label">ORIGIN STATE COUNTY</div>
          <StateCountyTree
            options={stateCounties}
            type="origin"
            setSelectedStateCounty={updateStateCounty}
            value={filters}
          />
          <div className="menu-primary-label">DESTINATION STATE COUNTY</div>
          <StateCountyTree
            type="destination"
            options={stateCounties}
            setSelectedStateCounty={updateStateCounty}
            value={filters}
          /> */}
        </React.Fragment>
      ) : (
        <div className="FiltersContainer-loader-container">
          <Loader />
        </div>
      )}
    </div>
  );
}

export default FiltersContainer;
