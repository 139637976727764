import React from 'react';
import classnames from 'classnames';
import './RadioButtonSet.scss';

function RadioButtonSet({ options, onToggle, selected }) {
  return (
    <div className="RadioButtonSet">
      {options.map((option, i) => (
        <div
          key={i}
          className={classnames('RadioButtonSet-container', {
            selected: selected && selected.includes(option.value),
          })}
          onClick={() => onToggle(option.value)}
        >
          <div
            className={classnames('RadioButtonSet-button-border', { selected })}
          >
            {selected && selected.includes(option.value) ? (
              <div className="RadioButtonSet-button-center" />
            ) : null}
          </div>
          <div className="RadioButtonSet-label"> {option.label} </div>
        </div>
      ))}
    </div>
  );
}

export default RadioButtonSet;
