import React from 'react';
import './ColorInput.scss';

const ColorInput = ({ onChange, value }) => (
  <div className="ColorInput">
    <input
      type="color"
      value={value}
      onChange={e => onChange(e.target.value)}
      className="ColorInput-input"
    />
  </div>
);

export default ColorInput;
