import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoCircleOutlined } from '../../images/InfoCircleOutlined.svg';
import './Tooltip.scss';

const Tooltip = () => {
  const { t } = useTranslation();
  return (
    <div class="tooltip-container">
      <span class="info-icon">
        <InfoCircleOutlined />
      </span>
      <div class="tooltip">{t('downloadMenu.downloadTopNOdFlows')}</div>
    </div>
  );
};

export default Tooltip;
