import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Close } from '../../images/close.svg';
import './ODPairsTableFilter.scss';
import { WeaknessLabels } from '../../constants';
import Dropdown from '../../components/Dropdown';
import NumberInput from '../../components/NumberInput';
import { useTransitItineraryStore } from '../../store/transit-itinerary';

const ItinerariesTableFilters = ({ closeMenu }) => {
  const { t } = useTranslation();
  const initialData = {
    weakness: 'All',
    minimunNoTotalTrips: 0,
    minimunNoTransitTrips: 0,
  };
  const ivttPercentage = useTransitItineraryStore(
    state => state.ivttPercentage
  );
  const walkTimePercentage = useTransitItineraryStore(
    state => state.walkTimePercentage
  );
  const setIvttPercentage = useTransitItineraryStore(
    state => state.setIvttPercentage
  );
  const itineraryTableFilters = useTransitItineraryStore(
    state => state.itineraryTableFilters
  );
  const setItineraryTableFilters = useTransitItineraryStore(
    state => state.setItineraryTableFilters
  );
  const setWalkTimePercentage = useTransitItineraryStore(
    state => state.setWalkTimePercentage
  );
  const targetCompetitiveness = useTransitItineraryStore(
    state => state.targetCompetitiveness
  );
  const setTargetCompetitiveness = useTransitItineraryStore(
    state => state.setTargetCompetitiveness
  );
  const updateValues = (value, key) => {
    const tempData = { ...itineraryTableFilters };
    tempData[key] = value;
    setItineraryTableFilters(tempData);
  };

  return (
    <div className="TableFilterMenu">
      <div className="TableFilterMenu-header">
        <div className="TableFilterMenu-header-label">
          {t('itinerariesTableFilters.itinerariesTableFilters')}
        </div>
        <div
          className="TableFilterMenu-header-reset-label"
          onClick={() => setItineraryTableFilters(initialData)}
        >
          {t('odpairsFilters.resetAll')}
        </div>
      </div>
      <div className="TableFilterMenu-section">
        <div className="TableFilterMenu-section-header-label">
          {t('itinerariesTableFilters.weakness')}
        </div>
        <Dropdown
          selectedValue={itineraryTableFilters.weakness}
          options={WeaknessLabels}
          onChange={value => updateValues(value, 'weakness')}
          optionsPosition="bottom"
        />
      </div>
      <div className="TableFilterMenu-section">
        <div className="TableFilterMenu-section-header-label">
          {t('itinerariesTableFilters.minNumberOfDailyTrips')}
        </div>
        <NumberInput
          value={itineraryTableFilters.minimunNoTotalTrips}
          onChange={value => updateValues(value, 'minimunNoTotalTrips')}
        />
      </div>
      <div className="TableFilterMenu-section">
        <div className="TableFilterMenu-section-header-label">
          {t('itinerariesTableFilters.targetTtr')}
        </div>
        <NumberInput
          value={targetCompetitiveness}
          onChange={value => setTargetCompetitiveness(value)}
        />
      </div>
      <div className="TableFilterMenu-section">
        <div className="TableFilterMenu-section-header-label">
          {t('itinerariesTableFilters.ivttAsOfTotalTransitTime')}
        </div>
        <NumberInput
          value={ivttPercentage}
          onChange={value => setIvttPercentage(value)}
        />
      </div>
      <div className="TableFilterMenu-section">
        <div className="TableFilterMenu-section-header-label">
          {t('itinerariesTableFilters.walkTimeAsOfOvtt')}
        </div>
        <NumberInput
          value={walkTimePercentage}
          onChange={value => setWalkTimePercentage(value)}
        />
      </div>
      <div onClick={closeMenu} className="TableFilterMenu-close-button">
        <Close />
      </div>
    </div>
  );
};

export default ItinerariesTableFilters;
