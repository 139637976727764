import React, { useMemo, useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { useLoadingStore } from '../../store/loaders';
import Loader from '../../components/Loader';
import * as d3 from 'd3';
import useResizeObserver from '@react-hook/resize-observer';
import './ItinerariesWeaknessOverview.scss';
import { useTranslation } from 'react-i18next';
import { globalIternaryData } from '../../data/custom-layers/iternaryData';
import { useTransitItineraryStore } from '../../store/transit-itinerary';
import i18next from 'i18next';

const INPUTS_HEIGHT = 50;
const INITIAL_COMPETITIVENESS_BINS = [
  {
    category: i18next.t('itinerariesTableFilters.itineraryIsCompetitive'),
    itineraries: 0,
    dailyTrips: 0,
  },
  {
    category: i18next.t('itinerariesTableFilters.highIvttAsOfTotalTime'),
    itineraries: 0,
    dailyTrips: 0,
  },
  {
    category: i18next.t('itinerariesTableFilters.highWalkTimeAsOfOVTT'),
    itineraries: 0,
    dailyTrips: 0,
  },
  {
    category: i18next.t('itinerariesTableFilters.lowIVTTAsOfTotalTransitTime'),
    itineraries: 0,
    dailyTrips: 0,
  },
];

const ItinerariesWeaknessOverview = () => {
  const { t } = useTranslation();
  const isLoading = useLoadingStore(
    state => !!state.isLoading.itineraries.length
  );
  const itineraryData = useTransitItineraryStore(state => state.itineraryData);
  const [weaknessButtonSelection, setWeeknessButtonSelection] =
    useState('daily-trips');

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const calculateBarPercentage = (data, total) => {
    const value =
      weaknessButtonSelection === 'daily-trips'
        ? data?.dailyTrips
        : data?.itineraries;
    return Math.floor((value / total) * 100);
  };

  const processData = inputData => {
    const updatedData = inputData.reduce((accumulator, itinerary) => {
      const weakness = itinerary.weakness;
      const avgDailyTrips = itinerary.avg_daily_trips;

      if (accumulator[weakness]) {
        accumulator[weakness].avg_daily_trips += avgDailyTrips;
        accumulator[weakness].itineraries += 1;
      } else {
        accumulator[weakness] = {
          avg_daily_trips: avgDailyTrips,
          itineraries: 1,
        };
      }

      return accumulator;
    }, {});

    return updatedData;
  };

  const drawScatterPlot = useCallback(
    (h, w) => {
      const content = document.getElementById('svg-weakness-overview-chart');
      if (content || !h || !w || !itineraryData) return;
      const processedData = processData(itineraryData);
      // Get the highest trips value in the data for x-axis range
      let maxValue = 0;
      let totalDailyTrips = 0;
      let totalItineraries = 0;
      const chartData = JSON.parse(
        JSON.stringify(INITIAL_COMPETITIVENESS_BINS)
      );
      let totalCount = 0;
      chartData.map(item => {
        if (processedData[item.category]) {
          const dailyTrips = processedData[item.category]?.avg_daily_trips;
          const itineraries = processedData[item.category]?.itineraries;
          totalDailyTrips = totalDailyTrips + dailyTrips;
          totalItineraries = totalItineraries + itineraries;
          item.dailyTrips = dailyTrips;
          item.itineraries = itineraries;
          if (weaknessButtonSelection === 'daily-trips') {
            totalCount = totalDailyTrips;
            if (maxValue < dailyTrips) {
              maxValue = dailyTrips;
            }
          } else if (weaknessButtonSelection === 'total-itineraries') {
            totalCount = totalItineraries;
            if (maxValue < itineraries) {
              maxValue = itineraries;
            }
          }
        }
      });
      // set the dimensions and margins of the graph
      var margin = { top: 10, right: 45, bottom: 40, left: 120 };
      const width = w - margin.left - margin.right;
      const height = h - margin.top - margin.bottom;

      // append the svg object to the body of the page
      var svg = d3
        .select('#weakness-overview-chart')
        .append('svg')
        .attr('id', 'svg-weakness-overview-chart')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      const tooltip = d3
        .select('#weakness-overview-chart')
        .append('div')
        .attr('class', 'chartTooltip')
        .style('position', 'absolute')
        .style('color', 'white')
        .style('border-radius', '5px')
        .style('padding', '5px')
        .style('opacity', '0')
        .style('margin-left', '10px')
        .style('z-index', '2');

      // Define scales
      const xScale = d3.scaleLinear().domain([0, maxValue]).range([0, width]);
      const yScale = d3
        .scaleBand()
        .domain(chartData.map(d => d.category))
        .range([0, height])
        .padding(0.6);

      // Define colors
      let colorScale;
      let stack;
      if (weaknessButtonSelection === 'daily-trips') {
        colorScale = d3
          .scaleOrdinal()
          .domain(['dailyTrips'])
          .range(['#148BCC']);
        // Stack data
        stack = d3
          .stack()
          .keys(['dailyTrips'])
          .order(d3.stackOrderNone)
          .offset(d3.stackOffsetNone);
      } else {
        colorScale = d3
          .scaleOrdinal()
          .domain(['itineraries'])
          .range(['#148BCC']);

        // Stack data
        stack = d3
          .stack()
          .keys(['itineraries'])
          .order(d3.stackOrderNone)
          .offset(d3.stackOffsetNone);
      }

      const stackedData = stack(chartData);

      const tooltipText = (event, value) => {
        tooltip
          .html(
            `
            <div>
              <div>${Math.floor(value)} itineraries</div>
            </div>
          `
          )
          .style('top', event.pageY + 'px')
          .style('left', event.pageX + 'px')
          .style('opacity', '0.9')
          .style('z-index', 2);
      };

      // Draw stacked bars
      const bars = svg
        .selectAll('.bar')
        .data(stackedData)
        .enter()
        .append('g')
        .attr('fill', d => colorScale(d.key))
        .selectAll('rect')
        .data(d => d)
        .enter();

      bars
        .append('rect')
        .attr('x', d => xScale(d[0]))
        .attr('y', (d, i) => yScale(chartData[i].category))
        .attr('height', yScale.bandwidth())
        .attr('width', d => xScale(d[1]) - xScale(d[0]))
        .attr('class', (d, i) => 'group' + i + ' bar')
        .on('mouseover', (event, d) => {
          if (weaknessButtonSelection === 'total-itineraries') {
            tooltipText(event, d?.data?.itineraries ?? 0);
            d3.selectAll('.bar').transition().style('opacity', 0.5);
            const index = parseInt(
              event?.target?.classList?.[0]?.replace(/[^\d.]/g, ''),
              10
            );
            d3.selectAll(`.${event?.target?.classList?.[0]}`)
              .transition()
              .style('opacity', 1);
            d3.selectAll(`.yAxis${index}`).transition().style('opacity', 1);
          }
        })
        .on('mousemove', (event, d) => {
          if (weaknessButtonSelection === 'total-itineraries') {
            tooltip
              .style('top', event.layerY + 'px')
              .style('left', event.layerX + 'px');
          }
        })
        .on('mouseleave', d => {
          tooltip.style('opacity', '0').style('z-index', '-1');
          d3.selectAll('.bar').transition().style('opacity', 1);
        });

      bars
        .append('text')
        .text(d => `${calculateBarPercentage(d?.data, totalCount)}%`)
        .attr('class', 'bar-text')
        .attr('x', d => xScale(d[1]) + 5)
        .attr(
          'y',
          (d, i) => yScale(chartData[i].category) + yScale.bandwidth() * 0.8
        );

      // Add X-axis
      const xAxis = svg
        .append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(xScale).ticks(4).tickFormat(d3.format('.0s')));

      //Remove x-axis line
      xAxis.selectAll('path,line').style('opacity', 0).remove();

      // Add Y-axis
      const yAxis = svg
        .append('g')
        .call(d3.axisLeft(yScale))
        .attr('class', 'y-axis')
        .style('font-weight', '500');

      d3.selectAll('.y-axis .tick').attr(
        'class',
        (d, i) => 'yAxis' + i + ' bar' + ' tick'
      );

      // Adjust Y-axis text to wrap into multiple lines
      const yAxisText = yAxis.selectAll('.tick text'); // Select all text elements in your y-axis

      yAxisText.each(function () {
        const text = d3.select(this);
        const lineHeight = 1.2;
        const words = text.text().split(/\s+/);

        const dy = parseFloat(text.attr('dy'));
        const fontSize = parseFloat(text.style('font-size'));

        let y = 0;
        if (!isNaN(parseFloat(text.attr('y')))) {
          y = parseFloat(text.attr('y'));
        }

        const x = parseFloat(text.attr('x'));

        text.text(null);

        let tspan = text
          .append('tspan')
          .attr('x', x)
          .attr('y', y)
          .attr('dy', dy + 'em');

        let line = [];
        let lineNumber = 0;

        words.forEach(word => {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > 120) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            tspan = text
              .append('tspan')
              .attr('x', x)
              .attr('y', y + ++lineNumber * lineHeight * fontSize)
              .attr('dy', dy + 'em')
              .text(word);
          }
        });
      });

      // Remove Y-axis Line
      yAxis.selectAll('path,line').style('opacity', 0).remove();

      // Add X-axis label
      svg
        .append('text')
        .attr('y', height + 30)
        .attr('x', 0)
        .attr('class', 'axis-text')
        .attr('fill', 'black')
        .text(
          weaknessButtonSelection === 'daily-trips'
            ? t('transitStats.dailyTrips')
            : t('transitStats.totalItineraries')
        );
    },
    [itineraryData, weaknessButtonSelection, weaknessButtonSelection]
  );

  const redraw = useCallback(
    resizeArgs => {
      if (!resizeArgs) return;
      const { contentRect } = resizeArgs;
      let { width: nextWidth, height: nextHeight } = contentRect;
      nextHeight = nextHeight - INPUTS_HEIGHT;
      const { width, height } = dimensions;

      const reloadContent = (h, w) => {
        const content = document.getElementById('svg-weakness-overview-chart');
        if (!content) return;
        content.remove();
        drawScatterPlot(h, w);
      };

      if (nextHeight !== height || nextWidth !== width) {
        reloadContent(nextHeight, nextWidth);
        setDimensions({ width: nextWidth, height: nextHeight });
      }
    },
    [dimensions, drawScatterPlot, weaknessButtonSelection]
  );

  useResizeObserver(
    document?.getElementsByClassName('ItinerariesWeaknessOverview')?.[0],
    _.throttle(redraw, 1500, { trailing: false })
  );

  // On mount
  useEffect(() => {
    const chart = document.getElementById('weakness-overview-chart');
    if (!chart) return;
    // Return early if chart exists for other lifecycle methods to pick up
    const content = document.getElementById('svg-weakness-overview-chart');
    if (!content) {
      const container = document?.getElementsByClassName(
        'ItinerariesWeaknessOverview'
      )?.[0];
      drawScatterPlot(container.offsetHeight, container.offsetWidth);
      setDimensions({
        height: container.offsetHeight - INPUTS_HEIGHT,
        width: container.offsetWidth,
      });
    } else {
      content.remove();
      drawScatterPlot(dimensions.height, dimensions.width);
    }
  }, [drawScatterPlot, itineraryData, dimensions]);

  return (
    <div className="ItinerariesWeaknessOverview">
      {isLoading || !itineraryData ? (
        <Loader />
      ) : (
        <>
          <div className="ItinerariesWeaknessOverview-header menu-primary-label">
            {t('transitStats.itinerariesWeaknessOverview').toUpperCase()}
          </div>
          <div className="ItinerariesWeaknessOverview-buttons-block">
            <button
              className={classnames(
                'ItinerariesWeaknessOverview-buttons-block-button',
                {
                  selected: weaknessButtonSelection === 'daily-trips',
                }
              )}
              onClick={() => {
                setWeeknessButtonSelection('daily-trips');
              }}
            >
              {t('transitStats.dailyTrips')}
            </button>
            <button
              className={classnames('TripsDisplay-buttons-block-button', {
                selected: weaknessButtonSelection === 'total-itineraries',
              })}
              onClick={() => {
                setWeeknessButtonSelection('total-itineraries');
              }}
            >
              {t('transitStats.totalItineraries')}
            </button>
          </div>
          <div className="weakness-overview-chart-container">
            <div id="weakness-overview-chart" />
          </div>
        </>
      )}
    </div>
  );
};

export default ItinerariesWeaknessOverview;
