import { useApiRequestsStore } from './store/api-requests';

const { allowRequest } = useApiRequestsStore.getState();

export const deduplicateRequestHelper = async (key, fn, ...args) => {
  const allow = allowRequest(key, ...args);
  if (allow) {
    fn(...args);
  }
};
