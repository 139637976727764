import React, { useEffect, useState } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Link,
} from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import './PDFReport.scss';
import { useReportStore } from '../../store/reportStore';
import { formatDate, formatDateWithTime } from '../../constants';
import Loader from '../../components/Loader';
import editIcon from '../../images/editIcon.png';
import camsysLogo from '../../images/old_locus_logo.png';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 30,
    fontSize: 12,
    color: '#3F4D55',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  borderTop: {
    borderTop: '1px solid #899AA3',
  },
  alignCenter: {
    alignItems: 'center',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  image: {
    height: 225,
    margin: 'auto',
    marginTop: 0,
  },
  icon: {
    height: 10,
    margin: 0,
    width: 10,
  },
  logo: {
    height: 40,
  },
  text: {
    marginTop: 2,
    marginBottom: 2,
  },
  greyText: {
    color: '#899AA3',
  },
  footer: {
    fontSize: 10,
    fontStyle: 'italic',
  },
  title: {
    fontSize: 15,
  },
  header: {
    fontSize: 18,
    fontWeight: 700,
  },
  marginSM: {
    marginBottom: 5,
  },
  marginL: {
    marginTop: 10,
    paddingTop: 10,
    marginBottom: 10,
  },
  flexStart: {
    justifyContent: 'flex-start',
    gap: 5,
  },
  weightL: {
    fontWeight: 700,
  },
  logoText: {
    letterSpacing: 5,
    fontWeight: 'bold',
    color: 'rgb(137, 154, 163)',
  },
  marginBottomL: {
    marginBottom: 15,
  },
});

export const PDFReport = ({ clientLogo }) => {
  const { t } = useTranslation();
  const tripImageUrl = useReportStore(state => state.tripImageUrl);
  const originLegendUrl = useReportStore(state => state.originLegendUrl);
  const destinationLegendUrl = useReportStore(
    state => state.destinationLegendUrl
  );
  const mapDirection = useReportStore(state => state.mapDirection);
  const [notes, setNotes] = useState('');
  const pdfDetails = useReportStore(state => state.pdfDetails);
  const labelSource = useReportStore(state => state.labelSource);
  const [mapUrls, setMapUrls] = useState({
    origin: '',
    destination: '',
  });

  const readableNum = num => {
    const res = Math.ceil(num);
    return res.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const PDFReport = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.flex, styles.alignEnd]}>
          <View style={[styles.flex, styles.alignCenter, styles.flexStart]}>
            <Image src={camsysLogo} style={styles.logo} />
            <Text style={[styles.header, styles.logoText]}>LOCUS</Text>
          </View>
          <Link
            style={[styles.greyText, styles.text, styles.marginSM]}
            src="https://www.locusdata.io/"
          >
            www.locusdata.io
          </Link>
        </View>
        <View style={[styles.flex, styles.borderTop, styles.marginL]}>
          <View>
            <Text style={[styles.greyText, styles.marginSM]}>
              Created {formatDate(pdfDetails.createdAt)}
            </Text>
            <Text style={[styles.header, styles.marginSM]}>
              {pdfDetails.projectName}
            </Text>
            <Text style={[styles.title, styles.marginSM]}>
              {pdfDetails.viewName}
            </Text>
            <Text style={styles.greyText}>
              {readableNum(pdfDetails.dailyTrip)} daily trips
            </Text>
            <Text style={styles.greyText}>
              {readableNum(pdfDetails.milesTraveled)} person miles traveled
            </Text>
            <Text style={styles.text}>
              Total daily trips on an average {pdfDetails?.weekSelection} for{' '}
              {pdfDetails?.studyPeriod}
            </Text>
          </View>
          {clientLogo && <Image style={styles.logo} src={clientLogo} />}
        </View>
        {mapUrls?.origin?.length > 2 && (
          <View wrap={false}>
            <View style={[styles.borderTop, styles.marginL]}>
              <Text style={styles.weightL}>
                {labelSource.selection?.toUpperCase()} PER{' '}
                {labelSource?.zoneType?.toUpperCase()} BY ORIGIN
              </Text>
            </View>
            <Image style={styles.image} src={mapUrls.origin} />
          </View>
        )}
        {originLegendUrl?.length > 2 && (
          <View wrap={false}>
            <View style={[styles.borderTop, styles.marginL]}>
              <Text style={styles.weightL}>
                {labelSource.selection?.toUpperCase()} BY ORIGIN BETWEEN{' '}
                {labelSource?.zoneType?.toUpperCase()} DISTRIBUTION
              </Text>
            </View>
            <View>
              <Image src={originLegendUrl} />
            </View>
          </View>
        )}
        {mapUrls?.destination?.length > 2 && (
          <View wrap={false}>
            <View style={[styles.borderTop, styles.marginL]}>
              <Text style={styles.weightL}>
                {labelSource.selection?.toUpperCase()} PER{' '}
                {labelSource?.zoneType?.toUpperCase()} BY DESTINATION
              </Text>
            </View>
            <Image style={[styles.image]} src={mapUrls?.destination} />
          </View>
        )}
        {destinationLegendUrl?.length > 2 && (
          <View wrap={false}>
            <View style={[styles.borderTop, styles.marginL]}>
              <Text style={styles.weightL}>
                {labelSource.selection?.toUpperCase()} BY DESTINATION BETWEEN{' '}
                {labelSource?.zoneType?.toUpperCase()} DISTRIBUTION
              </Text>
            </View>
            <View>
              <Image src={destinationLegendUrl} />
            </View>
          </View>
        )}
        {tripImageUrl?.length > 0 && (
          <View wrap={false}>
            <View style={[styles.borderTop, styles.marginL]}>
              <Text style={styles.weightL}>DISTRIBUTION OF TRIPS</Text>
            </View>
            <View wrap={false}>
              <Image key={tripImageUrl[0]} src={tripImageUrl[0]} />
            </View>
          </View>
        )}
        {tripImageUrl?.length > 0 &&
          tripImageUrl?.map(
            (item, ind) =>
              ind > 0 && (
                <View wrap={false} key={ind}>
                  <Image src={item} />
                </View>
              )
          )}
        <View wrap={false} style={[styles.borderTop, styles.marginL]}>
          {notes?.length > 0 && (
            <View>
              <View style={[styles.flex, styles.alignCenter, styles.flexStart]}>
                <Image style={styles.icon} src={editIcon} />
                <Text style={styles.weightL}>NOTES</Text>
              </View>
              <Text
                style={[styles.greyText, styles.text, styles.marginBottomL]}
              >
                {notes?.length > 0 ? notes : 'Add your own notes here...'}
              </Text>
            </View>
          )}
          <View style={styles.footer}>
            <Text>
              All data sourced from LOCUS Flows. Copyright 2023 Cambridge
              Systematics. All rights reserved. Copyright information goes here
              as well as [LOCUS: Include metadata with a contact email or link
              to LOCUS support if users have questions]
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  useEffect(() => {
    let data = {
      origin: '',
      destination: '',
    };
    mapDirection?.map(item => {
      const details = document
        .getElementById(item)
        .getElementsByTagName('canvas');
      const url = details?.[0]?.toDataURL('image/png');
      data = {
        ...data,
        [item]: url,
      };
    });
    setMapUrls(data);
  }, [mapDirection]);

  return (
    <div className="reportContainer">
      <div className="noteBox">
        <label>{t('pdfReport.addNotes')}</label>
        <textarea
          value={notes}
          onChange={ev => setNotes(ev?.target?.value)}
          placeholder={t('pdfReport.addNotesPlaceholder')}
        />
      </div>
      <div className="pdf-btn">
        {mapUrls.origin || mapUrls.destination ? (
          <PDFDownloadLink
            document={PDFReport()}
            fileName={`${pdfDetails.viewName?.replaceAll(
              ' ',
              '_'
            )}_${formatDateWithTime(new Date())}`}
          >
            {t('pdfReport.downloadBtn')}
          </PDFDownloadLink>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};
