import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import Color from 'color';
import * as d3 from 'd3';
import _ from 'lodash';
import classnames from 'classnames';
import { Popover } from 'react-tiny-popover';
import { useLoadingStore } from '../store/loaders';
import Loader from './Loader';
import AscendingDescendingIcon from './AscendingDescendingIcon';
import { ReactComponent as VerticalEllipsis } from '../images/vertical_ellipsis.svg';
import { useTransitItineraryStore } from '../store/transit-itinerary';
import { evaluateExpression } from '../evaluate-expression';
import './TripsDisplay.scss';
import { useTranslation } from 'react-i18next';
import TranistBreakdown from './transit-components/TransitBreakdown';
import { loadItineraryData } from '../connectors';
import ODPairsTableFilter from './transit-components/ODPairsTableFilter';
import ItinerariesTableFilters from './transit-components/ItinerariesTableFilters';
import { calculateWeakness, computeCompetitiveness } from '../constants';

function TripsDisplay({
  data,
  activeDashboardInstance,
  accessToken,
  isTripsDataExpandedFully,
}) {
  const setHoveredItinerary = useTransitItineraryStore(
    state => state.setHovered
  );
  const [openFiltersMenu, setOpenFiltersMenu] = useState(false);
  const [openItineraryFiltersMenu, setOpenItineraryFiltersMenu] =
    useState(false);
  const hoveredItinerary = useTransitItineraryStore(state => state.hovered);
  const selectedODPair = useTransitItineraryStore(
    state => state.selectedODPair
  );
  const setItineraryData = useTransitItineraryStore(
    state => state.setItineraryData
  );
  const [initialItineraryData, setInitialItineraryData] = useState(null);
  const itineraryData = useTransitItineraryStore(state => state.itineraryData);
  const setSelectedODPair = useTransitItineraryStore(
    state => state.setSelectedODPair
  );
  const ivttPercentage = useTransitItineraryStore(
    state => state.ivttPercentage
  );
  const walkTimePercentage = useTransitItineraryStore(
    state => state.walkTimePercentage
  );
  const itineraryTableFilters = useTransitItineraryStore(
    state => state.itineraryTableFilters
  );
  const odPairsData = useTransitItineraryStore(state => state.odPairsData);
  const setODPairsData = useTransitItineraryStore(
    state => state.setODPairsData
  );
  const odPairTableFilters = useTransitItineraryStore(
    state => state.odPairTableFilters
  );
  const targetCompetitiveness = useTransitItineraryStore(
    state => state.targetCompetitiveness
  );
  const targetTransitShare = useTransitItineraryStore(
    state => state.targetTransitShare
  );
  const { t } = useTranslation();
  const [locallyHovered, setLocallyHovered] = useState(null);
  const [tripsData, setTripsData] = useState([]);
  const [transitButtonSelection, setTransitButtonSelection] =
    useState('overview');

  const isLoading = useLoadingStore(
    state => !!state.isLoading.tripsPanel.length
  );

  const isItinerariesLoading = useLoadingStore(
    state => !!state.isLoading.itineraries.length
  );

  const [columns, setColumns] = useState([
    {
      label: 'OD pair name',
      key: 'itineraryLabel',
      active: null,
    },
    { label: 'Total trips', key: 'total_trips', active: null, sort: 'numeric' },
    {
      label: 'Transit trips',
      key: 'total_transit_trips',
      active: null,
    },
    {
      label: 'Transit share',
      key: 'transitShare',
      active: null,
      isPercent: true,
    },
    {
      label: 'Competitiveness',
      key: 'travel_time_ratio',
      active: null,
    },
  ]);

  const [selectedODColumns, setSelectedODColumns] = useState([
    {
      label: 'Itinerary name',
      key: 'itinerary',
      active: null,
    },
    {
      label: 'Weakness',
      key: 'weakness',
      active: null,
    },
    {
      label: 'Avg. Daily trips',
      key: 'avg_daily_trips',
      active: null,
      sort: 'numeric',
    },
    {
      label: 'Avg. Transit Time',
      key: 'avg_transit_time',
      active: null,
      sort: 'numeric',
    },
    {
      label: 'Competitiveness',
      key: 'avg_travel_time_ratio',
      active: null,
    },
    {
      label: 'Avg. Trip Distance',
      key: 'avg_trip_distance',
      active: null,
      sort: 'numeric',
    },
  ]);

  const updateColumns = useCallback(
    (columnKey, value) => {
      const nextColumns = columns.map(c => {
        if (c.key !== columnKey) {
          return { ...c, active: null };
        }
        return { ...c, active: value };
      });
      setColumns(nextColumns);
    },
    [columns]
  );

  useEffect(() => {
    if (!data) return;
    let tempData = JSON.parse(JSON.stringify(data));
    tempData.forEach(item => {
      item.competitiveness = computeCompetitiveness(
        Number(item?.transit_trips) / Number(item?.total_trips),
        item?.avg_travel_time_ratio,
        targetTransitShare,
        targetCompetitiveness
      );
    });
    if (odPairTableFilters.minimunNoTotalTrips > 0) {
      tempData = tempData.filter(
        item => item?.total_trips >= odPairTableFilters.minimunNoTotalTrips
      );
    }

    if (odPairTableFilters.minimunNoTransitTrips > 0) {
      tempData = tempData.filter(
        item => item?.transit_trips >= odPairTableFilters.minimunNoTransitTrips
      );
    }

    if (odPairTableFilters.competitiveness !== 'All') {
      tempData = tempData.filter(
        item => item?.competitiveness === odPairTableFilters.competitiveness
      );
    }

    setODPairsData(tempData);
  }, [data, odPairTableFilters, targetCompetitiveness, targetTransitShare]);

  const sortData = useCallback(
    (key, sortDirection) => {
      if (!odPairsData) return [];

      let reverse = false;

      if (sortDirection) {
        reverse = sortDirection === 'descending';
      }

      let nextData = odPairsData.map(item => {
        const {
          origin_geomarket,
          destination_geomarket,
          transit_trips,
          total_trips,
          avg_travel_time_ratio,
          avg_trip_distance,
        } = item;
        return {
          origin_geomarket,
          destination_geomarket,
          itineraryLabel: `${origin_geomarket} >> ${destination_geomarket}`,
          transitShare: Number(transit_trips) / Number(total_trips),
          total_trips: Math.round(Number(total_trips)),
          total_transit_trips: Math.round(Number(transit_trips)),
          travel_time_ratio: Number(avg_travel_time_ratio.toFixed(2)),
          avg_trip_distance: Number(avg_trip_distance.toFixed(2)),
        };
      });

      let sortKeys = ['itineraryLabel'];

      if (key) {
        sortKeys = [key, ...sortKeys];
      }

      nextData = _.sortBy(nextData, sortKeys);

      if (reverse) {
        nextData = nextData.reverse();
      }
      return nextData;
    },
    [odPairsData]
  );

  const iternaryScaler = useMemo(() => {
    if (!itineraryData || !itineraryData.length) return null;
    return Object.entries(itineraryData[0]).reduce((acc, [k, v]) => {
      if (isNaN(Number(v))) return acc;

      const scale = d3
        .scaleLinear()
        .domain([
          d3.min(itineraryData.map(d => d[k])),
          d3.max(itineraryData.map(d => d[k])),
        ])
        .range([0, 1]);

      const fn = num => {
        const expression = [
          'interpolate',
          ['linear'],
          ['get', 'color-scale'],
          0,
          '#D4E6F3',
          1,
          '#255277',
        ];
        const value = scale(num);

        const args = {
          layerType: 'fill',
          propertyType: 'paint',
          propertyId: 'fill-color',
          properties: { 'color-scale': value },
          value: expression,
        };

        const backgroundColor = evaluateExpression(args);

        const fontColor = Color(backgroundColor).isDark() ? 'white' : '#3f4d55';

        return { backgroundColor: backgroundColor, color: fontColor };
      };

      acc[k] = fn;
      return acc;
    }, {});
  }, [itineraryData]);

  const scaler = useMemo(() => {
    if (!tripsData || !tripsData.length) return null;
    return Object.entries(tripsData[0]).reduce((acc, [k, v]) => {
      if (isNaN(Number(v))) return acc;

      const scale = d3
        .scaleLinear()
        .domain([
          d3.min(tripsData.map(d => d[k])),
          d3.max(tripsData.map(d => d[k])),
        ])
        .range([0, 1]);

      const fn = num => {
        const expression = [
          'interpolate',
          ['linear'],
          ['get', 'color-scale'],
          0,
          '#D4E6F3',
          1,
          '#255277',
        ];
        const value = scale(num);

        const args = {
          layerType: 'fill',
          propertyType: 'paint',
          propertyId: 'fill-color',
          properties: { 'color-scale': value },
          value: expression,
        };

        const backgroundColor = evaluateExpression(args);

        const fontColor = Color(backgroundColor).isDark() ? 'white' : '#3f4d55';

        return { backgroundColor: backgroundColor, color: fontColor };
      };

      acc[k] = fn;
      return acc;
    }, {});
  }, [tripsData]);

  useEffect(() => {
    const activeColumn = columns.find(c => !!c.active) ?? {};
    const { key, active: sortDirection } = activeColumn;
    const nextData = sortData(key, sortDirection);
    setTripsData(nextData);
  }, [columns, sortData]);

  const classify = useCallback(id => {
    if (!id || typeof id !== 'string') return id;
    return id.replaceAll(' ', '').toLowerCase();
  }, []);

  useEffect(() => {
    if (locallyHovered) return;
    const { origin_geomarket, destination_geomarket } = hoveredItinerary;
    let el;
    if (origin_geomarket && destination_geomarket) {
      el = document.getElementById(
        `list-item-${classify(origin_geomarket)}-${classify(
          destination_geomarket
        )}`
      );
    } else if (!destination_geomarket) {
      el = document.getElementsByClassName(
        `origin-${classify(origin_geomarket)}`
      )[0];
    } else if (!origin_geomarket) {
      el = document.getElementsByClassName(
        `destination-${classify(destination_geomarket)}`
      )[0];
    }

    if (el) {
      el.scrollIntoView({ block: 'center' });
    }
  }, [hoveredItinerary, locallyHovered, classify]);

  const isHovered = useCallback(
    item => {
      if (Object.values(hoveredItinerary).every(v => !v)) return false;

      if (Object.values(hoveredItinerary).every(v => !!v)) {
        return (
          item?.origin_geomarket === hoveredItinerary?.origin_geomarket &&
          item?.destination_geomarket ===
            hoveredItinerary?.destination_geomarket
        );
      }

      return (
        item?.origin_geomarket === hoveredItinerary?.origin_geomarket ||
        item?.destination_geomarket === hoveredItinerary?.destination_geomarket
      );
    },
    [hoveredItinerary]
  );

  // Similar to getHumanReadableNumber but without words
  // for millions, billions, etc
  const commify = num => {
    if (isNaN(num)) return num;
    if (num < 100) {
      return num;
    }
    const rounded = `${Math.round(Number(num))}`;
    let split = rounded.split('');
    split = split.reverse().reduce((acc, d, i) => {
      if (i % 3 === 0) {
        acc.push([d]);
      } else {
        acc[acc.length - 1].push(d);
      }
      return acc;
    }, []);
    split = split.reverse().map(v => v.reverse().join(''));
    let str;

    str = split.join(',');

    return str;
  };

  const resetScroll = () => {
    const element = document.getElementsByClassName('TripsDisplay-list');
    element[0].scrollTop = 0;
  };

  useEffect(() => {
    if (
      !initialItineraryData ||
      !ivttPercentage ||
      !walkTimePercentage ||
      !targetCompetitiveness ||
      !selectedODPair
    )
      return;
    let tempData = JSON.parse(JSON.stringify(initialItineraryData));
    tempData.forEach(data => {
      const avgTravelTimeRatio = Number(data?.avg_travel_time_ratio.toFixed(2));
      const dataIvttPercentage = Number(Math.round(data?.ivtt * 100));
      const dataOvttPercentage = Number(
        Math.round(data?.walk_time_of_ovtt * 100)
      );
      data.avg_trip_distance = selectedODPair?.avg_trip_distance;
      data.avg_daily_trips = Number(data?.avg_daily_trips.toFixed(2));
      data.avg_transit_time = Number(data?.avg_transit_time.toFixed(2));
      data.avg_travel_time_ratio = avgTravelTimeRatio;
      data.access = Number(Math.round(data?.access * 100));
      data.initial_wait = Number(Math.round(data?.initial_wait * 100));
      data.ivtt = dataIvttPercentage;
      data.transfer_wait = Number(Math.round(data?.transfer_wait * 100));
      data.transfer_walk = Number(Math.round(data?.transfer_walk * 100));
      data.egress = Number(Math.round(data?.egress * 100));
      data.total_time = `${Math.round(
        data?.avg_transit_time * data?.avg_daily_trips
      )} mins`;
      data.weakness = calculateWeakness(
        avgTravelTimeRatio,
        targetCompetitiveness,
        dataIvttPercentage,
        ivttPercentage,
        dataOvttPercentage,
        walkTimePercentage
      );
    });
    if (itineraryTableFilters?.weakness !== 'All') {
      tempData = tempData.filter(
        item => item?.weakness === itineraryTableFilters?.weakness
      );
    }

    if (itineraryTableFilters?.minimunNoTotalTrips > 0) {
      tempData = tempData.filter(
        item =>
          item?.avg_daily_trips > itineraryTableFilters?.minimunNoTotalTrips
      );
    }

    setItineraryData(tempData);
  }, [
    ivttPercentage,
    walkTimePercentage,
    targetCompetitiveness,
    initialItineraryData,
    selectedODPair,
    itineraryTableFilters,
  ]);

  const getSelectedODPairItineraryData = async item => {
    setSelectedODPair(item);
    const result = await loadItineraryData(
      activeDashboardInstance,
      item?.origin_geomarket,
      item?.destination_geomarket,
      accessToken,
      'itineraries'
    );
    setInitialItineraryData(result);
  };

  return (
    <div className="TripsDisplay">
      {isLoading || !odPairsData ? (
        <Loader />
      ) : (
        <>
          {selectedODPair ? (
            <div className="TripsDisplay-iternary-header">
              <div className="TripsDisplay-iternary">
                <div
                  className="TripsDisplay-iternary-odPairs"
                  onClick={() => {
                    setSelectedODPair('');
                    setTransitButtonSelection('overview');
                    resetScroll();
                  }}
                >
                  {t('transitStats.allODPairs')}
                </div>
                <div className="TripsDisplay-iternary-slash">/</div>
                <div className="TripsDisplay-iternary-selected-header">
                  {selectedODPair.itineraryLabel}
                </div>
              </div>
              <div className="TripsDisplay-buttons-block">
                <div>
                  <Popover
                    isOpen={openItineraryFiltersMenu}
                    content={
                      <ItinerariesTableFilters
                        closeMenu={() =>
                          setOpenItineraryFiltersMenu(openMenu => !openMenu)
                        }
                      />
                    }
                    positions={isTripsDataExpandedFully ? ['bottom'] : ['top']}
                    reposition={true}
                    align="end"
                  >
                    <VerticalEllipsis
                      className="TripsDisplay-buttons-block-ellipsis"
                      onClick={() => {
                        setOpenItineraryFiltersMenu(openMenu => !openMenu);
                      }}
                    />
                  </Popover>
                </div>
                <button
                  className={classnames('TripsDisplay-buttons-block-button', {
                    selected: transitButtonSelection === 'overview',
                  })}
                  onClick={() => {
                    setTransitButtonSelection('overview');
                    resetScroll();
                  }}
                >
                  {t('transitStats.overview')}
                </button>
                <button
                  className={classnames('TripsDisplay-buttons-block-button', {
                    selected: transitButtonSelection === 'transit-breakdown',
                  })}
                  onClick={() => {
                    setTransitButtonSelection('transit-breakdown');
                    resetScroll();
                  }}
                >
                  {t('transitStats.tranistBreakdown')}
                </button>
              </div>
            </div>
          ) : (
            <div
              className="TripsDisplay-header"
              onClick={() => {
                setSelectedODPair('');
                resetScroll();
                setItineraryData(null);
              }}
            >
              {t('transitStats.allOriginDestinationPairs')}
              <div>
                <Popover
                  isOpen={openFiltersMenu}
                  content={
                    <ODPairsTableFilter
                      closeMenu={() =>
                        setOpenFiltersMenu(openMenu => !openMenu)
                      }
                    />
                  }
                  positions={isTripsDataExpandedFully ? ['bottom'] : ['top']}
                  reposition={true}
                  align="end"
                >
                  <VerticalEllipsis
                    className="TripsDisplay-buttons-block-ellipsis"
                    onClick={() => {
                      setOpenFiltersMenu(openMenu => !openMenu);
                    }}
                  />
                </Popover>
              </div>
            </div>
          )}

          <div
            className="TripsDisplay-list"
            onMouseEnter={() => {
              setLocallyHovered(true);
            }}
            onMouseLeave={() => {
              setLocallyHovered(false);
              if (!selectedODPair) {
                setHoveredItinerary({
                  origin_geomarket: null,
                  destination_geomarket: null,
                });
              }
            }}
          >
            {selectedODPair ? (
              <>
                {isItinerariesLoading ? (
                  <>
                    <Loader />
                  </>
                ) : transitButtonSelection === 'overview' ? (
                  selectedODColumns.map((column, i) => (
                    <div key={i} className="TripsDisplay-list-column">
                      <div className="TripsDisplay-list-column-header">
                        {column.label}
                        <AscendingDescendingIcon
                          active={column?.active}
                          setActive={v => updateColumns(column.key, v)}
                        />
                      </div>
                      <div className="TripsDisplay-list-container">
                        {itineraryData?.map((item, i) => (
                          <div
                            key={i}
                            style={
                              iternaryScaler?.[column.key]
                                ? {
                                    ...iternaryScaler[column.key](
                                      item[column.key]
                                    ),
                                  }
                                : {}
                            }
                            id={`list-item-${classify(
                              item?.origin_geomarket
                            )}-${classify(item?.destination_geomarket)}`}
                            className={classnames(
                              `TripsDisplay-list-item origin-${classify(
                                item?.origin_geomarket
                              )} destination-${classify(
                                item?.destination_geomarket
                              )}`
                            )}
                            title={item[column.key]}
                          >
                            {column?.isPercent
                              ? `${(item[column.key] * 100).toFixed(2)}%`
                              : commify(item[column.key])}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <TranistBreakdown tranistBreakdownData={itineraryData} />
                )}
              </>
            ) : (
              columns.map((column, i) => (
                <div key={i} className="TripsDisplay-list-column">
                  <div className="TripsDisplay-list-column-header">
                    {column.label}
                    <AscendingDescendingIcon
                      active={column?.active}
                      setActive={v => updateColumns(column.key, v)}
                    />
                  </div>
                  <div className="TripsDisplay-list-container">
                    {tripsData.map((item, i) => (
                      <div
                        key={i}
                        style={
                          scaler?.[column.key]
                            ? {
                                ...scaler[column.key](item[column.key]),
                              }
                            : {}
                        }
                        onClick={() => {
                          getSelectedODPairItineraryData(item);
                          resetScroll();
                          setHoveredItinerary({
                            origin_geomarket: item?.origin_geomarket,
                            destination_geomarket: item?.destination_geomarket,
                          });
                        }}
                        id={`list-item-${classify(
                          item?.origin_geomarket
                        )}-${classify(item?.destination_geomarket)}`}
                        className={classnames(
                          `TripsDisplay-list-item origin-${classify(
                            item?.origin_geomarket
                          )} destination-${classify(
                            item?.destination_geomarket
                          )}`,
                          {
                            hovered: isHovered(item),
                            unhovered:
                              Object.values(hoveredItinerary).some(v => !!v) &&
                              !isHovered(item),
                          }
                        )}
                        onMouseEnter={() => {
                          setHoveredItinerary({
                            origin_geomarket: item?.origin_geomarket,
                            destination_geomarket: item?.destination_geomarket,
                          });
                        }}
                        title={item[column.key]}
                      >
                        {column?.isPercent
                          ? `${(item[column.key] * 100).toFixed(2)}%`
                          : commify(item[column.key])}
                      </div>
                    ))}
                  </div>
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default TripsDisplay;
