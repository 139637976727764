import { create } from 'zustand';

export const useLoadingStore = create(set => ({
  isLoading: {
    map: [],
    filters: [],
    histograms: [],
    highlightedHistograms: [],
    downloadMenu: [],
    totals: [],
    tripsPanel: [],
    stats: [],
    itineraries: [],
  },
  setLoadingState: (section, id) =>
    set(state => {
      const currentState = state.isLoading?.[section];
      const nextLoading = currentState.includes(id)
        ? currentState.filter(v => v !== id)
        : currentState.concat([id]);
      return { isLoading: { ...state.isLoading, [section]: nextLoading } };
    }),
}));
