import React, { useState, useMemo, useCallback, useRef } from 'react';
import * as d3 from 'd3';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import hat from 'hat';
import './ChartsDownloadMenu.scss';
import { FILTERS_LABELS, getCurrentDate } from '../../constants';
import { saveAs } from 'file-saver';
import ButtonSet from '../ButtonSet';
import RadioButtonSet from '../RadioButtonSet';
import JSZip from 'jszip';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import _ from 'lodash';
import { useGraphTabsStore } from '../../store/graphs-tab';
import { useLoadingStore } from '../../store/loaders';

const sleep = delay => new Promise(resolve => setTimeout(resolve, delay));

function ChartsDownloadMenu({
  data,
  highlightedData,
  viewName,
  dataSegmentation,
  filterValues,
}) {
  const { t } = useTranslation();
  const loader = useRef();
  const [chartsToDownload, setChartsToDownload] = useState([]);
  const [downloadFormat, setDownloadFormat] = useState(null);

  const displayDataType = useGraphTabsStore(state => state.tab);
  const setDisplayDataType = useGraphTabsStore(state => state.setTab);
  const setLoadingState = useLoadingStore(state => state.setLoadingState);

  const getFilterLabel = property => {
    let label = FILTERS_LABELS.find(v => property === v.value)?.label;
    if (!label) {
      label = property.replaceAll('_', ' ');
      label = label.toUpperCase();
    }
    return label;
  };

  const chartDownloadOptions = useMemo(() => {
    let options = [];
    if (!data) return options;
    return options
      .concat(
        Object.keys(data).map(d => ({
          label: getFilterLabel(d),
          value: d,
        }))
      )
      .filter(
        v => !FILTERS_LABELS.find(l => v.value === l.value)?.removeHistogram
      );
  }, [data]);

  const downloadFormatOptions = [
    { label: t('general.csv'), value: 'csv' },
    { label: t('general.excel'), value: 'excel' },
    { label: t('general.pngImage'), value: 'png' },
  ];

  const downloadPng = useCallback(
    async (name, subset) => {
      let currentTab;
      if (subset !== displayDataType) {
        currentTab = displayDataType;
        setDisplayDataType(subset);
        await sleep(150);
      }
      const zip = new JSZip();
      const ignoreElements = el => {
        if (el.className && typeof el.className === 'string') {
          return el.className.includes('Histogram-download-button');
        }
        return false;
      };

      for (const chart of chartsToDownload) {
        const el = document.getElementById(`Histogram-${chart}-${subset}`);
        if (!el) continue;

        el.classList.add('Histogram-screenshot-padding');

        const label = `${chart}`;

        const pngName = label.replaceAll(' ', '_').toLowerCase();

        const canvas = await html2canvas(el, { ignoreElements });

        await new Promise(resolve =>
          canvas.toBlob(blob => {
            zip.file(`${pngName}.png`, blob, { base64: true });
            resolve();
          })
        );

        el.classList.remove('Histogram-screenshot-padding');
      }

      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, `${name}.zip`);
      });

      if (currentTab) {
        setDisplayDataType(currentTab);
        await sleep(150);
      }
      setLoadingState('downloadMenu', loader.current);
    },
    [
      chartsToDownload,
      displayDataType,
      setDisplayDataType,
      setLoadingState,
      loader.current,
    ]
  );

  const sort = useCallback(
    (key, results) => {
      const getSortByFn = key => {
        const sequence = filterValues[key];
        const sortByFn = item => {
          const index = sequence.findIndex(v => v === item[key]);
          return index;
        };
        return sortByFn;
      };
      const formatted = dataSegmentation
        ? _.sortBy(results, [getSortByFn(key), getSortByFn(dataSegmentation)])
        : _.sortBy(results, [getSortByFn(key)]);
      return formatted;
    },
    [dataSegmentation, filterValues]
  );

  const downloadExcel = useCallback(
    async (data, name) => {
      const workbook = XLSX.utils.book_new();

      for (const [k, v] of Object.entries(data)) {
        let columns = [k, 'daily_trips'];

        let formatted = v.map(val => {
          let next = {
            [k]: val.value,
            daily_trips: val.count,
          };
          if (dataSegmentation && val.segmentation) {
            next[dataSegmentation] = val.segmentation;
          }
          return next;
        });

        if (dataSegmentation) {
          columns.splice(1, 0, dataSegmentation);
        }

        formatted = sort(k, formatted);

        await new Promise(resolve => {
          const worksheet = XLSX.utils.json_to_sheet(formatted, {
            header: columns,
          });
          XLSX.utils.book_append_sheet(workbook, worksheet, k);
          resolve();
        });
      }

      XLSX.writeFile(workbook, `${name}.xlsx`, { compression: true });
      setLoadingState('downloadMenu', loader.current);
    },
    [loader.current, setLoadingState]
  );

  const downloadCsv = useCallback(
    async (data, name) => {
      const zip = new JSZip();

      for (const [k, v] of Object.entries(data)) {
        let columns = [k, 'daily_trips'];

        let formatted = v.map(val => {
          let next = {
            [k]: val.value,
            daily_trips: val.count,
          };
          if (dataSegmentation && val.segmentation) {
            next[dataSegmentation] = val.segmentation;
          }
          return next;
        });

        if (dataSegmentation) {
          columns.splice(1, 0, dataSegmentation);
        }

        formatted = sort(k, formatted);

        const csv = await d3.csvFormat(formatted, columns);

        zip.file(`${k}.csv`, csv);
      }

      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, `${name}.zip`);
      });
      setLoadingState('downloadMenu', loader.current);
    },
    [setLoadingState, loader.current]
  );

  const downloadCharts = useCallback(async () => {
    loader.current = hat();
    setLoadingState('downloadMenu', loader.current);
    const executeDownload = async (d, name, subset) => {
      const downloadData = chartsToDownload.reduce((acc, property) => {
        if (d[property]) {
          acc[property] = d[property];
        }
        return acc;
      }, {});

      switch (downloadFormat) {
        case 'csv':
          downloadCsv(downloadData, name);
          break;
        case 'excel':
          downloadExcel(downloadData, name);
          break;
        case 'png':
          // Subset specifically lets us download charts for selected area
          await downloadPng(name, subset);
          break;
        default:
          return null;
      }
    };

    const regionalName = `${viewName
      .toLowerCase()
      .replaceAll(' ', '_')}-regional-${getCurrentDate()}`;

    await executeDownload(data, regionalName, 'study_area');

    if (highlightedData) {
      const selectedName = `${viewName
        .toLowerCase()
        .replaceAll(' ', '_')}-selected-${getCurrentDate()}`;

      await executeDownload(highlightedData, selectedName, 'selections');
    }
  }, [
    downloadFormat,
    data,
    highlightedData,
    chartsToDownload,
    downloadCsv,
    downloadExcel,
    downloadPng,
    viewName,
  ]);

  return (
    <>
      <div className="DownloadMenu-section">
        <div className="DownloadMenu-row-container">
          <div className="DownloadMenu-buttonset-container">
            <div className="menu-secondary-label">
              {t('downloadMenu.selectCharts')}
            </div>
            {chartDownloadOptions?.length > 1 && (
              <ButtonSet
                dark={true}
                selected={chartsToDownload}
                options={chartDownloadOptions}
                onToggle={v =>
                  setChartsToDownload(arr =>
                    arr.includes(v)
                      ? arr.filter(val => val !== v)
                      : arr.concat([v])
                  )
                }
              />
            )}
          </div>
        </div>
        <div className="DownloadMenu-row-container">
          <RadioButtonSet
            selected={downloadFormat ? [downloadFormat] : null}
            options={downloadFormatOptions}
            onToggle={setDownloadFormat}
          />
          <div
            className={classnames('DownloadMenu-button', {
              disabled: !downloadFormat || !chartsToDownload.length,
            })}
            onClick={downloadCharts}
          >
            {t('downloadMenu.download')}
          </div>
        </div>
      </div>
    </>
  );
}

export default ChartsDownloadMenu;
