import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import './SaveNewView.scss';

function SaveNewView({ closeCustomModal, saveNewView }) {
  const { t } = useTranslation();
  const [values, setValues] = useState({ name: '' });
  const [formValidation, setFormValidation] = useState(false);

  const onChange = (key, value) => {
    const tempValues = { ...values };
    tempValues[key] = value;
    setValues(tempValues);
  };

  const onCreate = () => {
    if (
      values?.name?.trim()?.length > 0 &&
      (!/\s/g.test(values?.description) ||
        values?.description?.trim()?.length > 0)
    ) {
      saveNewView(values);
      setFormValidation(false);
    } else {
      setFormValidation(true);
    }
  };

  return (
    <div id="myModal" className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-heading">{t('saveNewView.createNewView')}</div>
          <div className="close" onClick={closeCustomModal}>
            &times;
          </div>
        </div>
        <hr />
        <div
          className={classnames('modal-body', {
            formValidation: formValidation && values.name?.trim()?.length === 0,
          })}
        >
          <input
            className="modal-input"
            placeholder={t('saveNewView.newViewName')}
            onChange={event => onChange('name', event.target.value)}
          />
          <input
            className={classnames({
              emptySpaces:
                /\s/g.test(values?.description) &&
                values?.description?.trim()?.length === 0,
            })}
            placeholder={t('saveNewView.description')}
            onChange={event => onChange('description', event.target.value)}
          />
        </div>
        <hr />
        <div className="modal-footer">
          <button onClick={closeCustomModal} className="cancel-button">
            {t('general.cancel')}
          </button>
          <button className="create-button" onClick={onCreate}>
            {t('general.create')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SaveNewView;
