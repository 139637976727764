import { create } from 'zustand';

export const useCustomLayersStore = create((set, get) => ({
  active: [],

  add: layer => {
    const currentIds = get().active.map(({ id }) => id);
    if (currentIds.includes(layer.id)) return;

    const newActive = [...get().active, layer];
    set({ active: newActive });
  },

  remove: layerId =>
    set({
      active: [...get().active.filter(({ id }) => id !== layerId)],
    }),

  setField: (layerId, name, value) => {
    set({
      active: [
        ...get().active.map(layer => {
          if (layer.id === layerId) {
            return {
              ...layer,
              [name]: value,
            };
          }
          return layer;
        }),
      ],
    });
  },

  setStyle: (layerId, paint) => get().setField(layerId, 'paint', paint),

  setVisibility: (layerId, visibility) =>
    get().setField(layerId, 'visibility', visibility),
}));
