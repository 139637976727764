import {
  BLOCKGROUPS_API_URL,
  CENSUSTRACTS_API_URL,
  COUNTIES_API_URL,
} from '../constants';

export const queryApi = async geoids => {
  const response = await fetch(BLOCKGROUPS_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      blockGroupGeoIds: geoids,
    }),
  });

  return (await response.json()).data;
};

export const getAreaApi = async geoids => {
  const response = await fetch(`${BLOCKGROUPS_API_URL}/area`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      blockGroupGeoIds: geoids,
    }),
  });

  return (await response.json()).data;
};

export const loadCensustractShapesFromApi = async geoids => {
  const response = await fetch(`${CENSUSTRACTS_API_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      censusTractGeoIds: geoids,
    }),
  });

  return (await response.json()).data;
};

export const loadCountyShapesFromApi = async geoids => {
  const response = await fetch(`${COUNTIES_API_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      countyGeoIds: geoids,
    }),
  });

  return (await response.json()).data;
};

export const getAreaForCensusTractsApi = async geoids => {
  const response = await fetch(`${CENSUSTRACTS_API_URL}/area`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      censusTractGeoIds: geoids,
    }),
  });

  return (await response.json()).data;
};

export const getAreaForCountiesApi = async geoids => {
  const response = await fetch(`${COUNTIES_API_URL}/area`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      countyGeoIds: geoids,
    }),
  });

  return (await response.json()).data;
};
