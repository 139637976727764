export const DEFAULT_PALETTE = {
  name: 'Default',
  palette: ['#E5EAA5', '#a8ddb5', '#7bccc4', '#4eb3d3', '#2b8cbe'],
};

export const ORANGE_PALETTE = {
  name: 'Yellow-Orange',
  palette: ['#F3E181', '#fec44f', '#EDAA60', '#E38D7A', '#D27B86'],
};

export const GREEN_PALETTE = {
  name: 'Green-Purple',
  palette: ['#98D1C3', '#70BAD1', '#599AD7', '#5984D7', '#7B79CE'],
};

export const BLUE_PALETTE = {
  name: 'Blue',
  palette: ['#D0E5EC', '#BADBE5', '#9DCEDD', '#75BCD2', '#35B0D7'],
};

export const DIVERGING_PALETTE = {
  name: 'Orange-Blue Diverging',
  palette: ['#EDAA60', '#F5C664', '#F4F3F3', '#BADBE5', '#75BCD2'],
};

export const PALETTES = [
  DEFAULT_PALETTE,
  ORANGE_PALETTE,
  GREEN_PALETTE,
  BLUE_PALETTE,
  DIVERGING_PALETTE,
];

export const DEFAULT_BIN_SIZE = 5;

export const getPaletteBins = (num, palette) => {
  switch (num) {
    case 5:
      return palette;
    case 4:
      return [palette[0], palette[1], palette[3], palette[4]];
    case 3:
      return [palette[0], palette[2], palette[4]];
    case 2:
      return [palette[0], palette[4]];
    case 1:
      return [palette[0]];
    default:
      return palette;
  }
};
