import { create } from 'zustand';

export const useReportStore = create(set => ({
  pdfReport: {
    pdfDestination: 'origin',
    mapDestination: 'origin',
  },
  chartOptions: [],
  pdfDetails: {
    createdAt: '',
    viewName: '',
    projectName: '',
    dailyTrip: 0,
    milesTraveled: 0,
    weekSelection: '',
    studyPeriod: '',
  },
  tripImageUrl: [],
  mapSource: null,
  originLegendUrl: '',
  destinationLegendUrl: '',
  mapDirection: [],
  labelSource: {
    selection: 'DAILY TRIPS',
    zoneType: 'BLOCK GROUPS',
  },
  setLabelSource: labelSource =>
    set({
      labelSource: labelSource,
    }),
  setMapDirection: mapDirection => set({ mapDirection }),
  setChartOptions: options =>
    set({
      chartOptions: options,
    }),
  setPdfReport: pdfReport =>
    set({
      pdfReport: {
        ...pdfReport,
      },
    }),
  setPdfDetails: pdfDetails =>
    set({
      pdfDetails: {
        ...pdfDetails,
      },
    }),
  setTripImageUrl: tripImageUrl =>
    set({
      tripImageUrl: tripImageUrl,
    }),
  setOriginLegendUrl: originLegendUrl => set({ originLegendUrl }),
  setDestinationLegendUrl: destinationLegendUrl =>
    set({ destinationLegendUrl }),
  setMapSource: map =>
    set({
      mapSource: map,
    }),
}));
