import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TransitDashboard from './TransitDashboard';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <>
      {/* <App
        // dashboardId={'mock-transit-dashboard'}
        clientName={'dev_DISCOVER'}
        dashboardId={'f6fcc02e-d648-4611-a3e8-7dc99991570e'}
        dashboardName={'Indiana 22-2'}
        viewName={'New dashboard queries - default view'}
        userId={'fd9e55e7-d336-4623-af13-8d1a734ecbdf'}
        viewId={'68424ccc-c75a-45db-8a44-0d4bc476e238'}
        ableToSaveNewView={true} // Enable or disable save as button based on this prop
        ableToUpdateView={true} // Enable or disable Save button based on this prop
        shareView={() => false} // Trigger this function on click on shareView button
        // Token pulled from local storage on https://locus-dev.zessta.com/home for generic user `mkhan@camsys.com` with password `Locus@123`
        // I'm mostly sure this is a temporary token, so not sure why this is how Zessta suggested I get a token
        accessToken={
          'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJIdi1fSHFBV0hfaXhlNnJLbTNXVTBaV2hsNTVXOWdyRTFyXzVWUndDTHhzIn0.eyJleHAiOjE3MDkwNDQ5MTEsImlhdCI6MTcwOTAxNjExMSwiYXV0aF90aW1lIjoxNzA5MDE2MTExLCJqdGkiOiI4YThiYmE0NS1kYjFjLTQxZjktYmMzNi05OTlmOTY3NDNjZjkiLCJpc3MiOiJodHRwczovL2xvY3VzLWF1dGguemVzc3RhLmNvbS9yZWFsbXMvTG9jdXNfcmVhbG0iLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiZWYxNWNkOGQtOGVhZC00ZDUzLWFhN2ItNWM0YjNiZWJiZmVmIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoibG9jdXMtYXV0aCIsIm5vbmNlIjoiZWU2OGI2MjgtMmViNy00MjRkLWIxOWQtOTkxNzc4ZmMwOGUyIiwic2Vzc2lvbl9zdGF0ZSI6IjYxOGYyY2RhLWVjNzEtNDE2ZS05YjExLTA4YzA5YWQxYWRkYSIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cHM6Ly9sb2N1cy1kZXYuemVzc3RhLmNvbSJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiZGVmYXVsdC1yb2xlcy1sb2N1c19yZWFsbSIsIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwic2lkIjoiNjE4ZjJjZGEtZWM3MS00MTZlLTliMTEtMDhjMDlhZDFhZGRhIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiSmFzd2FudGggTWF0YW0iLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJqYXN3YW50aC5tYXRhbUB6ZXNzdGEuY29tIiwiZ2l2ZW5fbmFtZSI6Ikphc3dhbnRoIiwiZmFtaWx5X25hbWUiOiJNYXRhbSIsImVtYWlsIjoiamFzd2FudGgubWF0YW1AemVzc3RhLmNvbSJ9.VAESjB27-zqoSac6855Z8zcmJBLwuYRtKLfYJHvPPDIgFF-IEJ6MOT5rSfnlpcrZ3eJln_uVQ9PQyWUXskCdcuebTHc7MNtTf_FMjGayeO-8HRan_Yb0yWz0YYo2WoQSkh3Y7L7LOeIXZpCwVmcj856u0z9Kw8XELhao18VSU8Jo63EJGbxpmcleRsw8hSucpovDDIwAg8m986oLmLXv5sYgQPyaCZDlEo6s58DfmTb5eDT17z5EcUtsmebft8FuMjG_HDGuB1qjQ-G3emMhx0SV9TzBhnvkqi1RgffYNyjc2clAMjtlpYKASy9QoFRSaxmTjjOAk5pxrdr-gZsuQQ'
        }
        odFlowsFeatureFlag={false}
        transitFlag={false}
      /> */}
      <TransitDashboard
        dashboardId={'41f7ed03-331f-4214-be3c-b704e2758188'}
        clientId={'389a9f8f-da8d-4c5b-87dc-dbdad4b639aa'}
        // dashboardId={'f6fcc02e-d648-4611-a3e8-7dc99991570e'}
        dashboardName={'New WMATA Transit'}
        viewName={'New WMATA Transit - default view'}
        userId={'44b9fa2b-035b-42d1-ab82-f36c43d238cb'}
        viewId={'061c0137-954f-48af-a78c-a4edb5296af3'}
        ableToSaveNewView={true} // Enable or disable save as button based on this prop
        ableToUpdateView={true} // Enable or disable Save button based on this prop
        shareView={() => false} // Trigger this function on click on shareView button
        // Token pulled from local storage on https://locus-dev.zessta.com/home for generic user `mkhan@camsys.com` with password `Locus@123`
        // I'm mostly sure this is a temporary token, so not sure why this is how Zessta suggested I get a token
        accessToken={
          'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJIdi1fSHFBV0hfaXhlNnJLbTNXVTBaV2hsNTVXOWdyRTFyXzVWUndDTHhzIn0.eyJleHAiOjE3MDkwNDQ5MTEsImlhdCI6MTcwOTAxNjExMSwiYXV0aF90aW1lIjoxNzA5MDE2MTExLCJqdGkiOiI4YThiYmE0NS1kYjFjLTQxZjktYmMzNi05OTlmOTY3NDNjZjkiLCJpc3MiOiJodHRwczovL2xvY3VzLWF1dGguemVzc3RhLmNvbS9yZWFsbXMvTG9jdXNfcmVhbG0iLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiZWYxNWNkOGQtOGVhZC00ZDUzLWFhN2ItNWM0YjNiZWJiZmVmIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoibG9jdXMtYXV0aCIsIm5vbmNlIjoiZWU2OGI2MjgtMmViNy00MjRkLWIxOWQtOTkxNzc4ZmMwOGUyIiwic2Vzc2lvbl9zdGF0ZSI6IjYxOGYyY2RhLWVjNzEtNDE2ZS05YjExLTA4YzA5YWQxYWRkYSIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cHM6Ly9sb2N1cy1kZXYuemVzc3RhLmNvbSJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiZGVmYXVsdC1yb2xlcy1sb2N1c19yZWFsbSIsIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwic2lkIjoiNjE4ZjJjZGEtZWM3MS00MTZlLTliMTEtMDhjMDlhZDFhZGRhIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiSmFzd2FudGggTWF0YW0iLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJqYXN3YW50aC5tYXRhbUB6ZXNzdGEuY29tIiwiZ2l2ZW5fbmFtZSI6Ikphc3dhbnRoIiwiZmFtaWx5X25hbWUiOiJNYXRhbSIsImVtYWlsIjoiamFzd2FudGgubWF0YW1AemVzc3RhLmNvbSJ9.VAESjB27-zqoSac6855Z8zcmJBLwuYRtKLfYJHvPPDIgFF-IEJ6MOT5rSfnlpcrZ3eJln_uVQ9PQyWUXskCdcuebTHc7MNtTf_FMjGayeO-8HRan_Yb0yWz0YYo2WoQSkh3Y7L7LOeIXZpCwVmcj856u0z9Kw8XELhao18VSU8Jo63EJGbxpmcleRsw8hSucpovDDIwAg8m986oLmLXv5sYgQPyaCZDlEo6s58DfmTb5eDT17z5EcUtsmebft8FuMjG_HDGuB1qjQ-G3emMhx0SV9TzBhnvkqi1RgffYNyjc2clAMjtlpYKASy9QoFRSaxmTjjOAk5pxrdr-gZsuQQ'
        }
      />
    </>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
