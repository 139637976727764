import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EngTranslation from './locales/en/enTranslation.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translations: EngTranslation,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    load: 'languageOnly',
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  });

export default i18n;
