import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import './i18n';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import hat from 'hat';
import './App.scss';
import Header from './components/Header';
import Toggle from './components/Toggle';
import Sidebar from './components/Sidebar';
import { useMapStore as originMapStore } from './store/origin-map';
import { useMapStore as destinationMapStore } from './store/destination-map';
import { useMapDrawStore as useOriginMapDrawStore } from './store/origin-map-draw';
import { useMapDrawStore as useDestinationMapDrawStore } from './store/destination-map-draw';
import { useApiRequestsStore } from './store/api-requests';
import {
  getDashboard,
  getFilters,
  updateFilters,
  createNewView,
  loadSummaryData,
  loadData,
  loadTotalTrips,
  getMapLayers,
  getStateAndCountyDetails,
  getStateCountiesDashboard,
  getGeoSelectionIds,
} from './connectors';
import {
  MAPBOX_STYLE_URLS,
  CLASSIFICATION_METHODS,
  ZONE_TYPE_OPTIONS,
  apiFiltersToAppFilters,
  appFiltersToQueryFilters,
  appFiltersToApiFilters,
  apiSummaryDataToAppSummaryData,
  FILTERS_LABELS,
  ZONE_SYSTEM_TYPES,
  CHOROPLETH_BASIS_OPTIONS,
  mergeObjects,
  updateFilterValues,
} from './constants';
import MenuSelector from './components/MenuSelector';
import MenuContainer from './components/MenuContainer';
import MapMenu from './components/menus/MapMenu';
import FiltersMenu from './components/menus/FiltersMenu';
import Layout from './components/Layout';
import classnames from 'classnames';
import { tempDashboardTransform } from './temp-dashboard-transform';
import { DEFAULT_PALETTE, DEFAULT_BIN_SIZE } from './color_palettes';
import PaletteSwitcher from './components/PaletteSwitcher';
import { deduplicateRequestHelper } from './deduplicate-request-helper';
import { useReportStore } from './store/reportStore';
import localDashboards from './dashboards';
import { useLoadingStore } from './store/loaders';
import { useCustomLayersStore } from './store/customLayers';
import TripsDisplay from './components/TripsDisplay';
import { ReactComponent as ExpandIcon } from './images/expand_transit.svg';
import Scatterplot from './components/Scatterplot';
import { useDashboardStore } from './store/dashboard';
import { useStateCountyStore } from './store/state-county';
import { useMenuDrawStore } from './store/menuDraw';

const sleep = delay => new Promise(resolve => setTimeout(resolve, delay));
const ref = React.createRef();

function App({
  clientName,
  dashboardId,
  dashboardName,
  viewName,
  userId,
  viewId,
  ableToSaveNewView,
  ableToUpdateView,
  shareView,
  accessToken,
  clientLogo,
}) {
  const { t } = useTranslation();
  const initialLoadId = useRef();
  const activeCustomLayers = useCustomLayersStore(state => state.active);
  const addCustomLayer = useCustomLayersStore(state => state.add);
  const setLoadingState = useLoadingStore(state => state.setLoadingState);
  const setStateCountyData = useStateCountyStore(
    state => state.setStateCountyData
  );
  const [tripsExpanded, setTripsExpanded] = useState(false);

  const [dashboardType, setDashboardType] = useState(null);

  const allowRequest = useApiRequestsStore(state => state.allowRequest);

  const [uiHidden, setUiHidden] = useState(false);
  const [mapLayers, setMapLayers] = useState([]);

  const [filterValues, setFilterValues] = useState(null);

  const [activeDashboardInstance, setActiveDashboardInstance] = useState(null);
  const setMapDrawOrigin = useOriginMapDrawStore(state => state.setMapDraw);
  const mapDrawOrigin = useOriginMapDrawStore(state => state.mapDraw);
  const setCurrentModeOrigin = useOriginMapDrawStore(
    state => state.setActiveMode
  );
  const setMapDrawDestination = useDestinationMapDrawStore(
    state => state.setMapDraw
  );
  const mapDrawDestination = useDestinationMapDrawStore(state => state.mapDraw);
  const setCurrentModeDestination = useDestinationMapDrawStore(
    state => state.setActiveMode
  );
  const setOriginSelectionGeoId = originMapStore(
    state => state.setSelectedGeoid
  );
  const setDestinationSelectionGeoId = destinationMapStore(
    state => state.setSelectedGeoid
  );

  const setMenuDraw = useMenuDrawStore(state => state.setMenuDraw);

  const originMapInstance = originMapStore(state => state.mapInstance);

  const destinationMapInstance = originMapStore(state => state.mapInstance);

  const originLayerShape = originMapStore(state => state.layerShape);
  const setOriginLayerShape = originMapStore(state => state.setLayerShape);
  const originSelectedGeoIds = originMapStore(state => state.selectedGeoIds);
  const setOriginSelectedGeoIds = originMapStore(
    state => state.setSelectedGeoIds
  );
  const destinationLayerShape = destinationMapStore(state => state.layerShape);
  const setDestinationLayerShape = destinationMapStore(
    state => state.setLayerShape
  );
  const destinationSelectionGeoIds = destinationMapStore(
    state => state.selectedGeoIds
  );
  const setDestinationSelectionGeoIds = destinationMapStore(
    state => state.setSelectedGeoIds
  );

  const getAllZoneSystemIds = () => {
    const zoneSystemIds = [];
    activeDashboardInstance?.zoneSystems?.forEach(item => {
      zoneSystemIds.push(item.id);
    });

    return zoneSystemIds;
  };

  const getGeoIdsByShape = async (
    layerShape,
    setSelectionGeoIds,
    zoneType,
    selectedGeoIds
  ) => {
    const zoneSystemIds = getAllZoneSystemIds();
    const nonPointSelectionShapes = layerShape?.default?.filter(
      item => item?.properties?.selection !== 'point'
    );
    if (nonPointSelectionShapes.length && !layerShape?.isPointSelected) {
      const result = await getGeoSelectionIds(
        nonPointSelectionShapes,
        zoneSystemIds,
        null,
        zoneType,
        null
      );
      const updatedGeoIds = mergeObjects(selectedGeoIds, result);
      setSelectionGeoIds(updatedGeoIds);
    }
  };

  useEffect(() => {
    if (originLayerShape?.default.length && originMapInstance) {
      getGeoIdsByShape(
        originLayerShape,
        setOriginSelectedGeoIds,
        zoneType,
        originSelectedGeoIds
      );
    }
  }, [originLayerShape]);

  useEffect(() => {
    if (destinationLayerShape?.default.length && destinationMapInstance) {
      getGeoIdsByShape(
        destinationLayerShape,
        setDestinationSelectionGeoIds,
        zoneType,
        destinationSelectionGeoIds
      );
    }
  }, [destinationLayerShape]);

  useEffect(() => {
    const getStateAndCountyData = async () => {
      const result = await getStateAndCountyDetails(dashboardId, accessToken);
      setStateCountyData(result);
    };

    getStateAndCountyData();
  }, [dashboardId, accessToken, getStateAndCountyDetails]);

  useEffect(() => {
    // TODO TRANSIT remove this. Simply disabling this functionality for now until API can handle
    if (dashboardType !== 'passenger') return;
    const getMapLayersFn = async () => {
      const result = await getMapLayers(dashboardId, accessToken);
      result?.forEach(item => {
        item.paint = item.style;
      });
      setMapLayers(result);
    };

    getMapLayersFn();
  }, [dashboardId, accessToken, dashboardType]);

  const [direction, setDirection] = useState(['origin']);
  // File name to get
  const [filters, setFilters] = useState({});
  const [dataFilters, setDataFilters] = useState({});

  const initialFilters = useRef({});

  // ---------

  // MAP VISUALS
  const [dataKey, setDataKey] = useState(null);

  // STYLING
  // Color palette to use for choropleth
  const [palette, setPalette] = useState(DEFAULT_PALETTE.palette);
  const [classificationMethod, setClassificationMethod] = useState(
    Object.values(CLASSIFICATION_METHODS)[0]?.value
  );
  const [binSize, setBinSize] = useState(DEFAULT_BIN_SIZE);
  // Expression type to use for choropleth
  const [scaleType, setScaleType] = useState('step');

  const [zoneType, setZoneType] = useState(null);
  const [isSaveNewView, setIsSaveNewView] = useState(false);

  // ------------------------------------------------------------------------------------------
  // DATA
  // Data to pass down into map to join with vector tiles
  // ORIGIN data
  const [originData, setOriginData] = useState({});

  // DESTINATION data
  const [destinationData, setDestinationData] = useState({});

  // TRANSIT trips data
  const [transitTripsData, setTransitTripsData] = useState(null);

  // STYLING
  // Origin
  const [highlightedGeoidsOrigin, setHighlightedGeoidsOrigin] = useState([]);
  // Destination
  const [highlightedGeoidsDestination, setHighlightedGeoidsDestination] =
    useState([]);

  const [styleUrl, setStyleUrl] = useState(MAPBOX_STYLE_URLS.light);

  // Panels
  const [activeMenu, setActiveMenu] = useState(null);

  // SUMMARY DATA
  const [summaryData, setSummaryData] = useState(null);
  const [dataSummaryData, setDataSummaryData] = useState(null);
  const [highlightedSummaryData, setHighlightedSummaryData] = useState(null);
  const [dataHighlightedSummaryData, setDataHighlightedSummaryData] =
    useState(null);
  const [summaryDataSegmentation, setSummaryDataSegmentation] = useState(null);
  const [boundingShape, setBoundingShape] = useState(null);

  const setStateCounties = useDashboardStore(state => state.setStateCounties);

  // TOTAL TRIPS
  const [totalTrips, setTotalTrips] = useState(null);
  const [totalPmt, setTotalPmt] = useState(null);
  const pdfReport = useReportStore(state => state.pdfReport);
  const setChartOptions = useReportStore(state => state.setChartOptions);
  const setPdfDetails = useReportStore(state => state.setPdfDetails);
  const pdfDetails = useReportStore(state => state.pdfDetails);
  const setMapDirection = useReportStore(state => state.setMapDirection);
  const setLabelSource = useReportStore(state => state.setLabelSource);

  const getActiveCustomMapLayers = layers => {
    let mapLayerOptions = [];
    if (layers.length) {
      mapLayerOptions =
        layers?.map(layer => _.pick(layer, ['id', 'paint'])) || [];
    }

    return mapLayerOptions;
  };

  const enableMapLayer = useCallback(
    layer => {
      addCustomLayer({ ...layer, visibility: 'visible' });
    },
    [addCustomLayer]
  );

  useEffect(() => {
    if (
      highlightedGeoidsOrigin.length &&
      !Object.values(ZONE_SYSTEM_TYPES).includes(zoneType)
    ) {
      let data = { ...originSelectedGeoIds };
      data[zoneType] = highlightedGeoidsOrigin;
      setOriginSelectedGeoIds(data);
    }
  }, [highlightedGeoidsOrigin]);

  useEffect(() => {
    if (
      highlightedGeoidsDestination.length &&
      !Object.values(ZONE_SYSTEM_TYPES).includes(zoneType)
    ) {
      let data = { ...destinationSelectionGeoIds };
      data[zoneType] = highlightedGeoidsDestination;
      setDestinationSelectionGeoIds(data);
    }
  }, [highlightedGeoidsDestination]);

  // ------------------------------------------------------------------------------------------

  const getFilterLabel = property => {
    let label = FILTERS_LABELS.find(v => property === v.value)?.label;
    if (!label) {
      label = property.replaceAll('_', ' ');
      label = label.toUpperCase();
    }
    return label;
  };

  const getStateCounties = async () => {
    const details = await getStateCountiesDashboard(dashboardId, accessToken);
    if (details?.data) {
      setStateCounties(details?.data);
    }
  };

  useEffect(() => {
    if (!filterValues || !dashboardType) return;

    const requestViewFilters = async () => {
      let viewFilters = await getFilters(viewId, accessToken);

      // Check if there are any selections saved
      const originMapSelectionData =
        viewFilters?.viewsOptions?.originMapSelections;
      if (originMapSelectionData) {
        if (originMapSelectionData?.originLayerShape) {
          setOriginLayerShape(originMapSelectionData?.originLayerShape);
        }

        if (originMapSelectionData?.originSelectedGeoIds) {
          setOriginSelectedGeoIds(originMapSelectionData?.originSelectedGeoIds);
        }
      }

      const destinationMapSelectionData =
        viewFilters?.viewsOptions?.destinationMapSelections;
      if (destinationMapSelectionData) {
        if (destinationMapSelectionData?.destinationLayerShape) {
          setDestinationLayerShape(
            destinationMapSelectionData?.destinationLayerShape
          );
        }

        if (destinationMapSelectionData?.destinationSelectionGeoIds) {
          setDestinationSelectionGeoIds(
            destinationMapSelectionData?.destinationSelectionGeoIds
          );
        }
      }
      // Check if there is any segmentation saved
      if (viewFilters?.viewsOptions?.segmentDataBy) {
        setSummaryDataSegmentation(viewFilters?.viewsOptions?.segmentDataBy);
      }
      viewFilters?.viewsOptions?.mapLayers.forEach(item => {
        enableMapLayer(item);
      });
      viewFilters = viewFilters?.filterValues ?? {};
      const nextFilters = apiFiltersToAppFilters(viewFilters);
      nextFilters?.equity?.push('Undefined');
      initialFilters.current = nextFilters;

      // Todo: Update later when we implement backend functionality for multiple study periods
      const studyPeriods = activeDashboardInstance?.studyPeriods;
      if (nextFilters?.period?.length > 1) {
        const periodEndDates = studyPeriods.map(item => moment(item.endDate));

        const latestPeriodIndex = periodEndDates.indexOf(
          moment.max(periodEndDates)
        );
        nextFilters.period = [studyPeriods[latestPeriodIndex].name];
      }

      setFilters(nextFilters);
    };
    // TODO TRANSIT this will be fully removed with API integration
    if (dashboardType === 'transit') {
      // Arbitrary filters based on hard files
      const initialTransitFilters = {
        day_of_week: ['Friday'],
        time_of_day: ['PM Peak: 3:00 pm – 7:00 pm'],
        equity: ['Equity Communities'],
        purpose: ['Non-commute'],
        itinerary_type: ['Bus Only'],
        travel_time_ratio_bin: ['4.5 - 5.0'],
      };
      initialFilters.current = initialTransitFilters;
      setFilters(initialTransitFilters);
    } else {
      requestViewFilters();
    }
  }, [filterValues, viewId, initialFilters, accessToken, dashboardType]);

  useEffect(() => {
    const requestDashboard = async () => {
      const nextDashboard = await getDashboard(dashboardId, 'map');
      if (
        nextDashboard?.dashboard?.filterOptions?.statesandCounties?.length > 0
      ) {
        setStateCounties(
          nextDashboard?.dashboard?.filterOptions?.statesandCounties
        );
      } else {
        getStateCounties();
      }

      const boundingShapes = {
        default: nextDashboard?.dashboard?.studyArea?.boundingShape,
      };

      const zoneSystems = nextDashboard?.dashboard?.zoneSystems;
      if (zoneSystems?.length) {
        zoneSystems.forEach(item => {
          boundingShapes[item?.name] = item?.boundingShape;
        });
      }

      setBoundingShape(boundingShapes);

      nextDashboard?.dashboard?.filterOptions?.equities?.push({
        equity: 'Undefined',
        label: 10,
      });

      const formattedDashboard = tempDashboardTransform(
        clientName,
        userId,
        dashboardName,
        nextDashboard
      );

      const nextDashboardType =
        formattedDashboard?.type.toLowerCase() ?? 'passenger';
      setDashboardType(nextDashboardType);
      if (nextDashboardType === 'transit') {
        setDirection(['origin', 'destination']);
      }

      setActiveDashboardInstance(formattedDashboard);
      const studyPeriodDetails = nextDashboard?.dashboard?.studyPeriods?.map(
        item => item?.name
      );
      const weekDetails =
        nextDashboard?.dashboard?.timePartition?.timeperiods?.map(
          item => item?.name
        );
      const data = {
        viewName: viewName,
        createdAt: nextDashboard?.dashboard?.createdAt,
        projectName: nextDashboard?.dashboard?.name,
        weekSelection: weekDetails?.join(', '),
        studyPeriod: studyPeriodDetails?.join(', '),
      };
      setPdfDetails(data);
    };

    // TODO TRANSIT Sleep fn just simulates a wait time from API to confirm functionality
    // This will be combined with general dashboard logic
    const requestTransitDashboard = async () => {
      await sleep(5000);
      const transitDashboard = localDashboards[dashboardId];
      setDashboardType(transitDashboard?.type.toLowerCase());
      setActiveDashboardInstance(transitDashboard);
    };

    // TODO TRANSIT this is temporarily pulling a mock dashboard from files, should be combined with other logic here
    if (dashboardId === 'mock-transit-dashboard') {
      requestTransitDashboard();
      setDirection(['origin', 'destination']);
    } else {
      requestDashboard();
    }
  }, [
    dashboardId,
    userId,
    dashboardName,
    clientName,
    setActiveDashboardInstance,
    dashboardType,
  ]);

  useEffect(() => {
    if (!dashboardType && !initialLoadId.current) {
      initialLoadId.current = hat();
      setLoadingState('map', initialLoadId.current);
    }
    // Once dashboardType gets set, update the appropriate starting data key and zone type
    setDataKey(dashboardType === 'transit' ? 'total_trips' : 'daily_trips');
    setZoneType(
      dashboardType === 'transit' ? 'transit_geomarkets' : 'Censustract'
    );

    if (dashboardType && initialLoadId.current) {
      setLoadingState('map', initialLoadId.current);
      initialLoadId.current = null;
    }
  }, [dashboardType, setLoadingState, initialLoadId]);

  useEffect(() => {
    if (!activeDashboardInstance) return;

    if (activeDashboardInstance?.studyPeriods?.length > 1) {
      const sortStudyPeriods = activeDashboardInstance?.studyPeriods?.sort(
        (date1, date2) => moment(date1.endDate).diff(moment(date2.endDate))
      );
      activeDashboardInstance.filterOptions.studyperiods =
        sortStudyPeriods?.map(item => ({ period: item.name }));
    }

    const filterValues = apiFiltersToAppFilters(
      activeDashboardInstance?.filterOptions ?? {}
    );

    setFilterValues(filterValues);
  }, [activeDashboardInstance]);

  const handleGeoSelections = useCallback(
    zone => {
      if (mapDrawOrigin?._enabled) {
        const ids = mapDrawOrigin.getSnapshot().map(({ id }) => id);
        mapDrawOrigin.removeFeatures(ids);
        mapDrawOrigin.clear();
        mapDrawOrigin.stop();
        const modeToSet = 'select';
        setMapDrawOrigin(null);
        setCurrentModeOrigin(modeToSet);
      }

      if (mapDrawDestination?._enabled) {
        const ids = mapDrawDestination.getSnapshot().map(({ id }) => id);
        mapDrawDestination.removeFeatures(ids);
        mapDrawDestination.clear();
        mapDrawDestination.stop();
        const modeToSet = 'select';
        setMapDrawDestination(null);
        setCurrentModeDestination(modeToSet);
      }

      setHighlightedGeoidsOrigin([]);
      setHighlightedGeoidsDestination([]);
      setOriginSelectionGeoId(null);
      setDestinationSelectionGeoId(null);
      setZoneType(zone);
    },
    [
      mapDrawOrigin,
      setMapDrawOrigin,
      setCurrentModeOrigin,
      mapDrawDestination,
      setMapDrawDestination,
      setCurrentModeDestination,
      setZoneType,
      highlightedGeoidsOrigin,
      highlightedGeoidsDestination,
      setOriginSelectionGeoId,
      setDestinationSelectionGeoId,
    ]
  );

  const zoneOptions = useMemo(() => {
    let options = [...ZONE_TYPE_OPTIONS].filter(
      item => item.dashboardType === dashboardType
    );
    if (
      activeDashboardInstance?.zoneSystems &&
      activeDashboardInstance?.zoneSystems.length
    ) {
      const zones = activeDashboardInstance?.zoneSystems.map(z => ({
        label: z.name,
        value: z.name,
      }));
      options = options.concat(zones);
    }
    return options;
  }, [activeDashboardInstance, dashboardType]);

  const renderMenu = useCallback(
    menu => {
      let selectedMenu = null;
      if (!menu) return selectedMenu;

      switch (menu) {
        case 'mapOptions':
          selectedMenu = (
            <MapMenu
              styleUrl={styleUrl}
              setStyleUrl={setStyleUrl}
              mapLayers={mapLayers}
            />
          );
          break;
        case 'filterOptions':
          selectedMenu = (
            <FiltersMenu
              onChangeProperties={data => {
                setOriginData({});
                setDestinationData({});
                setFilters(data);
              }}
              filters={filters}
              filterValues={filterValues}
              initialFilters={initialFilters.current}
              accessToken={accessToken}
            />
          );
          break;
        case 'dataOptions':
          selectedMenu = (
            <PaletteSwitcher
              // Prevent the reference from causing issues
              palette={JSON.parse(JSON.stringify(palette))}
              scaleType={scaleType}
              setPalette={setPalette}
              setScaleType={setScaleType}
              setClassificationMethod={setClassificationMethod}
              classificationMethod={classificationMethod}
              binSize={binSize}
              setBinSize={setBinSize}
              setDataKey={setDataKey}
              dataKey={dataKey}
              activeDashboardInstance={activeDashboardInstance}
              setZoneType={zone => {
                handleGeoSelections(zone);
              }}
              zoneOptions={zoneOptions}
              zoneType={zoneType}
              dashboardType={dashboardType}
            />
          );
          break;
        default:
          return null;
      }

      return (
        <MenuContainer closeMenu={() => setActiveMenu(null)}>
          {selectedMenu}
        </MenuContainer>
      );
    },
    [
      styleUrl,
      filterValues,
      filters,
      binSize,
      scaleType,
      palette,
      dataKey,
      classificationMethod,
      activeDashboardInstance,
      zoneOptions,
      zoneType,
      dashboardType,
      mapDrawOrigin,
      setMapDrawOrigin,
      mapDrawDestination,
      setMapDrawDestination,
      highlightedGeoidsOrigin,
    ]
  );

  // Update data filters
  useEffect(() => {
    if (!activeDashboardInstance) return;
    let next = { ...filters };

    // TODO TRANSIT remove once using API
    if (dashboardType !== 'transit') {
      next = appFiltersToQueryFilters(
        filters,
        activeDashboardInstance?.filterOptions
      );
    }

    setDataFilters(next);
  }, [filters, activeDashboardInstance, dashboardType]);

  // Update summary data
  useEffect(() => {
    if (!activeDashboardInstance || !summaryData) return;

    const nextSummaryData = apiSummaryDataToAppSummaryData(
      summaryData,
      summaryDataSegmentation,
      activeDashboardInstance?.filterOptions
    );

    let options = [];
    setDataSummaryData(nextSummaryData);
    const updatedOptions = options
      .concat(
        Object.keys(nextSummaryData).map(d => ({
          label: getFilterLabel(d),
          value: d,
        }))
      )
      .filter(
        v => !FILTERS_LABELS.find(l => v.value === l.value)?.removeHistogram
      );
    setChartOptions(updatedOptions);
  }, [summaryData, summaryDataSegmentation, activeDashboardInstance]);

  // Update higlighted summary data
  useEffect(() => {
    if (!activeDashboardInstance) return;
    if (!highlightedSummaryData) {
      setDataHighlightedSummaryData(null);
      return;
    }

    const nextSummaryData = apiSummaryDataToAppSummaryData(
      highlightedSummaryData,
      summaryDataSegmentation,
      activeDashboardInstance?.filterOptions
    );

    setDataHighlightedSummaryData(nextSummaryData);
  }, [
    highlightedSummaryData,
    summaryDataSegmentation,
    activeDashboardInstance,
  ]);

  const onUpdateView = useCallback(async () => {
    if (!activeDashboardInstance) return;
    const nextData = appFiltersToApiFilters(
      filters,
      activeDashboardInstance?.filterOptions
    );

    await updateFilters(
      viewId,
      {
        filterValues: { ...nextData },
        viewsOptions: {
          mapLayers: getActiveCustomMapLayers(activeCustomLayers),
          segmentDataBy: summaryDataSegmentation,
          originMapSelections: {
            originLayerShape,
            originSelectedGeoIds,
          },
          destinationMapSelections: {
            destinationLayerShape,
            destinationSelectionGeoIds,
          },
        },
      },
      accessToken,
      'map'
    );
  }, [
    viewId,
    filters,
    accessToken,
    activeDashboardInstance,
    activeCustomLayers,
    summaryDataSegmentation,
    destinationLayerShape,
    destinationSelectionGeoIds,
    originLayerShape,
    originSelectedGeoIds,
  ]);

  const onSaveNewView = useCallback(
    async values => {
      if (!activeDashboardInstance) return;
      const nextData = appFiltersToApiFilters(
        filters,
        activeDashboardInstance?.filterOptions
      );

      const dashboardDetails = {
        name: values.name,
        description: values.description,
        dashboardId: activeDashboardInstance?.dashboardId,
      };

      await createNewView(
        {
          filterValues: { ...nextData },
          viewsOptions: {
            mapLayers: getActiveCustomMapLayers(activeCustomLayers),
            segmentDataBy: summaryDataSegmentation,
            originMapSelections: {
              originLayerShape,
              originSelectedGeoIds,
            },
            destinationMapSelections: {
              destinationLayerShape,
              destinationSelectionGeoIds,
            },
          },
        },
        dashboardDetails,
        accessToken,
        'map'
      );
      setIsSaveNewView(false);
    },
    [
      filters,
      accessToken,
      activeDashboardInstance,
      activeCustomLayers,
      summaryDataSegmentation,
      destinationLayerShape,
      destinationSelectionGeoIds,
      originLayerShape,
      originSelectedGeoIds,
    ]
  );

  // ------------------------------------------------------------------------------
  const getThrottledDataFn = useCallback((loadFn, setFn, ...args) => {
    const fn = _.throttle(
      async () => {
        const nextData = await loadFn(...args);
        if (!nextData) return;
        setFn(nextData);
      },
      150,
      { trailing: true }
    );
    return fn;
  }, []);

  const getThrottleZoneSystemDataFn = useCallback(
    (zoneType, setFn, ...args) => {
      const fn = _.throttle(
        async () => {
          const nextData = await loadData(...args);
          if (!nextData) return;
          setFn(prevData => ({
            ...prevData,
            [zoneType]: nextData,
          }));
        },
        150,
        { trailing: true }
      );
      return fn;
    },
    []
  );

  const importOriginData = async () => {
    let modifiedFilters = updateFilterValues(dataFilters, filterValues);
    delete modifiedFilters.zoneSystem;
    modifiedFilters.zone_system = [zoneType];

    if (highlightedGeoidsDestination.length) {
      modifiedFilters['destination_geo'] = highlightedGeoidsDestination;
    } else if (destinationSelectionGeoIds?.[zoneType]?.length) {
      modifiedFilters['destination_geo'] =
        destinationSelectionGeoIds?.[zoneType];
    }

    if (highlightedGeoidsOrigin.length) {
      modifiedFilters['origin_geo'] = highlightedGeoidsOrigin;
    } else if (originSelectedGeoIds?.[zoneType]?.length) {
      modifiedFilters['origin_geo'] = originSelectedGeoIds?.[zoneType];
    }

    if (Object.keys(dataFilters).length) {
      const args = [activeDashboardInstance, 'origin', modifiedFilters, 'map'];

      const getDataFn = getThrottleZoneSystemDataFn(
        zoneType,
        setOriginData,
        ...args
      );

      deduplicateRequestHelper('loadOriginData', getDataFn, ...args);
    }
  };

  // Set origin data
  useEffect(() => {
    setMapDirection(direction);
    if (
      !activeDashboardInstance ||
      !direction.includes('origin') ||
      _.isEmpty(dataFilters)
    )
      return;

    importOriginData();
  }, [
    activeDashboardInstance,
    direction,
    dataFilters,
    summaryDataSegmentation,
    highlightedGeoidsDestination,
    highlightedGeoidsOrigin,
    setOriginData,
    getThrottledDataFn,
  ]);

  const importDestinationData = async () => {
    let modifiedFilters = updateFilterValues(dataFilters, filterValues);
    delete modifiedFilters.zoneSystem;
    modifiedFilters.zone_system = [zoneType];

    if (highlightedGeoidsDestination.length) {
      modifiedFilters['destination_geo'] = highlightedGeoidsDestination;
    } else if (destinationSelectionGeoIds?.[zoneType]?.length) {
      modifiedFilters['destination_geo'] =
        destinationSelectionGeoIds?.[zoneType];
    }

    if (highlightedGeoidsOrigin.length) {
      modifiedFilters['origin_geo'] = highlightedGeoidsOrigin;
    } else if (originSelectedGeoIds?.[zoneType]?.length) {
      modifiedFilters['origin_geo'] = originSelectedGeoIds?.[zoneType];
    }

    if (Object.keys(dataFilters).length) {
      const args = [
        activeDashboardInstance,
        'destination',
        modifiedFilters,
        'map',
      ];

      const getDataFn = getThrottleZoneSystemDataFn(
        zoneType,
        setDestinationData,
        ...args
      );

      deduplicateRequestHelper('loadDestinationData', getDataFn, ...args);
    }
  };

  // Set destination data
  useEffect(() => {
    if (
      !activeDashboardInstance ||
      !direction.includes('destination') ||
      _.isEmpty(dataFilters)
    )
      return;

    importDestinationData();
  }, [
    activeDashboardInstance,
    direction,
    dataFilters,
    summaryDataSegmentation,
    highlightedGeoidsOrigin,
    highlightedGeoidsDestination,
    setDestinationData,
    getThrottledDataFn,
  ]);

  // set origin and destination data on zone type change
  useEffect(() => {
    if (zoneType && !originData[zoneType] && direction.includes('origin')) {
      importOriginData();
    }

    if (
      zoneType &&
      !destinationData[zoneType] &&
      direction.includes('destination')
    ) {
      importDestinationData();
    }
  }, [zoneType]);

  // We only want to grab summary data for trips or pmt, while dataKey can also be shares of trips
  const summaryDataKey = useMemo(
    () => (dataKey === 'daily_pmt' ? 'daily_pmt' : 'daily_trips'),
    [dataKey]
  );

  // Summary data
  useEffect(() => {
    if (
      !activeDashboardInstance ||
      _.isEmpty(dataFilters) ||
      dashboardType === 'transit'
    )
      return;
    const importData = async () => {
      let modifiedFilters = updateFilterValues(dataFilters, filterValues);

      delete modifiedFilters.zoneSystem;
      modifiedFilters.zone_system = [zoneType];

      const args = [
        undefined,
        activeDashboardInstance,
        modifiedFilters,
        summaryDataKey,
        'histograms',
        {
          ...(summaryDataSegmentation && {
            segmentation: summaryDataSegmentation,
          }),
        },
      ];

      const getDataFn = getThrottledDataFn(
        loadSummaryData,
        setSummaryData,
        ...args
      );

      deduplicateRequestHelper('loadSummaryData', getDataFn, ...args);
    };

    importData();
  }, [
    activeDashboardInstance,
    dataFilters,
    summaryDataKey,
    summaryDataSegmentation,
    setSummaryData,
    allowRequest,
    getThrottledDataFn,
    dashboardType,
  ]);

  // Highlighted summary data
  useEffect(() => {
    if (
      !activeDashboardInstance ||
      _.isEmpty(dataFilters) ||
      dashboardType === 'transit'
    )
      return;
    const getHighlightedSummary = async () => {
      // if (
      //   !highlightedGeoidsOrigin.length &&
      //   !highlightedGeoidsDestination.length
      // )
      //   setHighlightedSummaryData(null);

      let nextFilters = updateFilterValues(dataFilters, filterValues);
      delete nextFilters.zoneSystem;
      nextFilters.zone_system = [zoneType];
      if (highlightedGeoidsOrigin.length) {
        nextFilters['origin_geo'] = highlightedGeoidsOrigin;
      } else if (originSelectedGeoIds?.[zoneType]?.length) {
        nextFilters['origin_geo'] = originSelectedGeoIds?.[zoneType];
      }

      if (highlightedGeoidsDestination.length) {
        nextFilters['destination_geo'] = highlightedGeoidsDestination;
      } else if (destinationSelectionGeoIds?.[zoneType]?.length) {
        nextFilters['destination_geo'] = destinationSelectionGeoIds?.[zoneType];
      }

      const args = [
        undefined,
        activeDashboardInstance,
        nextFilters,
        summaryDataKey,
        'highlightedHistograms',
        {
          ...(summaryDataSegmentation && {
            segmentation: summaryDataSegmentation,
          }),
        },
      ];

      const getDataFn = getThrottledDataFn(
        loadSummaryData,
        setHighlightedSummaryData,
        ...args
      );

      deduplicateRequestHelper(
        'loadHighlightedSummaryData',
        getDataFn,
        ...args
      );
    };
    if (
      direction &&
      dataFilters &&
      (highlightedGeoidsOrigin.length ||
        originSelectedGeoIds?.[zoneType]?.length ||
        highlightedGeoidsDestination.length ||
        destinationSelectionGeoIds?.[zoneType]?.length)
    ) {
      getHighlightedSummary();
    } else {
      setHighlightedSummaryData(null);
    }
  }, [
    activeDashboardInstance,
    direction,
    dataFilters,
    highlightedGeoidsOrigin,
    highlightedGeoidsDestination,
    summaryDataKey,
    summaryDataSegmentation,
    setHighlightedSummaryData,
    zoneType,
    getThrottledDataFn,
    dashboardType,
    originSelectedGeoIds,
    destinationSelectionGeoIds,
  ]);

  useEffect(() => {
    if (
      !activeDashboardInstance ||
      _.isEmpty(dataFilters) ||
      dashboardType === 'transit'
    )
      return;

    const setTotal = async () => {
      let studyAreaGeos = [];

      let originGeos = [];
      let destinationGeos = [];
      let modifiedFilters = updateFilterValues(dataFilters, filterValues);

      delete modifiedFilters.zoneSystem;
      modifiedFilters.zone_system = [zoneType];
      if (highlightedGeoidsOrigin.length) {
        modifiedFilters.zone_system = [zoneType];
        modifiedFilters['origin_geo'] = highlightedGeoidsOrigin;
      } else if (originSelectedGeoIds?.[zoneType]?.length) {
        modifiedFilters.zone_system = [zoneType];
        modifiedFilters['origin_geo'] = originSelectedGeoIds?.[zoneType];
      }

      if (highlightedGeoidsDestination.length) {
        modifiedFilters.zone_system = [zoneType];
        modifiedFilters['destination_geo'] = highlightedGeoidsDestination;
      } else if (destinationSelectionGeoIds?.[zoneType]?.length) {
        modifiedFilters.zone_system = [zoneType];
        modifiedFilters['destination_geo'] =
          destinationSelectionGeoIds?.[zoneType];
      }

      const args = [
        activeDashboardInstance,
        dashboardName,
        modifiedFilters,
        originGeos,
        destinationGeos,
        summaryDataKey,
        'totals',
      ];

      const setTotalsFn = async result => {
        const { daily_trips, daily_pmt } = result;

        setPdfDetails({
          ...pdfDetails,
          dailyTrip: daily_trips ?? 0,
          milesTraveled: daily_pmt ?? 0,
        });

        setTotalTrips(daily_trips ?? 0);
        setTotalPmt(daily_pmt ?? 0);
      };

      const getDataFn = getThrottledDataFn(
        loadTotalTrips,
        setTotalsFn,
        ...args
      );

      deduplicateRequestHelper('loadTotals', getDataFn, ...args);
    };

    setTotal();
  }, [
    activeDashboardInstance,
    summaryDataKey,
    dashboardName,
    dataFilters,
    direction,
    destinationData,
    highlightedGeoidsOrigin,
    highlightedGeoidsDestination,
    setTotalPmt,
    setTotalTrips,
    getThrottledDataFn,
    dashboardType,
  ]);

  useEffect(() => {
    pdfReport.pdfDestination && setDirection([pdfReport.pdfDestination]);
  }, [pdfReport.pdfDestination]);

  useEffect(() => {
    const key = CHOROPLETH_BASIS_OPTIONS.find(item => item?.value === dataKey);
    const type = ZONE_TYPE_OPTIONS.find(item => item?.value === zoneType);
    setLabelSource({
      selection: key?.label,
      zoneType: type?.label,
    });
  }, [dataKey, zoneType]);

  const getZoneSystemBoundingShape = () => {
    if (Object.values(ZONE_SYSTEM_TYPES).includes(zoneType)) {
      return boundingShape?.default;
    } else {
      return boundingShape?.[zoneType];
    }
  };

  return (
    <div id="App" className="App" ref={ref}>
      <Header
        originData={originData}
        destinationData={destinationData}
        downloadRef={ref}
        projectName={activeDashboardInstance?.name ?? 'Project name'}
        viewName={viewName ?? 'View name'}
        dimensions={filterValues ? Object.keys(filterValues) : null}
        activeDashboardInstance={activeDashboardInstance}
        filterValues={filterValues}
        summaryData={dataSummaryData}
        highlightedSummaryData={dataHighlightedSummaryData}
        dataSegmentation={summaryDataSegmentation}
        ableToSaveNewView={dataFilters ? ableToSaveNewView : false}
        ableToUpdateView={dataFilters ? ableToUpdateView : false}
        updateView={onUpdateView}
        saveNewView={onSaveNewView}
        shareView={shareView}
        isSaveNewView={isSaveNewView}
        handleCancel={() => setIsSaveNewView(false)}
        onSaveAsButtonClick={() => setIsSaveNewView(true)}
        setUiHidden={setUiHidden}
        uiHidden={uiHidden}
        clientLogo={clientLogo}
        dashboardType={dashboardType}
        dataFilters={dataFilters}
        zoneType={zoneType}
        direction={direction}
        highlightedGeoidsOrigin={highlightedGeoidsOrigin}
        highlightedGeoidsDestination={highlightedGeoidsDestination}
      />
      <div className="App-layout">
        <div className="App-layout-main">
          <div className="App-layout-maps-container">
            {!!zoneType && !!dataKey ? (
              <>
                {direction.includes('origin') ? (
                  <div
                    className={classnames('App-layout-map-container', {
                      border: direction.length > 1,
                    })}
                  >
                    <Layout
                      data={originData}
                      direction={'origin'}
                      boundingShape={getZoneSystemBoundingShape()}
                      styleUrl={styleUrl}
                      filters={dataFilters}
                      activeDashboardInstance={activeDashboardInstance}
                      highlightedGeoids={highlightedGeoidsOrigin}
                      summaryDataSegmentation={summaryDataSegmentation}
                      setHighlightedGeoidsForDirection={
                        setHighlightedGeoidsOrigin
                      }
                      mapStore={originMapStore}
                      mapDrawStore={useOriginMapDrawStore}
                      // TODO this is too many props being passed around, consider another approach
                      scaleType={scaleType}
                      binSize={binSize}
                      classificationMethod={classificationMethod}
                      palette={palette}
                      dataKey={dataKey}
                      zoneOptions={zoneOptions}
                      zoneType={zoneType}
                      uiHidden={uiHidden}
                      dashboardType={dashboardType}
                      setActiveMenu={setActiveMenu}
                    />
                  </div>
                ) : null}
                {direction.includes('destination') ? (
                  <div className="App-layout-map-container">
                    <Layout
                      data={destinationData}
                      direction={'destination'}
                      boundingShape={getZoneSystemBoundingShape()}
                      styleUrl={styleUrl}
                      filters={dataFilters}
                      activeDashboardInstance={activeDashboardInstance}
                      highlightedGeoids={highlightedGeoidsDestination}
                      summaryDataSegmentation={summaryDataSegmentation}
                      setHighlightedGeoidsForDirection={
                        setHighlightedGeoidsDestination
                      }
                      mapStore={destinationMapStore}
                      mapDrawStore={useDestinationMapDrawStore}
                      // TODO this is too many props being passed around, consider another approach
                      scaleType={scaleType}
                      binSize={binSize}
                      classificationMethod={classificationMethod}
                      palette={palette}
                      dataKey={dataKey}
                      zoneOptions={zoneOptions}
                      zoneType={zoneType}
                      allowMapSync={direction.length > 1}
                      uiHidden={uiHidden}
                      dashboardType={dashboardType}
                      {...(direction?.length === 1 && {
                        setActiveMenu: setActiveMenu,
                      })}
                    />
                  </div>
                ) : null}
              </>
            ) : null}

            <div className="App-layout-upper-left-fixed">
              <div className="App-layout-main-menu-container">
                <MenuSelector
                  onSelectMenu={val => {
                    setActiveMenu(val);
                    setMenuDraw(val);
                  }}
                  menu={activeMenu}
                  disableMenus={
                    direction.length === 2 ? ['selectionOptions'] : []
                  }
                />
                {renderMenu(activeMenu)}
              </div>
            </div>
            <div className="App-layout-lower-left-fixed">
              <div className="App-origin-destination-container drop-shadow">
                <div className="menu-primary-label App-origin-destination-container-label">
                  {t('general.viewBy')}
                </div>
                <Toggle
                  options={[
                    { label: 'Origin', value: 'origin' },
                    { label: 'Destination', value: 'destination' },
                  ]}
                  onToggle={v => {
                    let nextDirection = direction;
                    if (
                      nextDirection.includes(v) &&
                      nextDirection.length === 1
                    ) {
                      return;
                    } else if (nextDirection.includes(v)) {
                      nextDirection = nextDirection.filter(val => val !== v);
                    } else {
                      nextDirection = nextDirection.concat([v]);
                    }

                    setDirection(nextDirection);
                  }}
                  selected={direction}
                />
              </div>
            </div>
            <div className="App-layout-right-fixed">
              {dashboardType !== 'transit' ? (
                <Sidebar
                  totalTrips={totalTrips}
                  totalPmt={totalPmt}
                  summaryData={dataSummaryData}
                  highlightedSummaryData={dataHighlightedSummaryData}
                  setSegmentation={setSummaryDataSegmentation}
                  dataSegmentation={summaryDataSegmentation}
                  filterValues={filterValues}
                  uiHidden={uiHidden}
                  dataKey={dataKey}
                />
              ) : null}
            </div>
          </div>

          {dashboardType === 'transit' ? (
            <>
              <div
                className={classnames('App-transit-trips-section', {
                  expanded: tripsExpanded,
                })}
              >
                <div className="App-transit-trips-section-icon">
                  <div
                    className="App-transit-trips-section-icon-container"
                    onClick={() => setTripsExpanded(!tripsExpanded)}
                  >
                    <ExpandIcon
                      style={{
                        transform: `rotate(${tripsExpanded ? '180' : '0'}deg)`,
                      }}
                    />
                  </div>
                </div>
                <div className="App-transit-trips-content">
                  <div className="App-transit-trips-content-scatterplot-column">
                    <Scatterplot data={transitTripsData} />
                  </div>
                  <div className="App-transit-trips-content-trips-display-column">
                    <TripsDisplay
                      data={transitTripsData}
                      isExpanded={tripsExpanded}
                      setExpanded={() => setTripsExpanded(!tripsExpanded)}
                    />{' '}
                  </div>
                </div>
              </div>
              {tripsExpanded ? (
                <div className="App-transit-trips-section-placeholder" />
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default App;
