import React, { useCallback, useMemo, useState } from 'react';
import ColorInput from '../ColorInput';
import MenuContainer from '../MenuContainer';
import NumberInput from '../NumberInput';
import { ReactComponent as CustomLayerCircle } from '../../images/custom_layer_circle.svg';
import { ReactComponent as CustomLayerFill } from '../../images/custom_layer_fill.svg';
import { ReactComponent as CustomLayerLine } from '../../images/custom_layer_line.svg';
import { ReactComponent as ModalToggle } from '../../images/more_toggle.svg';
import { ReactComponent as ModalToggleActive } from '../../images/more_toggle_active.svg';
import { ReactComponent as VisibilityHidden } from '../../images/visibility_hidden.svg';
import { ReactComponent as VisibilityVisible } from '../../images/visibility_visible.svg';
import { useCustomLayersStore } from '../../store/customLayers';
import './CustomLayerEntry.scss';

const getLayerPaintFields = type => {
  switch (type) {
    case 'circle':
      return [
        {
          label: 'Color',
          name: 'circle-color',
          type: 'color',
        },
        {
          label: 'Outline color',
          name: 'circle-stroke-color',
          type: 'color',
        },
        {
          label: 'Size',
          name: 'circle-radius',
          type: 'number',
          min: 1,
          max: 20,
          step: 0.5,
        },
        {
          label: 'Opacity',
          name: 'circle-opacity',
          type: 'number',
          min: 0,
          max: 1,
          step: 0.1,
        },
      ];
    case 'fill':
      return [
        {
          label: 'Fill color',
          name: 'fill-color',
          type: 'color',
        },
        {
          label: 'Fill opacity',
          name: 'fill-opacity',
          type: 'number',
          min: 0,
          max: 1,
          step: 0.1,
        },
        {
          label: 'Outline color',
          name: 'line-color',
          type: 'color',
        },
        {
          label: 'Outline thickness',
          name: 'line-width',
          type: 'number',
          min: 0.5,
          max: 5,
        },
        {
          label: 'Outline opacity',
          name: 'line-opacity',
          type: 'number',
          min: 0,
          max: 1,
          step: 0.1,
        },
      ];
    case 'line':
      return [
        {
          label: 'Color',
          name: 'line-color',
          type: 'color',
        },
        {
          label: 'Thickness',
          name: 'line-width',
          type: 'number',
          min: 0.5,
          max: 5,
        },
        {
          label: 'Opacity',
          name: 'line-opacity',
          type: 'number',
          min: 0,
          max: 1,
          step: 0.1,
        },
      ];
    default:
      return [];
  }
};

const CustomLayerStyleField = ({
  onChange,
  label,
  name,
  type,
  value,
  ...options
}) => {
  let field;

  switch (type) {
    case 'color':
      field = <ColorInput value={value} onChange={onChange} {...options} />;
      break;
    case 'number':
      field = <NumberInput value={value} onChange={onChange} {...options} />;
      break;
    default:
      break;
  }
  return (
    <div className="CustomLayerStyleField">
      <label>
        <span>{label}</span>
        {field}
      </label>
    </div>
  );
};

const CustomLayerStyleSettings = ({ layer }) => {
  const setStyle = useCustomLayersStore(state => state.setStyle);
  const fields = getLayerPaintFields(layer.type);

  const handleUpdateStyle = useCallback(
    (name, value) => {
      const newStyle = {
        ...layer.paint,
        [name]: value,
      };
      setStyle(layer.id, newStyle);
    },
    [setStyle, layer]
  );

  return (
    <div className="CustomLayerStyleSettings">
      {fields.map(field => (
        <CustomLayerStyleField
          key={field.name}
          onChange={value => {
            if (field.type === 'number' && value <= field.max) {
              handleUpdateStyle(field.name, parseFloat(value.toFixed(2)));
            } else if (field.type === 'color') {
              handleUpdateStyle(field.name, value);
            }
          }}
          value={layer.paint[field.name]}
          {...field}
        />
      ))}
    </div>
  );
};

const CustomLayerSettings = ({ layer }) => {
  const remove = useCustomLayersStore(state => state.remove);

  return (
    <div className="CustomLayerSettings">
      <section>
        <CustomLayerStyleSettings layer={layer} />
      </section>
      <section>
        <button className="remove-button" onClick={() => remove(layer.id)}>
          Delete
        </button>
      </section>
    </div>
  );
};

const CustomLayerEntry = ({ id }) => {
  const activeCustomLayers = useCustomLayersStore(state => state.active);
  const setVisibility = useCustomLayersStore(state => state.setVisibility);
  const [modalOpen, setModalOpen] = useState(false);

  const layer = useMemo(() => {
    return activeCustomLayers.find(l => l.id === id);
  }, [activeCustomLayers, id]);

  const handleSetVisibility = useCallback(
    visible => {
      setVisibility(id, visible);
    },
    [id, setVisibility]
  );

  const LayerIcon = useMemo(() => {
    switch (layer.type) {
      case 'line':
        return CustomLayerLine;
      case 'fill':
        return CustomLayerFill;
      case 'circle':
        return CustomLayerCircle;
      default:
        return null;
    }
  }, [layer.type]);

  return (
    <div className="CustomLayerEntry">
      <div className="description">
        <div className="layer-icon">{LayerIcon ? <LayerIcon /> : null}</div>
        <div className="layer-name">{layer.name}</div>
      </div>
      <div className="actions">
        <div
          className="action visibility-toggle"
          onClick={() =>
            handleSetVisibility(
              layer.visibility === 'visible' ? 'none' : 'visible'
            )
          }
        >
          {layer.visibility === 'visible' ? (
            <VisibilityVisible />
          ) : (
            <VisibilityHidden />
          )}
        </div>
        <div
          className="action modal-toggle"
          onClick={() => setModalOpen(!modalOpen)}
        >
          {modalOpen ? <ModalToggleActive /> : <ModalToggle />}
        </div>
        <div className="settings-modal-container">
          {modalOpen ? (
            <div className="settings-modal">
              <MenuContainer closeMenu={() => setModalOpen(false)}>
                <CustomLayerSettings layer={layer} />
              </MenuContainer>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CustomLayerEntry;
