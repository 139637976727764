import React from 'react';
import { useTranslation } from 'react-i18next';
import './MapMenu.scss';
import CustomLayersInput from '../custom-layers/CustomLayersInput';
import Dropdown from '../Dropdown';
import { MAPBOX_STYLE_URLS } from '../../constants';

function MapMenu({ styleUrl, setStyleUrl, mapLayers }) {
  const { t } = useTranslation();
  return (
    <div className="MapMenu">
      <div className="menu-header-label">{t('mapMenu.map')}</div>

      <div className="sections">
        <section>
          <div className="menu-primary-label">{t('mapMenu.mapLayers')}</div>
          <CustomLayersInput mapLayers={mapLayers} />
        </section>

        <section>
          <div className="menu-primary-label">{t('mapMenu.changeBasemap')}</div>

          <div className="MapMenu-section">
            <Dropdown
              selectedValue={styleUrl}
              options={Object.entries(MAPBOX_STYLE_URLS).map(([k, v]) => ({
                label: k.charAt(0).toUpperCase() + k.slice(1),
                value: v,
              }))}
              onChange={setStyleUrl}
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default MapMenu;
