import React, { useEffect, useCallback, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import './Popover.scss';

const Popover = ({ parentRef, isOpen, children, position = 'bottom' }) => {
  const ref = useRef(null);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const setPosition = useCallback(() => {
    const popoverRef = ref?.current;
    if (!parentRef || !popoverRef) return;
    const parent = parentRef.getBoundingClientRect();
    const popover = popoverRef.getBoundingClientRect();
    const yOffset =
      position === 'bottom'
        ? `calc(${parent.y + parent.height}px + 0.5rem)`
        : `calc(${parent.y - popover.height}px - 0.5rem)`;
    setOffset({
      x: `${parent.x - (popover.width - parent.width)}px`,
      y: yOffset,
    });
  }, [ref, parentRef, position]);

  useEffect(() => {
    if (isOpen) {
      setPosition();
    }
  }, [isOpen, setPosition]);

  useResizeObserver(document?.getElementsByClassName('App')?.[0], setPosition);

  if (!isOpen) return null;
  return (
    <div
      ref={ref}
      className="Popover"
      style={{
        transform: `translate(${offset.x},${offset.y})`,
        opacity: !offset.x && !offset.y ? 0 : 1,
      }}
    >
      {children}
    </div>
  );
};

export default Popover;
