import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PALETTES, getPaletteBins } from '../color_palettes';
import Dropdown from './Dropdown';
import ButtonSet from './ButtonSet';
import { ReactComponent as Reverse } from '../images/reverse.svg';
import './PaletteSwitcher.scss';
import { CLASSIFICATION_METHODS, CHOROPLETH_BASIS_OPTIONS } from '../constants';
import NumberInput from './NumberInput';

function PaletteSwitcher({
  palette,
  scaleType,
  classificationMethod,
  setClassificationMethod,
  setPalette,
  setScaleType,
  setBinSize,
  binSize,
  setDataKey,
  dataKey,
  activeDashboardInstance,
  setZoneType,
  zoneOptions,
  zoneType,
  dashboardType,
}) {
  const { t } = useTranslation();
  // TODO set min, max values of the color scale
  const [reverse, setReverse] = useState(false);

  const reversePalette = () => {
    setReverse(!reverse);
    setPalette(palette.reverse());
  };

  const createPaletteLabel = (label, colors, type) => {
    const continuous =
      colors.length > 1 ? (
        <div
          style={{
            background: `linear-gradient(0.25turn, ${colors.join(', ')})`,
            width: '40%',
            height: '1rem',
            marginRight: '0.5rem',
            flexShrink: 0,
          }}
        />
      ) : (
        <div
          style={{
            backgroundColor: `${colors[0]}`,
            width: '40%',
            height: '1rem',
            marginRight: '0.5rem',
            flexShrink: 0,
          }}
        />
      );
    const discrete = (
      <div
        style={{
          width: '40%',
          height: '1rem',
          marginRight: '0.5rem',
          flexShrink: '0',
          display: 'flex',
        }}
      >
        {colors.map((color, i) => (
          <div
            key={i}
            style={{ height: '100%', flexGrow: 1, backgroundColor: color }}
          />
        ))}
      </div>
    );

    return (
      <div className="PaletteSwitcher-label">
        {type === 'step' ? discrete : continuous}
        <div className="PaletteSwitcher-label-name">{label}</div>
      </div>
    );
  };

  const getPaletteOptions = (num, doReverse) => {
    // Issues with mutability, need to clone
    const nextOptions = JSON.parse(JSON.stringify(PALETTES)).map(p => {
      const full = doReverse ? p.palette.reverse() : p.palette;
      const colors = getPaletteBins(num, full);
      return {
        label: createPaletteLabel(p.name, colors, scaleType),
        value: JSON.stringify(full),
      };
    });
    return nextOptions;
  };

  return (
    <div className="PaletteSwitcher">
      <div className="menu-primary-label">{t('palette.data')}</div>
      <div className="PaletteSwitcher-dropdown-section">
        <Dropdown
          selectedValue={dataKey}
          options={CHOROPLETH_BASIS_OPTIONS.filter(
            item => item?.dashboardType === dashboardType
          )}
          onChange={setDataKey}
          optionsPosition="bottom"
        />
      </div>

      <div className="menu-primary-label">{t('palette.zoneType')}</div>
      <div className="PaletteSwitcher-dropdown-section">
        <ButtonSet
          options={zoneOptions}
          onToggle={setZoneType}
          selected={[zoneType]}
        />
      </div>

      <div className="menu-primary-label">{t('palette.dataColorScale')}</div>
      <div className="PaletteSwitcher-dropdown-section">
        <div className="menu-secondary-label">{t('palette.modifyScale')}</div>
        <Dropdown
          key="colorPalette"
          selectedValue={JSON.stringify(palette)}
          options={getPaletteOptions(binSize, reverse)}
          onChange={v => setPalette(JSON.parse(v))}
          optionsPosition="bottom"
        />
      </div>
      <div className="PaletteSwitcher-palette-container">
        <div className="menu-secondary-label">{t('palette.scaleType')}</div>
        <div className="PaletteSwitcher-control-container">
          <ButtonSet
            options={[
              { label: t('palette.discrete'), value: 'step' },
              { label: t('palette.continuous'), value: 'interpolate' },
            ]}
            onToggle={setScaleType}
            selected={[scaleType]}
          />
          <button
            className="PaletteSwitcher-reverse-button"
            onClick={reversePalette}
          >
            <Reverse />
          </button>
        </div>
      </div>

      <div className="PaletteSwitcher-dropdown-section">
        <div className="menu-secondary-label">{t('palette.classifyBy')}</div>
        <ButtonSet
          options={Object.values(CLASSIFICATION_METHODS)}
          onToggle={setClassificationMethod}
          selected={[classificationMethod]}
        />
      </div>
      <div className="PaletteSwitcher-dropdown-section">
        <div className="menu-secondary-label">{t('palette.numberOfBins')}</div>
        <NumberInput min={1} max={5} value={binSize} onChange={setBinSize} />
      </div>
    </div>
  );
}

export default PaletteSwitcher;
