import * as d3 from 'd3';
import * as ss from 'simple-statistics';

const getIncrements = binSize => {
  const increment = 10 / binSize;
  const increments = new Array(binSize)
    .fill()
    .map((_, i) => (increment * i) / 10);
  return increments;
};

export const getQuantileValues = (binSize, values) => {
  const increments = getIncrements(binSize);
  return increments.map(v => d3.quantile(values, v));
};

export const getEqualIntervalValues = (binSize, values) => {
  // Extent up only to the 99th percentile to remove outliers
  const extent = [d3.quantile(values, 0), d3.quantile(values, 0.99)];
  const scale = d3.scaleLinear().domain([0, 1]).range(extent);
  const increments = getIncrements(binSize);
  return increments.map(v => scale(v));
};

export const getJenksValues = (binSize, values) => {
  let nextValues = ss.jenks(values, binSize);
  // Temp fix, for some reason Jenks can return duplicates
  nextValues = [...new Set(nextValues)].sort((a, b) => a - b);
  return nextValues;
};
